<template>
  <div v-if="onlineAccessRequired">
    <div class="topContentError topContentMessage" v-if="!isConnectedOnline">
      {{ getCurrentErrorText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      isOnline: navigator.onLine
    };
  },
  computed: {
    getCurrentErrorText() {
      return this.$store.getters.GET_CURRENT_ONLINE_ERROR;
    },
    onlineAccessRequired() {
      return this.$store.getters.IS_ONLINE_ACCESS_REQUIRED;
    },
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    }
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.isOnline = type === "online";
      this.$store.commit("SET_ONLINE", this.isOnline);
    }
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  }
};
</script>
