<template>
  <div>
    <!-- Button shown to users -->
    <a class="action-btn secondLine" @click="print">
      <span>
        <span>{{ $t("editTrip.summary.printReceipt") }}</span>
      </span>
    </a>
    <!-- Content that will be printed; easier to handle in template than through string manipulation -->
    <div id="summarycontent" ref="summarycontent" style="display: none;">
      <h1>{{ $t("editTrip.summary.logbook") }} {{ luid }}</h1>
      <h2>{{ $t("editTrip.summary.LandingCatchSummary") }}</h2>
      <ul>
        <li v-for="(cargo, index) in cargos" :key="'cargo-' + index">
          {{ cargo.specie }} {{ cargo.form }} {{ cargo.weight }} kg
        </li>
      </ul>
    </div>
    <!-- Required for printing without having to create a new window/tab -->
    <iframe
      :srcdoc="content"
      id="summaryprint"
      ref="summaryprint"
      style="display: none;"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "SummaryPrinter",
  data: function() {
    return {
      content: ""
    };
  },
  computed: {
    ...mapState({
      luid: state => state.selectedTrip.logbookUID,
      species: state => state.systemsLists.species,
      productForms: state => state.systemsLists.productForm,
      cargoEvents: state => state.selectedTrip.arrival.cargoEvents
    }),
    cargos() {
      let result = [];
      if (this.cargoEvents) {
        for (const cargoEvent of this.cargoEvents) {
          for (const cargo of cargoEvent.cargos) {
            const specieLabel = this.species.find(
              x => x.value === cargo.species
            );
            const formLabel = this.productForms.find(
              x => x.value === cargo.productForm
            );
            result.push({
              weight: cargo.weight,
              specie: specieLabel.text,
              form: formLabel.text
            });
          }
        }
      }
      return result;
    }
  },
  methods: {
    print() {
      this.$refs.summaryprint.focus();
      this.$refs.summaryprint.contentWindow.print();
    }
  },
  mounted() {
    this.content = this.$refs.summarycontent.innerHTML;
  }
};
</script>
