import { render, staticRenderFns } from "./AdditionalInfoViewer.vue?vue&type=template&id=599cbbcf&scoped=true"
import script from "./AdditionalInfoViewer.vue?vue&type=script&lang=js"
export * from "./AdditionalInfoViewer.vue?vue&type=script&lang=js"
import style0 from "./AdditionalInfoViewer.vue?vue&type=style&index=0&id=599cbbcf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599cbbcf",
  null
  
)

export default component.exports