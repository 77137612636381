<template>
  <header class="insideApp" v-if="isAuthenticated">
    <div class="header-content">
      <img class="logo" :src="headerLogo" />
      <div
        class="mainMenu"
        :class="(!hasElogKey || !isProfileReady) && 'alertMenu'"
        @click.prevent="toggleMainMenu"
        @mouseenter.prevent="openMainMenu"
        @mouseleave.prevent="closeMainMenu"
      >
        <span class="politeHeader">
          <span class="message">{{ $t("header.welcome") }}&nbsp;</span>
          <span class="username">{{ username }}</span>
          <img class="logo" :src="headerMenuArrow" />
        </span>
        <span class="hourglass">
          <span class="day">{{ currentTime | day }} </span>
          <span class="localtime">{{ currentTime | time }}</span>
        </span>
        <div class="dropDownMainMenu" v-show="isMainMenuOpen">
          <div class="section darkItem">
            <a v-if="language == 'en'" @click="setLanguage('fr')">
              <span>
                <span>
                  <span>Français</span>
                </span>
              </span>
            </a>
          </div>
          <div class="section darkItem">
            <a v-if="language == 'fr'" @click="setLanguage('en')">
              <span>
                <span>
                  <span>English</span>
                </span>
              </span>
            </a>
          </div>
          <div class="section">
            <router-link to="/help">
              <span>
                <span>
                  <span>{{ $t("header.help") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link to="/modules">
              <span>
                <span>
                  <span>{{ $t("header.modules") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link :to="{ name: 'archiveModuleList' }">
              <span>
                <span>
                  <span>{{ $t("header.archives") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :class="!isProfileReady && 'alertMenu'"
              :to="{ name: 'userprofile', params: { tab: 1 } }"
            >
              <span>
                <span>
                  <span>{{ $t("header.profile") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :class="!hasElogKey && 'alertMenu'"
              :to="{ name: 'userprofile', params: { tab: 3 } }"
            >
              <span>
                <span>
                  <span>{{ $t("elogkey.label") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link :to="{ name: 'receipts' }">
              <span>
                <span>
                  <span>{{ $t("header.receipts") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :to="{
                name: 'formList',
                params: { reportId: $const.INACTIVITYID }
              }"
            >
              <span>
                <span>
                  <span>{{ $t("header.inactivities") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section darkItem">
            <router-link :to="{ name: 'lock' }">
              <span>
                <span>
                  <span>{{ $t("header.lock") }}</span>
                </span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="helpMenu"
        @click.prevent="toggleHelpMenu()"
        @mouseenter.prevent="openHelpMenu()"
        @mouseleave.prevent="closeHelpMenu()"
      >
        <img :src="iconBook" />
        <div class="dropDownHelpMenu" v-show="isHelpMenuOpen">
          <div class="section">
            <router-link to="/help">
              <span>
                <span>
                  <span>{{ $t("header.help") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <a @click="navigate(userGuideUrl)">
              <span>
                <span>
                  <span>{{ $t("header.guide") }}</span>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="header-content-smallscreen">
      <div
        class="mainMenu"
        :class="!hasElogKey && 'alertMenu'"
        @click.prevent="toggleMainMenu"
        @mouseenter.prevent="openMainMenu"
        @mouseleave.prevent="closeMainMenu"
      >
        <div class="button">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="dropDownMainMenu" v-show="isMainMenuOpen">
          <div class="section darkItem">
            <a v-if="language == 'en'" @click="setLanguage('fr')">
              <span>
                <span>
                  <span>Français</span>
                </span>
              </span>
            </a>
          </div>
          <div class="section darkItem">
            <a v-if="language == 'fr'" @click="setLanguage('en')">
              <span>
                <span>
                  <span>English</span>
                </span>
              </span>
            </a>
          </div>
          <div class="section">
            <router-link to="/help">
              <span>
                <span>
                  <span>{{ $t("header.help") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link to="/modules">
              <span>
                <span>
                  <span>{{ $t("header.modules") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link :to="{ name: 'archiveModuleList' }">
              <span>
                <span>
                  <span>{{ $t("header.archives") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :class="!isProfileReady && 'alertMenu'"
              :to="{ name: 'userprofile', params: { tab: 1 } }"
            >
              <span>
                <span>
                  <span>{{ $t("header.profile") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :class="!hasElogKey && 'alertMenu'"
              :to="{ name: 'userprofile', params: { tab: 3 } }"
            >
              <span>
                <span>
                  <span>{{ $t("elogkey.label") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link :to="{ name: 'receipts' }">
              <span>
                <span>
                  <span>{{ $t("header.receipts") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <router-link
              :to="{
                name: 'formList',
                params: { reportId: $const.INACTIVITYID }
              }"
            >
              <span>
                <span>
                  <span>{{ $t("header.inactivities") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section darkItem">
            <router-link :to="{ name: 'lock' }">
              <span>
                <span>
                  <span>{{ $t("header.lock") }}</span>
                </span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="headersLogo"></div>
      <div
        class="helpMenu"
        @click.prevent="toggleHelpMenu()"
        @mouseenter.prevent="openHelpMenu()"
        @mouseleave.prevent="closeHelpMenu()"
      >
        <div class="dropDownHelpMenu" v-show="isHelpMenuOpen">
          <div class="section">
            <router-link to="/help">
              <span>
                <span>
                  <span>{{ $t("header.help") }}</span>
                </span>
              </span>
            </router-link>
          </div>
          <div class="section">
            <a @click="navigate(userGuideUrl)">
              <span>
                <span>
                  <span>{{ $t("header.guide") }}</span>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

import headerLogo from "@/assets/header-logo.png";
import headerMenuArrow from "@/assets/header-mainmenu-arrow.png";
import iconBook from "@/assets/icon-book.gif";

export default {
  name: "Header",
  computed: {
    ...mapState({
      username: state => state.userProfile.userfullname
    }),
    isAuthenticated() {
      return this.$store.getters.IS_AUTHENTICATED;
    },
    isProfileReady() {
      if (this.$store.getters.USER_PROFILE) {
        return this.$store.getters.USER_PROFILE.isReady;
      }
      return null;
    },
    hasElogKey() {
      if (this.$store.getters.USER_PROFILE) {
        return this.$store.getters.USER_PROFILE.hasElogKey;
      }
      return null;
    },
    language() {
      return this.$i18n.locale;
    },
    isMobile() {
      return this.isAndroid || this.isIOS;
    },
    userGuideUrl() {
      const lang = this.language.toString().toUpperCase();
      const targetFilename = `/static/Jobel_2021_${lang}.pdf`;
      const baseUrl = process.env.VUE_APP_DJANGO_BASE_URL;
      const targetURL = new URL(targetFilename, baseUrl);
      return targetURL.href;
    }
  },
  data() {
    return {
      headerLogo,
      headerMenuArrow,
      iconBook,
      currentTime: null,
      isHelpMenuOpen: false,
      isMainMenuOpen: false,
      isAndroid: false,
      isIOS: false,
      interval: null
    };
  },
  methods: {
    navigate(url) {
      window.open(url, "_blank").focus();
    },
    updateCurrentTime() {
      this.currentTime = new Date();
    },
    toggleHelpMenu() {
      this.isHelpMenuOpen = !this.isHelpMenuOpen;
      this.isMainMenuOpen = false;
    },
    openHelpMenu() {
      if (!this.isMobile && !this.isHelpMenuOpen) {
        this.isHelpMenuOpen = true;
        this.isMainMenuOpen = false;
      }
    },
    closeHelpMenu() {
      if (!this.isMobile && this.isHelpMenuOpen) {
        this.isHelpMenuOpen = false;
      }
    },
    toggleMainMenu() {
      this.isMainMenuOpen = !this.isMainMenuOpen;
      this.isHelpMenuOpen = false;
    },
    openMainMenu() {
      if (!this.isMobile && !this.isMainMenuOpen) {
        this.isMainMenuOpen = true;
        this.isHelpMenuOpen = false;
      }
    },
    closeMainMenu() {
      if (!this.isMobile && this.isMainMenuOpen) {
        this.isMainMenuOpen = false;
      }
    },
    setLanguage(language) {
      const store = this.$store;
      store.dispatch("setLanguage", language).then(function() {
        return store.dispatch("dataReinitialization", language);
      });
    }
  },
  created() {
    this.isAndroid = navigator.userAgent.match(/Android/i) !== null;
    this.isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
  },
  mounted() {
    this.interval = setInterval(this.updateCurrentTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.darkItem {
  background-color: $darkColor;
}
</style>
