<template>
  <div name="HailViewer">
    <h4 class="darkTitle">
      <span>
        <span>
          {{ $t("editTrip.hailInCalls") }}
        </span>
      </span>
    </h4>
    <div v-for="(hailin, index) in hailIns" :key="'hailin-' + index">
      <fieldset class="fieldsetViewTrip">
        <h4 class="listItemTitle startContext subTitle">
          {{ $t("editTrip.hailInCall") }}&nbsp;{{ index + 1 }}
        </h4>
        <b-row>
          <b-col v-if="hailin.provider" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.provider')"
              :value="hailin.provider"
              :options="systemsLists.serviceProvider"
              type="select"
            />
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.hailInNumber')"
              :value="hailin.number"
              type="text"
            />
          </b-col>
          <b-col lg="4" sm="6" cols="12" v-if="hailin.isFishingCompleted">
            <LabelView
              :label="$t('editTrip.hailInfishing')"
              :value="hailin.isFishingCompleted"
              :options="systemsLists.response"
              type="select"
            />
          </b-col>
          <b-col v-if="hailin.estimatedArrival" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.lastStep.eta')"
              :value="$showDate(hailin.estimatedArrival)"
              type="text"
            />
          </b-col>
          <b-col lg="4" sm="6" cols="12" v-if="hailin.weightOnBoard">
            <UnitConverter
              :label="$t('editTrip.lastStep.weightOnBoard')"
              :value="hailin.weightOnBoard"
              unit="weight"
              viewOnly
            />
          </b-col>
          <b-col v-if="hailin.remark" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.remarkTitle')"
              :value="hailin.remark"
              type="text"
            />
          </b-col>
          <b-col v-if="hailin.closeDatetime" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('dataGroupClosureDate')"
              :value="$showDate(hailin.closeDatetime)"
              type="text"
            />
          </b-col>
        </b-row>
        <fieldset v-if="hailin['details'] && hailin['details'].length > 0">
          <h4>
            {{ $t("editTrip.lastStep.details") }}
          </h4>
          <div
            v-for="(detail, indexDetail) in hailin['details']"
            :key="'detail-' + indexDetail"
          >
            <b-row>
              <b-col lg="4" sm="6" cols="12" v-if="detail.towNum">
                <TowSelector
                  :value="detail.towNum"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="detail.towNumIdx">
                <TowSelector
                  :value="detail.towNumIdx"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="detail.remark">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="detail.remark"
                  type="text"
                />
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </fieldset>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import TowSelector from "../widgets/TowSelector.vue";

export default {
  name: "HailViewer",
  components: {
    LabelView,
    TowSelector,
    UnitConverter
  },
  props: {
    hailIns: Array
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile
    })
  },
  methods: {}
};
</script>
