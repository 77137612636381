var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page insideApp",attrs:{"id":"pageLobby"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("lobby.title")))]),_c('div',{staticClass:"content"},[(!_vm.hasSubscriptions)?_c('p',{staticClass:"emptyAccount"},[_vm._v(" "+_vm._s(_vm.$t("lobby.nomodule"))+" ")]):_vm._e(),(_vm.elogKeyIsNeeded)?_c('p',{staticClass:"elogKeyIsNeeded topContentError topContentMessage",on:{"click":_vm.elogClick}},[_vm._v(" "+_vm._s(_vm.$t("message.no_elogkey"))+" ")]):_vm._e(),(_vm.elogKeyIsExpired)?_c('p',{staticClass:"elogKeyIsExpired topContentWarning topContentMessage",on:{"click":_vm.elogClick}},[_vm._v(" "+_vm._s(_vm.$t("message.expired_elogkey"))+" ")]):_vm._e(),_c('nav',{staticClass:"homeMenu"},_vm._l((_vm.subscriptions),function(subscription,index){return _c('div',{key:subscription.uuid,staticClass:"module-btn-wrapper",class:_vm.moduleClass(index)},[(subscription.module.activated)?_c('div',[(subscription.willExpire || subscription.isExpired)?_c('router-link',{staticClass:"renewOverlay",attrs:{"to":{
              name: 'modulesorder',
              params: { id: subscription.module.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("lobby.renew"))+" ")]):_vm._e(),(subscription.willExpire && !subscription.isExpired)?_c('router-link',{staticClass:"renewOverlay expiration willExpire",attrs:{"to":{
              name: 'modulesorder',
              params: { id: subscription.module.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("lobby.expire"))+" "),_c('br'),_vm._v(_vm._s(_vm._f("day")(subscription.expiration))+" ")]):_vm._e(),(subscription.isExpired)?_c('router-link',{staticClass:"renewOverlay expiration alreadyExpired",attrs:{"to":{
              name: 'modulesorder',
              params: { id: subscription.module.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("lobby.expired"))+" "),_c('br'),_vm._v(_vm._s(_vm._f("day")(subscription.expiration))+" ")]):_vm._e(),_c('router-link',{staticClass:"module-link",class:subscription.module.isDemo && 'demo',attrs:{"to":{ name: 'module', params: { uuid: subscription.uuid } }}}),_c('span',{staticClass:"icon",staticStyle:{"background-repeat":"no-repeat","background-position":"center"},style:({
              'background-image':
                'url(' + _vm.getImageData(subscription.module.icon) + ')'
            })}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(subscription.module.label))])],1):_vm._e()])}),0),_c('div',{staticClass:"btn-add-module"},[_c('router-link',{staticClass:"addModuleLink",attrs:{"to":{ name: 'modulesadd' }}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("lobby.add")))]),_c('span',{staticClass:"plus-icon"},[_vm._v("+")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }