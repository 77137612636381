var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page trip insideApp"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("lobby.module"))+" "+_vm._s(_vm.subscription.module.label)+" ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$t("reviewTripData"))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"topPageMenu"},[_c('ReturnButton',{attrs:{"returnToArchives":this.action === 'archive'}})],1),(_vm.subscription.module.isDemo)?_c('p',{staticClass:"topContentWarning"},[_vm._v(" "+_vm._s(_vm.$t("triplist.demoWarning"))+" ")]):_vm._e(),_c('div',{staticClass:"tripContent"},[_c('SubscriptionViewer'),_c('div',[_c('h3',[_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("editTrip.tripData"))+" ")])])]),_c('DepartureViewer'),_c('EffortsViewer'),(_vm.currentTrip.baitUsages && _vm.currentTrip.baitUsages.length > 0)?_c('BaitViewer',{attrs:{"baitUsages":_vm.currentTrip.baitUsages}}):_vm._e(),(_vm.currentTrip.tags && _vm.currentTrip.tags.length > 0)?_c('TagsViewer',{attrs:{"tags":_vm.currentTrip.tags}}):_vm._e(),_c('GearLostViewer'),_c('SpeciesAtRiskViewer'),_c('MmiViewer'),(
            _vm.currentTrip.inactivity &&
              _vm.currentTrip.inactivity !== null &&
              _vm.currentTrip.inactivity.reportUID
          )?_c('InactivityViewer',{attrs:{"inactivity":_vm.currentTrip.inactivity}}):_vm._e(),_c('AdditionalInfoViewer'),_c('LastViewer')],1),_c('br'),_c('SummaryViewer',{attrs:{"showErrorData":_vm.showErrorData,"addErrorToParent":_vm.checkError}})],1),_c('div',{staticClass:"action-btn-wrapper"},[_c('div',{staticClass:"action-btn-wrapper-left"},[(_vm.currentTrip.status === 'review')?_c('a',{staticClass:"action-btn action-btn-edit",on:{"click":_vm.clickEditTrip}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.summary.editTrip")))])])]):_vm._e(),(_vm.currentTrip.status === 'review')?_c('a',{staticClass:"action-btn action-btn-delete secondLine",on:{"click":_vm.clickDeleteTrip}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.summary.deleteTrip")))])])]):_vm._e(),(
            ['closed', 'landing', 'synched'].includes(_vm.currentTrip['status'])
          )?_c('CrewPrinter'):_vm._e(),(['closed', 'synched'].includes(_vm.currentTrip['status']))?_c('SummaryPrinter'):_vm._e()],1),_c('div',{staticClass:"action-btn-wrapper-right"},[(_vm.currentTrip.status === 'landing')?_c('a',{staticClass:"action-btn action-btn-complete",attrs:{"id":"actionButton"},on:{"click":_vm.clickCloseLanding}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.summary.closeLanding")))])])]):(_vm.currentTrip.status === 'review')?_c('a',{staticClass:"action-btn action-btn-close",attrs:{"id":"actionButton"},on:{"click":_vm.clickCloseTrip}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.summary.closeTrip")))])])]):(_vm.currentTrip.status === 'closed')?_c('a',{staticClass:"action-btn action-btn-send",attrs:{"id":"actionButton"},on:{"click":_vm.clickSendTrip}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.summary.sendTrip")))])])]):_vm._e()]),_c('div',{staticClass:"action-btn-wrapper-center"},[_c('ReturnButton',{attrs:{"returnToArchives":this.action === 'archive'}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }