<template>
  <fieldset
    v-if="isCrewSectionVisible"
    name="Crew Section"
    :class="readonly ? 'list' : null"
  >
    <h4 :class="readonly ? 'crewTitle' : null">
      {{ $t("crew.title") }}
    </h4>
    <div v-if="!readonly || value.members.length > 0" name="Crewmen">
      <fieldset
        v-for="(crewman, index) in value.members"
        :key="index"
        :name="crewmanId(index + 1)"
        :class="readonly ? null : 'crewman'"
      >
        <h4 :class="readonly ? 'crewTitle' : 'sectionTitle'">
          {{ crewmanId(index + 1) }}
        </h4>
        <!-- Name -->
        <GenericInput
          v-if="!readonly"
          v-model.trim="crewman.name"
          type="text"
          :minlength="1"
          :maxlength="50"
          :label="$t('crew.name.label')"
          :help="$t('crew.name.help')"
          :required="true"
          :refeshError="childRefresh"
          @binding="validateCrewman(index)"
          @input="validateCrewman(index)"
          @error="checkError"
        />
        <!-- FIN -->
        <GenericInput
          v-if="!readonly"
          v-model.trim="crewman.fisherIdentificationNumber"
          type="text"
          :label="$t('fin.short')"
          :help="$t('fin.desc')"
          :finValidator="true"
          :bothFieldRequired="true"
          :notBothFields="crewman.address"
          :refeshError="childRefresh"
          @binding="validateCrewman(index)"
          @input="validateCrewman(index)"
          @error="checkError"
          :key="'crewFin' + crewman.address"
        />
        <!-- Address -->
        <GenericInput
          v-if="!readonly"
          v-model.trim="crewman.address"
          type="text"
          :maxlength="200"
          :label="$t('crew.address.label')"
          :help="$t('crew.address.help')"
          :bothFieldRequired="true"
          :notBothFields="crewman.fisherIdentificationNumber"
          :refeshError="childRefresh"
          @binding="validateCrewman(index)"
          @input="validateCrewman(index)"
          @error="checkError"
          :key="'crewAddress' + crewman.fisherIdentificationNumber"
        />
        <p v-if="!readonly" class="field-wrapper">
          <input
            type="button"
            class="removeSuppEntryButton form-control action-btn action-btn-delete"
            :value="$t('crew.removeButton')"
            @click="removeCrewman(index)"
          />
        </p>
        <p
          v-if="!readonly && message[index].length > 0"
          class="blockError error"
        >
          {{ crewmanMessage(index) }}
        </p>
        <b-row v-if="readonly">
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('crew.name.label')"
              :value="crewman.name"
            />
          </b-col>
          <b-col v-if="hasFin(crewman)" lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('fin.short')"
              :value="crewman.fisherIdentificationNumber"
            />
          </b-col>
          <b-col v-if="hasAddress(crewman)" lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('crew.address.label')"
              :value="crewman.address"
            />
          </b-col>
        </b-row>
      </fieldset>
    </div>
    <fieldset v-if="!readonly" name="Add Button" class="suppEntryButtonWrapper">
      <p>
        <input
          class="addSuppEntryButton"
          type="button"
          :value="$t('crew.addButton')"
          @click="addCrewman()"
        />
      </p>
    </fieldset>
    <fieldset v-if="hasCrewCount" name="Count">
      <b-row v-if="readonly">
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            type="text"
            :label="$t('crew.count.label')"
            :value="value.count"
          />
        </b-col>
      </b-row>
      <IntegerInput
        v-if="!readonly"
        v-model.number="value.count"
        :label="$t('crew.count.label')"
        :help="$t('crew.count.help')"
        :min="1"
        :max="maxCrewNb"
        :required="true"
        :refeshError="childRefresh"
        @error="checkError"
      />
    </fieldset>
  </fieldset>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import LabelView from "@/components/LabelView.vue";
import { mapState } from "vuex";
import { mapping } from "@/utils/FormStateMapping";
export default {
  name: "Crew",
  components: {
    GenericInput,
    IntegerInput,
    LabelView
  },
  props: {
    hasCrewCount: {
      type: Boolean,
      required: false,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    refresh: {
      // type: Number,
      required: false,
      default: 0
    },
    value: {
      type: Object,
      required: true,
      // A valid crew Object has exactly 2 fields: 1 number and one array.
      // Every array item is an Object containing 3 fields of type String;
      // the first (name) is mandatory, and one of the other two fields is required.
      validator: function(crew) {
        return (
          "count" in crew &&
          (crew.count == null || typeof crew.count === "number") &&
          "members" in crew &&
          Array.isArray(crew.members) &&
          crew.members.every(
            crewman =>
              typeof crewman === "object" &&
              "name" in crewman &&
              (crewman.name === null || typeof crewman.name === "string") &&
              (("fisherIdentificationNumber" in crewman &&
                (crewman.fisherIdentificationNumber === null ||
                  typeof crewman.fisherIdentificationNumber === "string")) ||
                ("address" in crewman &&
                  (crewman.address === null ||
                    typeof crewman.address === "string")))
          )
        );
      }
    }
  },
  data: function() {
    return {
      childRefresh: false,
      error: {},
      message: []
    };
  },
  computed: {
    ...mapState({
      maxCrewNb: state => state.editTripSubform[mapping.maxCrewNb.stateName]
    }),
    hasMultipleCrewmen() {
      return this.value.members.length > 1;
    },
    isCrewCountInvalid() {
      if (!this.hasCrewCount) return false;
      return (
        this.value.count === null ||
        this.value.count < 1 ||
        this.value.count > this.maxCrewNb
      );
    },
    isCrewSectionVisible() {
      return (
        !this.readonly || this.hasCrewCount || this.value.members.length > 0
      );
    }
  },
  watch: {
    hasCrewCount() {
      this.value.count = this.hasCrewCount ? this.value.count : null;
    },
    refresh: function() {
      this.childRefresh = !this.childRefresh;
      this.validateCrewmen();
    },
    "value.members.length": function(
      newCrewMembersLength,
      oldCrewMembersLength
    ) {
      if (this.value.count == oldCrewMembersLength + 1) {
        this.value.count = newCrewMembersLength + 1;
      }
    }
  },
  created() {
    if (this.isCrewCountInvalid) {
      this.value.count = 1;
    }
  },
  beforeUpdate() {
    if (this.message.length == 0) {
      this.value.members.forEach(crewman => {
        this.message.push("");
      });
    }
  },
  methods: {
    addCrewman: function() {
      const vm = this;
      vm.childRefresh = !vm.childRefresh;
      vm.$nextTick(() => {
        vm.validateCrewmen();
        if (!vm.$hasInputErrorAndScroll("error")) {
          vm.value.members.push({
            name: "",
            fisherIdentificationNumber: null,
            address: null
          });
          vm.message.push("");
        }
      });
    },
    removeCrewman: function(index) {
      // Clear any pending errors for this crewwman
      let error = {};
      error[this.crewmanId(index + 1)] = false;
      this.checkError(error);

      // We can now safely remove the two entries for this crewman
      this.value.members.splice(index, 1);
      this.message.splice(index, 1);
    },
    checkError(error) {
      this.error = { ...this.error, ...error };
      this.$emit("error", this.error);
    },
    validateCrewman(index) {
      let crewman = this.value.members[index];

      // Le nom doit être présent.
      if (!crewman.name) {
        this.message[index] = this.$t("crew.name.mandatory");
        let error = {};
        error[this.crewmanId(index + 1)] = this.message[index];
        this.checkError(error);
        return false;
      }

      // Au moins un des deux champs est nécessaire.
      if (!crewman.fisherIdentificationNumber && !crewman.address) {
        this.message[index] = this.$t("crew.finOrAddress");
        let error = {};
        error[this.crewmanId(index + 1)] = this.message[index];
        this.checkError(error);
        return false;
      }

      this.message[index] = "";
      let error = {};
      error[this.crewmanId(index + 1)] = false;
      this.checkError(error);
      return true;
    },
    validateCrewmen() {
      if (this.isCrewCountInvalid) {
        return false;
      }

      for (let index = 0; index < this.value.members.length; ++index) {
        if (!this.validateCrewman(index)) {
          return false;
        }
      }
      return true;
    },
    crewmanId: function(id) {
      return this.$t("crew.crewman") + " " + id;
    },
    crewmanMessage: function(index) {
      return this.message[index];
    },
    hasFin(crewman) {
      return (
        this.readonly &&
        crewman.fisherIdentificationNumber &&
        crewman.fisherIdentificationNumber.length > 0
      );
    },
    hasAddress(crewman) {
      return this.readonly && crewman.address && crewman.address.length > 0;
    }
  }
};
</script>
