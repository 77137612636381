<template>
  <div class="page-main" name="ConnexionResetKey">
    <h1 v-show="!isBadToken">{{ $t("connexion.changePassword") }}</h1>
    <h1 v-show="isBadToken">{{ $t("connexion.badToken") }}</h1>

    <div class="topPageMenu">
      <router-link :to="{ name: 'connexion' }">
        {{ $t("cancel") }}
      </router-link>
    </div>

    <p v-show="isBadToken">
      {{ $t("connexion.invalidReset") }}
      <a @click.stop="requestNewReset()">{{ $t("connexion.newReset") }}</a>
      .
    </p>

    <form v-show="isGoodToken" method="POST" @submit="validate">
      <fieldset>
        <input hidden type="text" name="void" autoComplete="void" />
        <PasswordInput
          v-model="inputs.password"
          :label="$t('password.new')"
          :refeshError="refeshError"
          :displayPassword="true"
          :required="true"
          :minlength="8"
          autocomplete="new-password"
          @error="checkError"
        />
        <PasswordInput
          v-model="inputs.passwordConfirmation"
          :label="$t('password.confirm')"
          :forceErrorMsg="passwordConfirmError"
          :refeshError="refeshError"
          :displayPassword="true"
          :required="true"
          autocomplete="new-password"
          @error="checkError"
          @binding="checkPasswordConfirmation"
        />
      </fieldset>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.resetaction") }}
      </button>
    </form>
  </div>
</template>

<script>
import Jobel from "@/service/jobel";
import PasswordInput from "@/components/widgets/PasswordInput.vue";

export default {
  name: "ConnexionResetKey",
  computed: {},
  components: {
    PasswordInput
  },
  data() {
    return {
      inputs: {
        passwordConfirmation: null,
        password: null
      },
      refeshError: false,
      token: null,
      uid: null,
      isBadToken: false,
      isGoodToken: false,
      passwordConfirmError: false
    };
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshForm() {
      this.refeshError = !this.refeshError;
    },
    checkPasswordConfirmation(e) {
      const hasPassword =
        this.inputs.password && this.inputs.password.length > 0;
      const hasConfirmation =
        this.inputs.passwordConfirmation &&
        this.inputs.passwordConfirmation.length > 0;
      if (
        hasPassword &&
        hasConfirmation &&
        this.inputs.password != this.inputs.passwordConfirmation
      ) {
        this.passwordConfirmError = this.$i18n.t("validator.unconfirmed");
        this.refeshError = !this.refeshError;
      }
    },
    validate: function(e) {
      e.preventDefault();
      const vuejs = this;
      vuejs.refreshForm();
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }
        const pw = vuejs.inputs.password;
        const pw2 = vuejs.inputs.passwordConfirmation;
        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(() => Jobel.newPassword(vuejs.uid, vuejs.token, pw, pw2))
          .then(() => {
            return vuejs.$router
              .replace({ name: "connexionresetdone" })
              .catch(error => {
                // ignore because of exception on navigation guard...
              });
          })
          .catch(error => vuejs.$alertUserApiError(error))
          .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
      });
    },
    validateToken() {
      const vuejs = this;
      const user = this.uid;
      const token = this.token;
      vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() => Jobel.verifyToken(user, token))
        .then(() => {
          vuejs.isBadToken = false;
          vuejs.isGoodToken = true;
        })
        .catch(error => {
          if (error.errorType == "user") {
            vuejs.isBadToken = true;
            vuejs.isGoodToken = false;
          } else {
            vuejs.$alertUserApiError(error);
          }
        })
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    }
  },
  beforeMount() {
    this.token = this.$route.params.token;
    this.uid = this.$route.params.uidb36;
    this.isBadToken = false;
    this.isGoodToken = false;
  },
  mounted() {
    this.validateToken();
  },
  watch: {
    "inputs.passwordConfirmation"(newVal) {
      this.passwordConfirmError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.topPageMenu {
  color: $darkColor;
  padding-bottom: 25px;
}
.topPageMenu a {
  color: $darkColor;
  display: block;
  text-align: center;
  padding: 7px;
}
.topPageMenu a:hover {
  text-decoration: underline;
}
.topPageMenu .separator {
  display: none;
}
</style>
