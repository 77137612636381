<template>
  <div class="faq">
    <div class="header" @click="handleClick(faq.id)">
      <div class="question">
        {{ faq.question }}
      </div>
      <a>
        <b-icon :icon="faq.isOpen ? 'dash' : 'plus'" scale="2"></b-icon>
      </a>
    </div>
    <div :class="['answer', { open: faq.isOpen }]">
      <ul>
        <li v-for="(answer, index) in faq.answer" :key="index">
          {{ faq.answer[index] }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Faq",
  props: {
    faq: null
  },
  data() {
    return {};
  },
  methods: {
    handleClick(id) {
      this.$emit("toggleAnswer", id);
    }
  }
};
</script>
<style scoped lang="scss">
.faq {
  flex-grow: 1;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    .question {
      font-weight: 700;
    }
  }
  .answer {
    height: 0;
    overflow-y: scroll;
    line-height: 0;
    background-color: transparent;
    transition: height 0.5s;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      appearance: none;
      background-color: transparent;
    }
    p {
      padding: 1px;
    }
    &.open {
      height: auto;
    }
  }
}
</style>
