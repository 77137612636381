var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":""}},[(_vm.type === 'hailIn')?_c('Summary'):_vm._e(),_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip." + _vm.type + "Calls"))+" ")]),_vm._l((_vm.hails),function(hail,index){return _c('fieldset',{key:'hail-' + _vm.type + index,staticClass:"tow"},[(_vm.hails.length > 1)?_c('h4',{staticClass:"gearLossTitle startContext sectionTitle"},[_vm._v(" "+_vm._s(_vm.$t("editTrip." + _vm.type + "Call"))+" "+_vm._s(index + 1)+" ")]):_vm._e(),_c('b-overlay',{key:'hail' + _vm.type + index + hail['closeDatetime'],attrs:{"show":hail['closeDatetime'] !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.showDate(hail["closeDatetime"]))+" ")])]},proxy:true}],null,true)},[_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-' + _vm.type + '-remark' + index),expression:"'modal-' + type + '-remark' + index"},{name:"show",rawName:"v-show",value:(hail['closeDatetime'] === undefined),expression:"hail['closeDatetime'] === undefined"}],staticClass:"add-remark-btn-notTitle"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'modal-' + _vm.type + '-remark' + index},model:{value:(hail['remark']),callback:function ($$v) {_vm.$set(hail, 'remark', $$v)},expression:"hail['remark']"}}),(_vm.type === 'hailOut')?_c('fieldset',{attrs:{"disabled":hail['closeDatetime'] !== undefined}},[(_vm.hasHailOutCompany)?_c('Select',{attrs:{"label":_vm.$t('editTrip.departureStep.provider'),"options":_vm.hailOutCallProviders,"required":_vm.hasHailOutCompany === _vm.$const.MANDATORY,"refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['provider']),callback:function ($$v) {_vm.$set(hail, 'provider', $$v)},expression:"hail['provider']"}}):_vm._e(),_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.hailOutNumber'),"type":"text","maxlength":20,"required":true,"refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['number']),callback:function ($$v) {_vm.$set(hail, 'number', $$v)},expression:"hail['number']"}}),_c('fieldset',{staticClass:"suppEntryButtonWrapper"},[_c('p',[(!hail['closeDatetime'])?_c('input',{staticClass:"closeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.hailOutCallCloseButton')},on:{"click":function($event){return _vm.closeHail(index)}}}):_vm._e()])])],1):_c('fieldset',{attrs:{"disabled":hail['closeDatetime'] !== undefined}},[(hail['provider'] === 11682)?_c('p',{staticClass:"hailinNumberMessage",staticStyle:{"display":"table"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("editTrip.hailInMessage"))+" ")])]):_vm._e(),_c('br'),(_vm.hasHailInCompany)?_c('Select',{attrs:{"label":_vm.$t('editTrip.provider'),"options":_vm.hailInCallProviders,"required":_vm.hasHailInCompany === _vm.$const.MANDATORY,"refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['provider']),callback:function ($$v) {_vm.$set(hail, 'provider', $$v)},expression:"hail['provider']"}}):_vm._e(),_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.hailInNumber'),"type":"text","maxlength":20,"required":true,"refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['number']),callback:function ($$v) {_vm.$set(hail, 'number', $$v)},expression:"hail['number']"}}),(_vm.hasFishingCompleted)?_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.hailInfishing'),"required":true,"options":_vm.systemsLists.response,"type":"radio","refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['isFishingCompleted']),callback:function ($$v) {_vm.$set(hail, 'isFishingCompleted', $$v)},expression:"hail['isFishingCompleted']"}}):_vm._e(),(_vm.hasHailInEstimates)?_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.lastStep.eta'),"type":"datetime","required":true,"autodatetime":false,"minDates":[_vm.lastDateValue],"passDate":false,"refeshError":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['estimatedArrival']),callback:function ($$v) {_vm.$set(hail, 'estimatedArrival', $$v)},expression:"hail['estimatedArrival']"}}):_vm._e(),(_vm.hasHailInEstimates)?_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.lastStep.weightOnBoard'),"required":true,"min":0,"unit":"weight","refresh":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addErrors(error, index);
            }},model:{value:(hail['weightOnBoard']),callback:function ($$v) {_vm.$set(hail, 'weightOnBoard', $$v)},expression:"hail['weightOnBoard']"}}):_vm._e(),(_vm.hasHailInDetails)?_c('HailInDetails',{attrs:{"showErrorDataParent":_vm.refreshError + _vm.hailRefresh + _vm.refreshIndex[index],"addErrorToParent":error => {
              _vm.addErrors(error, index);
            },"details":hail['details']}}):_vm._e(),(hail['closeDatetime'] === undefined)?_c('fieldset',{staticClass:"suppEntryButtonWrapper",staticStyle:{"display":"block"}},[_c('p',[(!hail['closeDatetime'])?_c('input',{staticClass:"addBaitForm closeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.hailInCallCloseButton')},on:{"click":function($event){return _vm.closeHail(index)}}}):_vm._e()])]):_vm._e()],1)],1)],1)}),_c('fieldset',{staticClass:"suppEntryButtonWrapper"},[_c('p',[(_vm.hails !== null && _vm.hails.length > 1 && !_vm.checkCloseDate)?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.hailCallRemoveButton')},on:{"click":function($event){return _vm.removeHail()}}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.hailCallAddButton')},on:{"click":function($event){return _vm.addHail()}}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }