<template>
  <fieldset v-if="hasGearFishingMethod" class="suppEntryButtonWrapper">
    <h4>{{ $t("editTrip.effortStep.fishingMethods") }}</h4>
    <fieldset
      v-for="(fishingMethod, fishingMethodIndex) in fishingMethods"
      :key="fishingMethodIndex"
    >
      <Select
        :label="
          $t('editTrip.effortStep.fishingMethod') +
            ' ' +
            (fishingMethodIndex + 1)
        "
        :options="fishingMethodOptions"
        v-model="fishingMethods[fishingMethodIndex]"
        :forceErrorMsg="uniqueFishingMethod(fishingMethod, fishingMethodIndex)"
        :required="true"
        @error="addErrorToParent"
        :refeshError="showErrorData"
        :key="'fishingMethod-' + fishingMethodIndex"
      />
    </fieldset>
    <p>
      <input
        v-if="canRemove"
        @click="removeFishingMethod()"
        class="removeSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.removeFishingMethod')"
      />
      <input
        v-if="canAdd"
        @click="addFishingMethod()"
        class="addSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.addFishingMethod')"
      />
    </p>
  </fieldset>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Select from "@/components/Select.vue";

export default {
  name: "Methods",
  components: {
    Select
  },
  props: {
    addErrorToParent: Function,
    showErrorData: Number,
    initialMethods: Array,
    subform: Number,
    gear: Number
  },
  data() {
    return {
      fishingMethods: []
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      allFishingMethods: state => state.systemsLists.fishingMethod
    }),
    hasGearFishingMethod() {
      return this.getPropertyValue("hasGearFishingMethod", {
        subforms: this.subform,
        gears: this.gear
      });
    },
    fishingMethodOptions() {
      return [
        {
          value: null,
          text: "---------",
          disabled: this.hasGearFishingMethod === this.$const.MANDATORY
        },
        ...this.allFishingMethods
      ];
    },
    canAdd() {
      if (this.fishingMethods && this.fishingMethods.length > 0) {
        const lastMethod = this.fishingMethods[this.fishingMethods.length - 1];
        return lastMethod != null && lastMethod !== "";
      }
      return true;
    },
    canRemove() {
      if (this.fishingMethods.length === 0) {
        return false;
      }
      return !(
        this.hasGearFishingMethod === this.$const.MANDATORY &&
        this.fishingMethods.length === 1
      );
    }
  },
  watch: {
    gear: function() {
      this.updateMapping();
    },
    subform: function() {
      this.updateMapping();
    },
    fishingMethods: {
      deep: true,
      handler: function() {
        this.$emit("update-fishingMethods", this.fishingMethods);
      }
    }
  },
  beforeMount() {
    this.fishingMethods = this.initialMethods;
    this.updateMapping();
  },
  methods: {
    updateMapping() {
      if (
        this.hasGearFishingMethod === this.$const.MANDATORY &&
        this.fishingMethods.length === 0
      ) {
        this.addFishingMethod();
      }
    },
    removeFishingMethod() {
      this.fishingMethods = this.fishingMethods.slice(0, -1);
      this.fishingMethods = [...this.fishingMethods];
    },
    addFishingMethod() {
      if (this.fishingMethods == null) {
        this.fishingMethods = [];
      }
      this.fishingMethods.push("");
      this.fishingMethods = [...this.fishingMethods];
    },
    uniqueFishingMethod(method, index) {
      const filteredArray = this.fishingMethods.filter(
        value => value === method
      );
      const firstIndex = this.fishingMethods.indexOf(method);
      return filteredArray.length > 1 && firstIndex !== index
        ? this.$t("fields.fishingMethodError")
        : undefined;
    }
  }
};
</script>
