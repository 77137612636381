// TODO: Rename subscriptionName to propertyName to better reflect its meaning !!
export const mapping = {
  baits: {
    stateName: "fishingBaitOptions",
    subscriptionName: "baits",
    list: "baitType"
  },
  GearsTypes: {
    stateName: "gearTypeOptions",
    subscriptionName: "gears",
    list: "gearType"
  },
  GearSubTypes: {
    stateName: "gearSubTypeOptions",
    subscriptionName: "gearSubtypes",
    list: "gearSubTypes"
  },
  GearTrapSizes: {
    stateName: "trapSizeOptions",
    subscriptionName: "trapSizes",
    list: "trapSizes"
  },
  Regions: {
    stateName: "DFOregionOptions",
    subscriptionName: "regions",
    list: "dfoRegions"
  },
  Subforms: {
    stateName: "subformOptions",
    subscriptionName: "subforms",
    list: "subforms"
  },
  TargetSpecies: {
    stateName: "targetSpecies",
    subscriptionName: "targetSpecies",
    list: "species"
  },
  hasDeparturePort: {
    stateName: "hasDeparturePort",
    subscriptionName: "hasDeparturePort",
    list: null
  },
  maxTagCatchLatitude: {
    stateName: "maxTagCatchLatitude",
    subscriptionName: "maxTagCatchLatitude",
    list: null
  },
  minTagCatchLatitude: {
    stateName: "minTagCatchLatitude",
    subscriptionName: "minTagCatchLatitude",
    list: null
  },
  maxTagCatchLongitude: {
    stateName: "maxTagCatchLongitude",
    subscriptionName: "maxTagCatchLongitude",
    list: null
  },
  minTagCatchLongitude: {
    stateName: "minTagCatchLongitude",
    subscriptionName: "minTagCatchLongitude",
    list: null
  },
  HailOutCallProviders: {
    stateName: "hailOutCallProviders",
    subscriptionName: "hailOutCallProviders",
    list: "serviceProvider"
  },
  HailInCallProviders: {
    stateName: "hailInCallProviders",
    subscriptionName: "hailInCallProviders",
    list: "serviceProvider"
  },
  HailOutCallOption: {
    stateName: "hailOutCallOption",
    subscriptionName: "hasHailOut",
    list: null
  },
  GridMapMagdalenIslands: {
    stateName: "gridMapMagdalenIslands",
    subscriptionName: "gridMapMagdalenIslands",
    list: null
  },
  HailInCallOption: {
    stateName: "hailInCallOption",
    subscriptionName: "hasHailIn",
    list: null
  },
  hasEffortStartDatetime: {
    stateName: "hasEffortStartDatetime",
    subscriptionName: "hasEffortStartDatetime",
    list: null
  },
  hasEffortEndDatetime: {
    stateName: "hasEffortEndDatetime",
    subscriptionName: "hasEffortEndDatetime",
    list: null
  },
  hasEffortDetailDatetime: {
    stateName: "hasEffortDetailDatetime",
    subscriptionName: "hasEffortDetailDatetime",
    list: null
  },
  hasEffortDetailNafo: {
    stateName: "hasEffortDetailNafo",
    subscriptionName: "hasEffortDetailNafo",
    list: null
  },
  hasEffortDetailStartNafo: {
    stateName: "hasEffortDetailStartNafo",
    subscriptionName: "hasEffortDetailStartNafo",
    list: null
  },
  // TODO: Obsolete
  pacAreas: {
    stateName: "pacAreas",
    subscriptionName: "pacAreas",
    list: null
  },
  // TODO: Obsolete
  maxGearSize: {
    stateName: "maxGearSize",
    subscriptionName: "maxGearSize",
    list: null
  },
  hasEffortDetailStartGrid: {
    stateName: "hasEffortDetailStartGrid",
    subscriptionName: "hasEffortDetailStartGrid",
    list: null
  },
  maxCrewNb: {
    stateName: "maxCrewNb",
    subscriptionName: "maxCrewNb",
    list: null
  },
  // TODO: Obsolete
  maxAverageTowSpeed: {
    stateName: "maxAverageTowSpeed",
    subscriptionName: "maxAverageTowSpeed",
    list: null
  },
  useSimplifiedEffort: {
    stateName: "useSimplifiedEffort",
    subscriptionName: "useSimplifiedEffort",
    list: null
  },
  hasTransfer: {
    stateName: "hasTransfer",
    subscriptionName: "hasTransfer",
    list: null
  },
  hasNbDaysFshd: {
    stateName: "hasNbDaysFshd",
    subscriptionName: "hasNbDaysFshd",
    list: null
  },
  hasEffortDetailNbHooks: {
    stateName: "hasEffortDetailNbHooks",
    subscriptionName: "hasEffortDetailNbHooks",
    list: null
  },
  fishingMethods: {
    stateName: "fishingMethods",
    subscriptionName: "fishingMethods",
    list: null
  },
  baitTypes: {
    stateName: "baitTypes",
    subscriptionName: "baitTypes",
    list: "baitType"
  },
  baitCategories: {
    stateName: "baitCategories",
    subscriptionName: "baitCategories",
    list: "baitCategory"
  },
  hasCargoWeight: {
    stateName: "hasCargoWeight",
    subscriptionName: "hasCargoWeight",
    list: null
  },
  hasEffortSampleSpecieNumber: {
    stateName: "hasEffortSampleSpecieNumber",
    subscriptionName: "hasEffortSampleSpecieNumber",
    list: null
  },
  hasEffortSampleSampleSpecieLength: {
    stateName: "hasEffortSampleSampleSpecieLength",
    subscriptionName: "hasEffortSampleSampleSpecieLength",
    list: null
  }
};
