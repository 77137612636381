<template>
  <div>
    <h4>
      {{ $t("editTrip.effortStep.sampleEffort") }}
    </h4>
    <fieldset
      v-for="(sample, sampleIndex) in effort.samples"
      :key="sampleIndex"
    >
      <h4>
        {{ $t("editTrip.effortStep.sampleEffort") }} {{ sampleIndex }}
        <span
          v-b-modal="
            'modal-Trip-' + effortSampleIndex + '-sample-remark' + sampleIndex
          "
          class="add-remark-btn"
        />
      </h4>
      <Popup
        v-model="sample['remark']"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="
          'modal-Trip-' + effortSampleIndex + '-sample-remark' + sampleIndex
        "
      />
      <Select
        :label="$t('editTrip.effortStep.specie')"
        v-model="sample['species']"
        :options="sampleOptions"
        :required="true"
        @error="addError"
        :refeshError="showErrorData"
      />
      <UnitConverter
        :label="$t('editTrip.effortStep.effortSampleSpecimenLength')"
        v-model="sample['length']"
        :required="true"
        :min="0"
        unit="cm"
        :max="999.9"
        @error="addError"
        :refresh="showErrorData"
      />
      <IntegerInput
        v-model="sample['number']"
        :label="$t('editTrip.effortStep.effortSampleSpecimenNumber')"
        :min="1"
        :max="999"
        :required="true"
        :refeshError="showErrorData"
        @error="addError"
      />
    </fieldset>
    <p>
      <input
        v-if="effort.samples.length > 0"
        @click="removeSample()"
        class="removeSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.removeSample')"
      />
      <input
        @click="addSample()"
        class="addSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.addSample')"
      />
    </p>
    <br />
  </div>
</template>
<script>
import { BModal } from "bootstrap-vue";
import { mapGetters, mapState } from "vuex";
import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    IntegerInput,
    Select,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    addError: Function,
    effort: Object,
    effortIndex: Number,
    effortSampleIndex: Number
  },
  data() {
    return {
      triggerShowError: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform
    }),
    sampleOptions() {
      return this.buildOptions(this.sampleSpecies, this.systemsLists.species);
    },
    sampleSpecies() {
      return this.getPropertyValue(
        "sampleSpecies",
        {
          subforms: this.subform
        },
        []
      );
    }
  },
  watch: {},
  methods: {
    removeSample() {
      this.effort.samples.pop();
    },
    addSample() {
      const vm = this;
      this.triggerShowError++;
      vm.$nextTick(() => {
        this.effort.samples.push({
          species: null,
          number: null,
          length: null
        });
      });
    }
  },
  beforeMount() {}
};
</script>
