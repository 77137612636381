<template>
  <b-container name="Crew Register" class="borders" fluid>
    <p class="small center">
      <img src="/img/icons/jobel-logo-transparent-96.png" />
      {{ version }}
    </p>
    <b-row name="Row 1: Header" class="top-bottom-borders dark-background">
      <b-col>
        <p class="center bold large">{{ $t("crew.register.title") }}</p>
      </b-col>
    </b-row>
    <b-row name="Row 2: Trip Identification" class="bottom-border">
      <b-col>
        <p class="center bold medium">
          {{ $t("trip") }} {{ tripNumber }} - {{ logbookId }}
        </p>
        <b-row name="Vessel" class="top-border">
          <b-col cols="3" class="bold light-background right-border">
            {{ $t("vessel") }}
          </b-col>
          <b-col cols="9">
            {{ currentTrip.vessel.name }}
            #{{ currentTrip.vessel.registeredNumber }}
          </b-col>
        </b-row>
        <b-row name="Dates" class="top-border">
          <b-col cols="3" class="bold light-background right-border">
            {{ $t("date") }}
          </b-col>
          <b-col cols="9">
            <span v-if="arrivalDate !== departureDate">
              {{ departureDate }} {{ $t("to") }} {{ arrivalDate }}
            </span>
            <span v-else>
              {{ departureDate }}
            </span>
          </b-col>
        </b-row>
        <b-row
          name="Target Species"
          class="top-border"
          v-if="targetSpecies.length > 0"
        >
          <b-col cols="3" class="bold light-background right-border">
            {{ $t("fields.targetSpecies") }}
          </b-col>
          <b-col cols="9">{{ targetSpecies }}</b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row name="Row 3: Operator" class="bottom-border">
      <b-col>
        <p class="center bold medium">
          {{ $t("section.operator") }}
        </p>
        <b-row name="Table Header">
          <b-col cols="4" class="bold">{{ $t("crew.name.label") }}</b-col>
          <b-col cols="2" class="bold">{{ $t("fin.short") }}</b-col>
          <b-col cols="6" class="bold">{{ $t("crew.address.label") }}</b-col>
        </b-row>
        <b-row class="odd-row">
          <b-col cols="4" class="right-border">
            {{ currentTrip.operator.name }}
          </b-col>
          <b-col cols="2" class="right-border">
            {{ currentTrip.operator.fisherIdentificationNumber }}
          </b-col>
          <b-col cols="6"></b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row name="Row 4: Crew Members" v-if="crewmenExist" class="bottom-border">
      <b-col>
        <p class="center bold medium">
          {{ $t("crew.register.members") }}
        </p>
        <b-row
          v-for="(crewman, index) in crewmen"
          :key="index"
          :class="oddOrEvenRow(index)"
        >
          <b-col cols="4" class="right-border">
            {{ crewman.name }}
          </b-col>
          <b-col cols="2" class="right-border">
            {{ crewman.fisherIdentificationNumber || "N/A" }}
          </b-col>
          <b-col cols="6">
            {{ crewman.address }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row name="Row 5: Crew Count" class="bottom-border">
      <b-col>
        <p class="center bold medium">
          {{ $t("fields.crewNb") }}: {{ currentTrip.crewNb }}
        </p>
      </b-col>
    </b-row>
    <footer>
      <div class="page modulesPage trip insideApp action-btn-wrapper">
        <div class="action-btn-wrapper-center">
          <a @click="backToList" class="action-btn-backtolist">
            <span v-html="returnToList" />
          </a>
        </div>
      </div>
    </footer>
    <p class="small center footer">
      {{ $t("crew.register.footer", { date: date }) }}
    </p>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import buildId from "@/buildId.json";
export default {
  name: "CrewRegister",
  computed: {
    ...mapState({
      currentTrip: state => state.selectedTrip,
      systemsLists: state => state.systemsLists
    }),
    logbookId() {
      return this.currentTrip.logbookUID;
    },
    tripNumber() {
      return this.currentTrip.tripNumber;
    },
    departureDate() {
      return this.dateOnly(this.currentTrip.departure.datetime);
    },
    arrivalDate() {
      // If no arrival date exist, assume it is the same as the departure date.
      return this.currentTrip.arrival.datetime
        ? this.dateOnly(this.currentTrip.arrival.datetime)
        : this.departureDate;
    },
    targetSpecies() {
      // Build the set of unique species by scanning all efforts, tows, and catches
      let species = new Set();
      if (this.currentTrip.efforts) {
        for (let effort of this.currentTrip.efforts) {
          if (effort.tows) {
            for (let tow of effort.tows) {
              if (tow.catches) {
                for (let onecatch of tow.catches) {
                  if (onecatch.species) {
                    species.add(onecatch.species);
                  }
                }
              }
            }
          }
        }
      }

      // Transform the Set into an Array
      let ids = [...species];

      // Map the IDs to their names
      let names = ids.map(id => {
        return this.systemsLists.species.find(s => s.value === id).text;
      });

      // Sort the array and convert to a list of names
      return names.sort().join(", ");
    },
    crewmen() {
      return this.currentTrip.crew;
    },
    crewmenExist() {
      return this.crewmen && this.crewmen.length > 0;
    },
    version() {
      return buildId.versionNumber;
    },
    date() {
      return new Date().toLocaleString(this.$i18n.locale);
    },
    returnToList() {
      return this.$router.currentRoute.params.from === "archive"
        ? this.$t("returnToArchives")
        : this.$t("returnToRegister");
    }
  },
  methods: {
    dateOnly(date) {
      return date.substring(0, date.indexOf("T"));
    },
    oddOrEvenRow(index) {
      return index % 2 === 1 ? "odd-row" : "even-row";
    },
    backToList() {
      if (this.$router.currentRoute.params.from === "archive") {
        this.$router.push({
          name: "archiveList",
          params: {
            uuid: this.$route.params.uuid,
            startdate: this.$route.params.startdate,
            enddate: this.$route.params.enddate
          }
        });
      } else if (this.$router.currentRoute.params.from === "module") {
        this.$router.push({
          name: "module",
          params: { uuid: this.$route.params.uuid }
        });
      } else {
        alert("Route not supported");
      }
    }
  },
  mounted() {
    setTimeout(window.print, 500);
  }
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 5px;
  width: 100%;
}
.grey {
  color: darkgrey;
}
.bold {
  font-weight: bold;
}
.borders {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: black;
}
.bottom-border {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: black;
}
.top-bottom-borders {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: black;
}
.right-border {
  border-right-width: 1px;
  border-style: solid;
  border-color: black;
}
.top-border {
  border-top-width: 1px;
  border-style: solid;
  border-color: black;
}
.even-row {
  border-top-width: 1px;
  border-style: solid;
  border-color: black;
  background-color: rgb(226, 226, 226);
}
.odd-row {
  border-top-width: 1px;
  border-style: solid;
  border-color: black;
  background-color: rgb(192, 192, 192);
}
.center {
  text-align: center;
}
.large {
  font-size: 2em;
}
.medium {
  font-size: 1.5em;
}
.small {
  font-size: 0.88em;
}
.dark-background {
  background-color: darkgrey;
}
.light-background {
  background-color: lightgrey;
}
p {
  padding: 0.5em;
}
</style>
