<template>
  <div name="TransfersViewer">
    <h4 class="darkTitle">
      <span>
        <span>
          {{ $t("editTrip.lastStep.transfers") }}
        </span>
      </span>
    </h4>
    <div v-for="(transfer, index) in transfers" :key="'transfer-' + index">
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="listItemTitle startContext subTitle">
            {{ $t("editTrip.lastStep.transfer") }}&nbsp;{{ index + 1 }}
          </h4>
          <b-col>
            <b-row>
              <b-col v-if="transfer.remark" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="transfer.remark"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.transferFrom" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.transferFrom')"
                  :value="transfer.transferFrom"
                  :options="$t('productFrom.items')"
                  type="select"
                />
              </b-col>
              <b-col v-if="needDisplayFromLabel(index)" lg="4" sm="6" cols="12">
                <LabelView
                  :value="$t('editTrip.lastStep.transferFrom')"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="transfer.vesselFrom && transfer.vesselFrom.name"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.vesselName')"
                  :value="transfer.vesselFrom.name"
                  type="text"
                />
              </b-col>
              <b-col
                v-if="
                  transfer.vesselFrom && transfer.vesselFrom.registeredNumber
                "
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.vesselNumber')"
                  :value="transfer.vesselFrom.registeredNumber"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.pondFromNumber" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.pondNumber')"
                  :value="transfer.pondFromNumber"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="transfer.transferTo" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.transferTo')"
                  :value="transfer.transferTo"
                  :options="$t('productTo.items')"
                  type="select"
                />
              </b-col>
              <b-col v-if="needDisplayToLabel(index)" lg="4" sm="6" cols="12">
                <LabelView
                  :value="$t('editTrip.lastStep.transferTo')"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="transfer.vesselTo && transfer.vesselTo.name"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.vesselName')"
                  :value="transfer.vesselTo.name"
                  type="text"
                />
              </b-col>
              <b-col
                v-if="transfer.vesselTo && transfer.vesselTo.registeredNumber"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.vesselNumber')"
                  :value="transfer.vesselTo.registeredNumber"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.pondToNumber" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.pondNumber')"
                  :value="transfer.pondToNumber"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="transfer.releasedAtSea" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.releasedAtSea')"
                  :value="transfer.releasedAtSea"
                  type="select"
                  :options="systemsLists.response"
                />
              </b-col>
              <b-col v-if="transfer.webRemoved" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.webRemoved')"
                  :value="transfer.webRemoved"
                  type="select"
                  :options="systemsLists.response"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="transfer.isLoad" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.isLoad')"
                  :value="transfer.isLoad"
                  :options="$t('productDestination.items')"
                  type="select"
                />
              </b-col>
              <b-col v-if="transfer.datetime" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('transfer.datetime')"
                  :value="$showDate(transfer.datetime)"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.duration" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.duration')"
                  :value="transfer.duration"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.towingTime" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.towingTime')"
                  :value="transfer.towingTime"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.towingDistance" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.towingDistance')"
                  :value="transfer.towingDistance"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer.fromTowNumIdx" lg="4" sm="6" cols="12">
                <TowSelector
                  :value="transfer.fromTowNumIdx"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col v-if="transfer.fromTowNum" lg="4" sm="6" cols="12">
                <TowSelector
                  :value="transfer.fromTowNum"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col lg="4" cols="6" v-if="transfer?.catchPosition?.latitude">
                <LabelView
                  :label="$t('latitude')"
                  :value="latitude(transfer.catchPosition.latitude)"
                  type="text"
                />
              </b-col>
              <b-col lg="4" cols="6" v-if="transfer?.catchPosition?.longitude">
                <LabelView
                  :label="$t('longitude')"
                  :value="longitude(transfer.catchPosition.longitude)"
                  type="text"
                />
              </b-col>
              <b-col v-if="transfer['closeDatetime']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('dataGroupClosureDate')"
                  :value="$showDate(transfer['closeDatetime'])"
                  type="text"
                />
              </b-col>
            </b-row>
            <!-- NOTE: Cargo entries in Jobel are called Transfer details by the MPO. -->
            <h4 v-if="transfer.cargos && transfer.cargos.length > 0">
              {{ $t("editTrip.lastStep.details") }}
            </h4>
            <b-row
              v-for="(cargo, cargoIndex) in transfer.cargos"
              :key="'cargo-' + cargoIndex"
            >
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.specie')"
                  :value="cargo.species"
                  :options="systemsLists.species"
                  type="select"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.productForm')"
                  :value="cargo.productForm"
                  :options="systemsLists.productForm"
                  type="select"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <UnitConverter
                  :label="$t('editTrip.lastStep.weight')"
                  :value="cargo.weight"
                  unit="weight"
                  viewOnly
                />
              </b-col>
              <b-col v-if="cargo.mortalityWeight" lg="4" sm="6" cols="12">
                <UnitConverter
                  :label="$t('editTrip.lastStep.mortalityWeight')"
                  :value="cargo.mortalityWeight"
                  unit="weight"
                  viewOnly
                />
              </b-col>
              <b-col v-if="cargo.logbookNumber" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.logbookNumber')"
                  :value="cargo.logbookNumber"
                  type="text"
                />
              </b-col>
              <b-col v-if="cargo.towNum" lg="4" sm="6" cols="12">
                <TowSelector
                  :value="cargo.towNum"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col v-if="cargo.towNumIdx" lg="4" sm="6" cols="12">
                <TowSelector
                  :value="cargo.towNumIdx"
                  :efforts="currentTrip.efforts"
                  viewOnly
                />
              </b-col>
              <b-col v-if="cargo.remark" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="cargo.remark"
                  type="text"
                />
              </b-col>
            </b-row>
          </b-col>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Geolocation from "@/utils/geolocation.js";
import TowSelector from "@/components/widgets/TowSelector.vue";

export default {
  name: "TransfersViewer",
  components: {
    LabelView,
    TowSelector,
    UnitConverter
  },
  props: {
    transfers: Array
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile
    })
  },
  methods: {
    needDisplayFromLabel(index) {
      let transfer = this.transfers[index];
      if (
        !transfer["transferFrom"] &&
        ((transfer.vesselFrom && transfer.vesselFrom.registeredNumber) ||
          transfer["pondFromNumber"])
      ) {
        return true;
      }
      return false;
    },
    needDisplayToLabel(index) {
      let transfer = this.transfers[index];
      if (
        !transfer["transferTo"] &&
        ((transfer.vesselTo && transfer.vesselTo.registeredNumber) ||
          transfer["pondToNumber"])
      ) {
        return true;
      }
      return false;
    },
    latitude(lat) {
      const deg = lat;
      const dms = Geolocation.convertDD2toDMS(deg, "lat").toString;
      return `${dms} = ${deg} °`;
    },
    longitude(lon) {
      const deg = lon;
      const dms = Geolocation.convertDD2toDMS(deg, "lon").toString;
      return `${dms} = ${deg} °`;
    }
  }
};
</script>
