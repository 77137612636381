<template>
  <a @click="returnClick">
    <span v-if="returnToArchives" v-html="$t('returnToArchives')" />
    <span v-else v-html="$t('returnToRegister')" />
  </a>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ReturnButton",
  props: {
    returnToArchives: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription
    })
  },
  methods: {
    returnClick() {
      // This is the default route to return to the Trip Register page
      let name = "module";
      let params = { uuid: this.subscription.uuid };

      // ... and this is the route to return to the Archives page
      if (this.returnToArchives) {
        name = "archiveList";
        params["startdate"] = this.$route.params.startdate;
        params["enddate"] = this.$route.params.enddate;
      }

      this.$router.push({ name: name, params: params });
    }
  }
};
</script>
