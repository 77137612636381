var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page modulesPage archive insideApp",attrs:{"id":"pageArchive_modules"}},[_c('h1',[_vm._v(" Module "),_c('span',{staticClass:"moduleName"},[_vm._v(_vm._s(_vm.subscription.module.label))])]),_c('h2',[_vm._v(_vm._s(_vm.$t("archivePageHeader")))]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"topPageMenu"},[_c('a',{staticClass:"backToPageArchiveLobby",domProps:{"innerHTML":_vm._s(_vm.$t('returnToMyArchives'))},on:{"click":function($event){return _vm.clickReturn()}}})]),_c('FaqManager',{attrs:{"faq-data":_vm.$t('archive.faqDataNoDatePicker')}}),_c('form',{attrs:{"id":"archiveWindowSelector","novalidate":"novalidate"}},[_c('fieldset',[_c('Select',{attrs:{"label":_vm.$t('archive.period'),"options":_vm.$t('archive.dateOptions'),"required":true,"enable-sort":false},model:{value:(_vm.dateSelection),callback:function ($$v) {_vm.dateSelection=$$v},expression:"dateSelection"}}),_c('br'),(_vm.customDate)?_c('span',{staticClass:"line-block-no-line"},[_c('p',{staticClass:"leftHalf"},[_c('span',{staticClass:"label"},[_c('span',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("archive.from")))])])]),_c('span',{staticClass:"line-block-no-line noPadding"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.startDatetime),expression:"startDatetime"}],staticClass:"valid",attrs:{"name":"startDatetimeTxt","type":"text"},domProps:{"value":(_vm.startDatetime)},on:{"input":function($event){if($event.target.composing)return;_vm.startDatetime=$event.target.value}}}),_c('a',{staticClass:"customDateIcon"},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: 'short'
                    },"id":"datepicker-start","size":"sm","boundary":"viewport","button-only":"","today-button":"","reset-button":"","close-button":""},model:{value:(_vm.startDatetime),callback:function ($$v) {_vm.startDatetime=$$v},expression:"startDatetime"}})],1)]),(_vm.errorStart)?_c('span',{staticClass:"error small"},[_vm._v(" "+_vm._s(_vm.errorStart)+" ")]):_vm._e()]),_c('p',{staticClass:"rightHalf"},[_c('span',{staticClass:"label"},[_c('span',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("archive.until")))])])]),_c('span',{staticClass:"line-block-no-line noPadding"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.endDatetime),expression:"endDatetime"}],staticClass:"valid",attrs:{"name":"endDatetimeTxt","type":"text"},domProps:{"value":(_vm.endDatetime)},on:{"input":function($event){if($event.target.composing)return;_vm.endDatetime=$event.target.value}}}),_c('a',{staticClass:"customDateIcon"},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: 'short'
                    },"id":"datepicker-end","size":"sm","boundary":"viewport","button-only":"","today-button":"","reset-button":"","close-button":""},model:{value:(_vm.endDatetime),callback:function ($$v) {_vm.endDatetime=$$v},expression:"endDatetime"}})],1)]),(_vm.errorEnd)?_c('span',{staticClass:"error small"},[_vm._v(" "+_vm._s(_vm.errorEnd)+" ")]):_vm._e()])]):_vm._e(),_c('fieldset',[_c('input',{staticClass:"addSuppEntryButton",attrs:{"value":_vm.$t('archive.search'),"disabled":!(_vm.startDatetime && _vm.endDatetime),"type":"button"},on:{"click":_vm.refreshList}})])],1)]),_vm._m(0),_c('h2',{staticClass:"tableTitle"},[_c('span',[_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("archive.trips"))+" "),_c('span',[_vm._v(" ("+_vm._s(_vm.displayedTrips.length)+") ")])])])])]),_c('table',{staticClass:"tableList archive"},[_c('tbody',[_c('tr',{staticClass:"tableHead"},[_c('th',[_vm._v(" "+_vm._s(_vm.$t("date"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("archive.keptInfo"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("actions"))+" ")])]),(_vm.displayedTrips && _vm.displayedTrips.length === 0)?_c('tr',{staticClass:"empty-list"},[_c('td',{staticClass:"empty-list sortingDatetime-column column-title"},[_vm._v(" ... ")]),_c('td',{staticClass:"empty-list catchSummary-column column-title"},[_vm._v("...")]),_c('td',{staticClass:"empty-list actions-btn-column column-title"},[_vm._v("...")])]):_vm._e(),_vm._l((_vm.displayedTrips),function(trip,index){return _c('tr',{key:index},[_c('td',{staticClass:"archive left"},[_c('a',[_c('label',{staticClass:"normal"},[_vm._v(" "+_vm._s(_vm.formatDate(trip["datetime"]))+" ")])])]),_c('td',{staticClass:"archive"},[_c('label',{staticClass:"normal"},[_vm._v(_vm._s(_vm.catchValue(trip)))])]),_c('td',{staticClass:"archive right"},[_c('div',{staticClass:"btn-add",on:{"click":function($event){return _vm.viewTrip(trip)}}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("triplist.action.view") + " " + trip.logbookUID)+" ")])])])])})],2)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"hide"},[_c('tbody',{staticClass:"row-trip-template"},[_c('tr',[_c('td',{staticClass:"sortingDatetime-column sortingDatetime"}),_c('td',{staticClass:"catchSummary-column catchSummary"}),_c('td',{staticClass:"actions-btn-column actions-btn"},[_c('a',{staticClass:"btn-view"},[_vm._v("Voir le voyage")])])])])])
}]

export { render, staticRenderFns }