<template>
  <Select
    v-model="lgrid"
    :label="$t('lgrid')"
    :options="lobsterGridOptions"
    :required="required"
    :refeshError="refresh"
    @error="checkError"
    @input="onInput"
  />
</template>

<script>
import Select from "@/components/Select.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "LobsterGrid",
  components: {
    Select
  },
  props: {
    value: {
      required: true
    },
    area: {
      type: Number,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    // Allows the parent to trigger a refresh operation. When 'refresh' changes, the
    // lobster grid value is checked for validity; i.e. present in the list of options.
    refresh: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
      lgrid: null
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({ lGrids: state => state.systemsLists.lGrids }),
    lobsterGrids() {
      return this.getPropertyValue("lobsterGrids", { areas: this.area }, []);
    },
    lobsterGridOptions() {
      return this.buildOptions(this.lobsterGrids, this.lGrids, this.required);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        this.lgrid = newValue;
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    checkError(value) {
      this.$emit("error", value);
    }
  }
};
</script>
