<template>
  <div id="pageChooseModule" class="page insideApp">
    <h1>{{ $t("lobby.choose") }}</h1>
    <div class="content">
      <div class="topPageMenu">
        <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModulesManage"
};
</script>
