<template>
  <div>
    <h4 v-if="hasNbVntch || hasNbVntchYou || hasBigLobster">
      {{ $t("editTrip.effortStep.special") }}
    </h4>
    <fieldset class="catchEntriesContainer">
      <IntegerInput
        v-if="hasNbVntch"
        :label="$t('editTrip.effortStep.nbVnotchObserved')"
        v-model="tow['nbVnotchObserved']"
        :required="hasNbVntch === $const.MANDATORY"
        :min="0"
        :max="SPECIMEN_MAX"
        @error="addErrorToParent"
        :refeshError="showErrorData"
      />
      <IntegerInput
        v-if="hasNbVntchYou"
        :label="$t('editTrip.effortStep.nbSelfVnotched')"
        v-model="tow['nbSelfVnotched']"
        :required="hasNbVntchYou === $const.MANDATORY"
        :min="0"
        :max="SPECIMEN_MAX"
        @error="addErrorToParent"
        :refeshError="showErrorData"
      />
      <IntegerInput
        v-if="hasBigLobster"
        :label="$t('editTrip.effortStep.nbBigLobster')"
        v-model="tow['nbBigLobster']"
        :required="hasBigLobster === $const.MANDATORY"
        :min="0"
        :max="SPECIMEN_MAX"
        @error="addErrorToParent"
        :refeshError="showErrorData"
      />
    </fieldset>
  </div>
</template>
<script>
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    IntegerInput
  },
  data() {
    return {
      hasNbVntch: 0,
      hasNbVntchYou: 0,
      hasBigLobster: 0
    };
  },
  props: {
    area: Number,
    tow: Object,
    showErrorData: Number,
    addErrorToParent: Function,
    SPECIMEN_MAX: Number
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subform: state => state.editTripSubform.subform
    })
  },
  watch: {
    area() {
      this.updateMapping();
    }
  },
  methods: {
    updateMapping() {
      this.hasNbVntch = this.valueBySubformAndArea("hasNbVntch");
      if (this.hasNbVntch === 0) {
        this.tow["nbVnotchObserved"] = null;
      }
      this.hasNbVntchYou = this.valueBySubformAndArea("hasNbVntchYou");
      if (this.hasNbVntchYou === 0) {
        this.tow["nbSelfVnotched"] = null;
      }
      this.hasBigLobster = this.valueBySubformAndArea("hasBigLobster");
      if (this.hasBigLobster === 0) {
        this.tow["nbBigLobster"] = null;
      }
    },
    valueBySubformAndArea(key) {
      return this.getPropertyValue(key, {
        subforms: this.subform,
        areas: this.area
      });
    }
  },
  beforeMount() {
    this.updateMapping();
  }
};
</script>
