var dateFormat = require("dateformat");

class DateFormat {
  static toStringDate(date, string) {
    return dateFormat(date, string);
  }

  static getZone(userProfile) {
    let zone = "Canada/Eastern";
    try {
      zone = userProfile.timezone;
    } catch (err) {}
    return zone;
  }
}

module.exports = DateFormat;
