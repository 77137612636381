<template>
  <p name="PasswordInput" class="field-wrapper">
    <span v-if="label" class="label" style="height:auto">
      {{ label }}
      <a v-if="hasHelp" :id="'tooltipButton' + id" class="dot"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        :target="'tooltipButton' + id"
        :title="help"
        placement="topleft"
        variant="primary"
        triggers="click"
      >
      </b-tooltip>
    </span>
    <span :class="displayPassword ? 'line-block' : 'line-block-no-line'">
      <b-input
        :style="inputStyle"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :name="id"
        :type="showPassword ? 'text' : type"
        :autocomplete="hasAutocomplete"
        :class="errorClass"
        @blur="focusChanged"
        @input="onChange($event)"
      />
      <a
        v-if="displayPassword"
        style="display: flex; margin: auto;"
        @click.prevent="toggleShow"
      >
        <b-icon :icon="showPassword ? 'eye-slash' : 'eye'" scale="2"></b-icon>
      </a>
    </span>
    <router-link
      v-if="hasReset"
      :to="{ name: 'connexionreset' }"
      class="resetPassword"
    >
      {{ $t("connexion.nopassword") }} ?
    </router-link>
    <router-link
      v-if="hasChange"
      :to="{ name: 'passwordchange' }"
      class="resetPassword"
    >
      {{ $t("connexion.changePassword") }} ?
    </router-link>
    <label
      v-if="isError || forceErrorMsg || forceErrorMsgNoBlock"
      class="error"
    >
      {{ errorMessage }}
    </label>
  </p>
</template>
<script>
import Validator from "@/utils/validator.js";
export default {
  name: "PasswordInput",
  props: {
    value: {
      required: true
    },
    autocomplete: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      default: undefined
    },
    minlength: {
      default: undefined
    },
    help: {
      type: String,
      default: ""
    },
    displayPassword: {
      type: Boolean,
      default: false
    },
    resetPassword: {
      type: Boolean,
      default: false
    },
    changePassword: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    forceErrorMsgNoBlock: {
      default: undefined
    },
    refeshError: {
      default: false
    },
    iconSize: {
      type: String,
      required: false,
      default: "2rem"
    },
    iconColor: {
      type: String,
      required: false,
      default: "#a0aec0"
    }
  },
  data() {
    return {
      id: "PasswordInput #" + (Math.random() * 100000).toFixed(0),
      defaultErrorMsg: undefined,
      errorClass: { error: false },
      hasHelp: this.help,
      hasReset: this.resetPassword,
      hasChange: this.changePassword,
      hasAutocomplete: this.autocomplete,
      showPassword: false,
      isError: false,
      inputStyle: null,
      type: "password"
    };
  },
  computed: {
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      } else if (this.forceErrorMsgNoBlock) {
        msg = this.forceErrorMsgNoBlock;
      }

      return msg;
    },
    filter() {
      return {
        type: this.type,
        required: this.required,
        minlength: this.minlength,
        maxlength: this.maxlength
      };
    },
    iconStyle() {
      return {
        height: this.iconSize,
        width: this.iconSize,
        color: this.iconColor,
        fill: "currentColor"
      };
    }
  },
  watch: {
    refeshError() {
      this.refreshErrorMethod();
    },
    refresh() {
      this.setError();
    },
    isError() {
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    }
  },
  mounted() {
    this.inputStyle = null;
    if (this.displayPassword) {
      this.inputStyle = {
        "background-color": "#f9f9f9",
        "-webkit-box-shadow": "0 1px 3px rgba(0, 0, 0, .15)",
        "-moz-box-shadow": "0 1px 3px rgba(0, 0, 0, .15)",
        "box-shadow": "0 1px 3px rgba(0, 0, 0, .15)",
        border: "none",
        "border-right": "solid 1px gray",
        "box-sizing": "border-box",
        margin: "0%",
        width: "85%"
      };
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  },
  methods: {
    focusChanged(e) {
      this.setError(e.target.value);
      let value = e.target.value;
      this.$emit("binding", value);
    },
    onChange(value) {
      if (value === undefined || value === "" || value === null) {
        value = null;
      }
      this.$emit("input", value);
    },
    refreshErrorMethod() {
      this.setError(this.value);
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;

      if (rt.notBlocked || this.forceErrorMsgNoBlock) {
        return;
      }
      this.$emit("error", object);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>
