<template>
  <fieldset name="FishingInfos">
    <b-row style="padding:0 1em">
      <b-col v-if="displayLicenceNumber" lg="4" sm="6" cols="12">
        <LabelView
          type="text"
          :label="$t('editTrip.effortStep.licenceNumber')"
          :value="effort.licenceNumber"
        />
      </b-col>
      <b-col v-if="licence.target" lg="4" sm="6" cols="12">
        <LabelView
          type="select"
          :label="$t('editTrip.effortStep.target')"
          :value="licence.target"
          :options="targetSpecies"
        />
      </b-col>
      <b-col v-if="licence.targetOtherDescription" lg="4" sm="6" cols="12">
        <LabelView
          type="text"
          :label="$t('fields.targetOtherDescription')"
          :value="licence.targetOtherDescription"
        />
      </b-col>
      <b-col v-if="licence.area && hasEffortArea" lg="4" sm="6" cols="12">
        <LabelView
          type="select"
          :label="$t('fields.area')"
          :value="licence.area"
          :options="systemsLists.areas"
        />
      </b-col>
      <b-col v-if="licence.lgrid" lg="4" sm="6" cols="12">
        <LabelView
          :label="$t('lgrid')"
          :value="effort.endPosition['lgrid']"
          :options="systemsLists.lGrids"
          type="select"
        />
      </b-col>
      <b-col v-if="licence.nbGear" lg="4" sm="6" cols="12">
        <LabelView
          type="text"
          :label="$t('editTrip.effortStep.nbGearMax')"
          :value="licence.nbGear"
        />
      </b-col>
      <b-col v-if="licence.section" lg="4" sm="6" cols="12">
        <LabelView
          type="select"
          :label="$t('editTrip.effortStep.ssSection')"
          :value="licence.section"
          :options="systemsLists.ssSections"
        />
      </b-col>
      <b-col v-if="licence.fishingType" lg="4" sm="6" cols="12">
        <LabelView
          type="select"
          :label="$t('editTrip.effortStep.fishingType')"
          :value="licence.fishingType"
          :options="systemsLists.fishingType"
        />
      </b-col>
    </b-row>
  </fieldset>
</template>
<script>
import LabelView from "@/components/LabelView.vue";

import { mapping } from "@/utils/FormStateMapping";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "FishingInfoDisplay",
  components: {
    LabelView
  },
  props: {
    licence: Object,
    displayLicenceNumber: Boolean,
    effort: Object,
    showErrorData: Number,
    addErrorToParent: Function
  },
  data() {
    return { firstTime: true };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      targetSpecies: state =>
        state.editTripSubform[mapping.TargetSpecies.stateName],
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform,
      form: state => state.currentOpenTrip.form
    }),
    // TODO: Un viewer ne devrait pas avoir à consulter une prop pour savoir s'il affiche un champ.
    hasEffortArea() {
      return this.getPropertyValue("hasEffortArea", {
        subforms: this.subform,
        targetSpecies: this.effort.target
      });
    },
    aTestDemoFor_Grid_SpeciesGridNafoFma_234() {
      return this.getPropertyValue(
        "aTestDemoFor_Grid_SpeciesGridNafoFma_234",
        {
          form: this.form,
          targetSpecies: this.effort.target,
          areas: this.effort.area
        },
        []
      );
    },
    aTestDemoFor_NafoGrid_SpeciesGridNafoFma_235() {
      return this.getPropertyValue(
        "aTestDemoFor_NafoGrid_SpeciesGridNafoFma_235",
        {
          form: this.form,
          targetSpecies: this.effort.target,
          areas: this.effort.area
        },
        []
      );
    }
  },
  watch: {
    licence: {
      handler() {
        if (this.firstTime) {
          this.firstTime = false;
          return;
        }
        this.updateEffort();
      },
      deep: true
    },
    "effort.area": function() {
      this.buildGridValidation();
    }
  },
  methods: {
    ...mapActions(["updateSubStateGeneric"]),
    updateEffort() {
      this.effort.target = this.licence.target;
      if (this.hasEffortArea) {
        this.effort.area = this.licence.area;
      }
      this.effort.nbGearMax = this.licence.nbGear;
      this.effort.ssSection = this.licence.section;
      this.effort.fishingType = this.licence.fishingType;
    },
    buildGridValidation() {
      let grids = this.aTestDemoFor_Grid_SpeciesGridNafoFma_234;

      if (grids.length > 0) {
        //remove the map number of the validation
        grids = grids.map(grids => grids.slice(1));
        this.updateSubStateGeneric({
          name: "gridValidationArray",
          value: grids
        });
      } else {
        grids = this.aTestDemoFor_NafoGrid_SpeciesGridNafoFma_235;

        for (const property in grids) {
          let grid = grids[property];
          grid = grid.map(grid => grid.slice(1));
          grids[property] = grid;
        }

        this.updateSubStateGeneric({
          name: "nafoGridValidationArray",
          value: grids
        });
      }
    }
  },
  beforeMount() {
    if (!this.hasEffortArea) {
      this.effort.area = null;
    }
    this.updateEffort();
  }
};
</script>
