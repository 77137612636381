<template>
  <p class="field-wrapper" :class="{ uneditable: uneditable }">
    <span class="label">
      <span>
        <label v-if="useDays">
          {{ $t("editTrip.effortStep.soakDurationInDays") }}
        </label>
        <label v-if="!useDays">
          {{ $t("editTrip.effortStep.soakDuration") }}
        </label>
      </span>
    </span>
    <!-- When only days makes sense -->
    <b-input
      v-if="useDays"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="durationInDays"
      :name="id"
      @input="onChange($event)"
      @keydown="onKeydown($event)"
      type="number"
      :class="errorClass"
      @blur="focusChanged"
    />

    <!-- DFO precision requires minutes -->
    <b-container v-if="!useDays" class="soakDurationWidget">
      <b-row>
        <b-col cols="4">
          <b-form-select
            :options="dayOption"
            v-model="selectedDay"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :name="id"
            :class="errorClass"
          />
        </b-col>
        <b-col cols="4">
          <b-form-select
            :options="hourOption"
            v-model="selectedHour"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :class="errorClass"
          />
        </b-col>
        <b-col cols="4">
          <b-form-select
            :options="minuteOption"
            v-model="selectedMinute"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :class="errorClass"
          />
        </b-col>
      </b-row>
    </b-container>
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "SoakDuration",
  props: [
    "value",
    "required",
    "disabled",
    "placeholder",
    "label",
    "refeshError",
    "errorMsg",
    "forceErrorMsg",
    "uneditable",
    "soakDurationMax",
    "useDays"
  ],
  data() {
    return {
      id: Math.round(Math.random() * 100000) + "",
      durationInDays: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      selectClass: [],
      filter: {
        type: "duration",
        required: this.required,
        min: 1,
        max: 99999
      },
      dayOption: [{ value: 0, text: "0 Day" }],
      hourOption: [{ value: 0, text: "0 Hour" }],
      minuteOption: [{ value: 0, text: "0 Minute" }],
      selectedDay: 0,
      selectedHour: 0,
      selectedMinute: 0,
      isError: false
    };
  },
  computed: {
    valueLength() {
      return this.value ? this.value.length : null;
    },
    errorMessage() {
      let msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }

      return msg;
    }
  },
  watch: {
    refeshError() {
      this.refreshErrorMethod();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value() {
      if (this.useDays) {
        this.durationInDays = this.value / (24 * 60);
      } else {
        if (this.value) {
          this.setDayHourMinute();
        } else {
          this.selectedDay = 0;
          this.selectedHour = 0;
          this.selectedMinute = 0;
        }
      }
    },
    soakDurationMax: {
      immediate: true,
      handler: function(newDuration) {
        if (newDuration) {
          this.filter.max = newDuration;
          if (!this.useDays) {
            this.setDayHourMinuteOptions();
          }
        }
      }
    }
  },
  methods: {
    refreshErrorMethod() {
      this.setError(this.value);
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;

      if (rt.notBlocked) {
        return;
      }
      this.$emit("error", object);
    },
    setCharAt(str, index, chr) {
      if (str === undefined) {
        return str;
      }
      if (index > str.length - 1) return str;
      return str.substring(0, index) + chr + str.substring(index + 1);
    },
    setDayHourMinute() {
      const time = parseInt(this.value);
      this.selectedDay = Math.trunc(time / 24 / 60);
      this.selectedHour = Math.trunc((time / 60) % 24);
      this.selectedMinute = time % 60;
    },
    setDuration() {
      let value = 0;
      value =
        this.selectedDay * 24 * 60 +
        this.selectedHour * 60 +
        this.selectedMinute;
      this.setError(value);
      this.$emit("input", value);
    },
    setDayHourMinuteOptions() {
      const time = parseInt(this.soakDurationMax);
      const day = time / 24 / 60;

      let hour = 0;
      if (time >= 60 * 24) {
        hour = 23;
      } else {
        hour = time / 60;
      }

      let minute = 0;
      if (time >= 60) {
        minute = 59;
      } else {
        minute = time;
      }

      this.dayOption = [];
      let i = 0;
      while (i <= day) {
        if (i <= 1) {
          this.dayOption.push({ value: i, text: i + " Day" });
        } else {
          this.dayOption.push({ value: i, text: i + " Days" });
        }
        i++;
      }

      this.hourOption = [];
      i = 0;
      while (i <= hour) {
        if (i <= 1) {
          this.hourOption.push({ value: i, text: i + " Hour" });
        } else {
          this.hourOption.push({ value: i, text: i + " Hours" });
        }
        i++;
      }

      this.minuteOption = [];
      i = 0;
      while (i <= minute) {
        if (i <= 1) {
          this.minuteOption.push({ value: i, text: i + " Minute" });
        } else {
          this.minuteOption.push({ value: i, text: i + " Minutes" });
        }
        i++;
      }
    },
    focusChanged(e) {
      this.setError(this.value);
      this.$emit("binding", this.value);
    },
    onChange(value) {
      if (value === undefined || value === "") {
        value = null;
      } else if (this.useDays) {
        value = value * (24 * 60);
      } else {
        // value = parseInt(value);
        // this.$set(this.value, "value", value);
        // if (this.digits) {
        //   value = parseFloat(value);
        // }
      }
      this.$emit("input", value);
    },
    onKeydown(e) {
      if (e.key == "." || e.key == ",") {
        e.preventDefault();
      }
    }
  },
  mounted() {
    if (this.useDays) {
      if (this.value) {
        this.durationInDays = Math.trunc(this.value / (24 * 60));
      }
    } else {
      if (this.value) {
        this.setDayHourMinute();
      } else {
        this.selectedDay = 0;
        this.selectedHour = 0;
        this.selectedMinute = 0;
      }

      if (this.soakDurationMax) {
        this.setDayHourMinuteOptions();
      }
    }
  },
  created() {},
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
    //document.removeEventListener('focusout', this.focusChanged)
  }
};
</script>

<style lang="scss" scoped>
.col-4 {
  padding: 0;
}

.label {
  color: #000;
}

.page form div > input:not([type="submit"]):not([type="button"]) {
  padding-right: 0.2rem;
  border-right-width: 0px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
</style>
