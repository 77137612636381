<template>
  <div>
    <h4>
      {{ $t("editTrip.effortStep.tags") }}
    </h4>
    <fieldset v-for="(tag, indexTag) in tags" :key="indexTag">
      <b-overlay
        :show="tag['closeDatetime'] !== undefined"
        rounded="sm"
        blur="none"
      >
        <template #overlay>
          <b-row>
            <b-col cols="1" class="overlayTextPosition">
              <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
            </b-col>
            <b-col>
              <p class="redMessage">{{ $t("closeMsg") }}</p>
              <br />
              <p class="redMessage">
                {{ $t("closeMsgDate") }}{{ showDate(tag["closeDatetime"]) }}
              </p>
            </b-col>
          </b-row>
        </template>
        <fieldset :disabled="tag['closeDatetime'] !== undefined">
          <h4>
            {{ $t("editTrip.effortStep.tag") }} {{ indexTag + 1 }}
            <span
              v-b-modal="'modal-Trip-Tags-' + indexTag + '-remark'"
              class="add-remark-btn"
            />
          </h4>
          <Popup
            v-model="tag['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-Trip-Tags-' + indexTag + '-remark'"
          />

          <Select
            :label="$t('editTrip.effortStep.specie')"
            v-model="tag['species']"
            :options="systemsLists.species"
            :required="true"
            @error="checkError"
            :refeshError="showErrorData + refreshIndex[indexTag]"
          />
          <GenericInput
            :label="$t('editTrip.effortStep.tag1')"
            v-model="tag['tag1']"
            :maxlength="40"
            type="text"
            :required="true"
            @error="checkError"
            :refeshError="showErrorData + refreshIndex[indexTag]"
          />
          <GenericInput
            v-if="hasTagNum2"
            :label="$t('editTrip.effortStep.tag2')"
            v-model="tag['tag2']"
            :maxlength="40"
            :required="hasTagNum2 === $const.MANDATORY"
            type="text"
            @error="checkError"
            :refeshError="showErrorData + refreshIndex[indexTag]"
          />
          <UnitConverter
            v-if="hasTagPosition"
            v-model="tag['depth']"
            :min="0"
            :max="9999"
            unit="depth"
            :label="$t('editTrip.effortStep.depth')"
            :required="hasTagPosition === $const.MANDATORY"
            :refresh="showErrorData + refreshIndex[indexTag]"
            @error="checkError"
          />
          <UnitConverter
            v-if="hasTagPosition"
            v-model="tag['length']"
            :max="999.9"
            :min="0.1"
            :label="$t('editTrip.effortStep.effortSampleSpecimenLength')"
            unit="cm"
            :required="hasTagPosition === $const.MANDATORY"
            :refresh="showErrorData + refreshIndex[indexTag]"
            @error="checkError"
          />
          <GeopositionWidget
            v-if="hasTagPosition"
            :position="tag['position']"
            @binding="
              value => {
                tag['position'] = value;
              }
            "
            :minLat="[minTagCatchLatitude]"
            :maxLat="[maxTagCatchLatitude]"
            :minLong="[minTagCatchLongitude]"
            :maxLong="[maxTagCatchLongitude]"
            :dissableSSSections="true"
            :required="hasTagPosition === $const.MANDATORY"
            :refeshError="showErrorData + refreshIndex[indexTag]"
            @error="checkError"
          />
        </fieldset>
      </b-overlay>
      <fieldset class="suppEntryButtonWrapper">
        <p>
          <input
            v-if="!tag['closeDatetime']"
            @click="closeTag(indexTag)"
            class="closeSuppEntryButton"
            type="button"
            :value="$t('editTrip.effortStep.closeTag')"
          />
        </p>
      </fieldset>
    </fieldset>
    <p>
      <input
        v-if="tags.length > 0"
        @click="removeTag()"
        class="removeSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.removeTag')"
      />
      <input
        @click="addTag()"
        class="addSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.addTag')"
      />
    </p>
    <br />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import DateFormat from "@/utils/dateFormat.js";
import Select from "@/components/Select.vue";
import GenericInput from "@/components/GenericInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import { mapping } from "@/utils/FormStateMapping";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    Select,
    GeopositionWidget,
    GenericInput,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    checkError: Function,
    tags: Array
  },
  data() {
    return {
      refreshIndex: [],
      triggerShowError: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform,
      currentPage: state => state.editTripSubform.currentPage,
      stateErrors: state => state.editTripSubform.errors,
      maxTagCatchLatitude: state =>
        state.editTripSubform[mapping.maxTagCatchLatitude.stateName],
      minTagCatchLatitude: state =>
        state.editTripSubform[mapping.minTagCatchLatitude.stateName],
      maxTagCatchLongitude: state =>
        state.editTripSubform[mapping.maxTagCatchLongitude.stateName],
      minTagCatchLongitude: state =>
        state.editTripSubform[mapping.minTagCatchLongitude.stateName]
    }),
    hasTagPosition() {
      return this.getPropertyValue("hasTagPosition", {
        subforms: this.subform
      });
    },
    hasTagNum2() {
      return this.getPropertyValue("hasTagNum2", {
        subforms: this.subform
      });
    }
  },
  watch: {},
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    addTag() {
      const vm = this;
      vm.triggerShowError++;
      vm.$nextTick(() => {
        vm.tags.push({
          remark: null,
          species: null,
          tag1: null,
          tag2: null,
          depth: null,
          position: {}
        });
      });
      vm.refreshIndex.push(0);
    },
    removeTag() {
      this.tags.pop();
      this.refreshIndex.pop();
    },
    closeTag(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      let hasErrors = this.$hasInputErrorAndScrollSubform("stateErrors", [
        this.currentPage
      ]);

      if (hasErrors) {
        return;
      }
      this.closeObject({
        value: this.tags,
        type: `${this.name}-${index}`,
        index: index,
        vm: this
      });
    }
  },
  mounted() {
    this.refreshIndex = Array(this.tags.length).fill(0);
  }
};
</script>
