class Geolocation {
  // convert decimal long and lat to degrees, minutes, seconds notation system.
  // http://stackoverflow.com/questions/8678371/how-to-convert-gps-degree-to-decimal-and-vice-versa-in-jquery-or-javascript-and
  // https://www.fcc.gov/encyclopedia/degrees-minutes-seconds-tofrom-decimal-degrees
  static convertDD2toDMS(decimalDegree, type) {
    // Validate the arguments
    if (isNaN(decimalDegree) || (type !== "lat" && type !== "lon")) {
      return false;
    }

    let value = Math.abs(Math.round(decimalDegree * 1000000));
    //Math.round is used to eliminate the small error caused by rounding in the computer:
    //e.g. 0.2 is not the same as 0.20000000000284
    //Error checks
    if (type === "lat" && value > 90 * 1000000) {
      return false;
    } else if (type === "lon" && value > 180 * 1000000) {
      return false;
    }

    let degrees = Math.floor(value / 1000000);
    let minutes = Math.floor((value / 1000000 - degrees) * 60);
    let seconds = Number(
      (
        (Math.floor(((value / 1000000 - degrees) * 60 - minutes) * 100000) *
          60) /
        100000
      ).toFixed()
    );

    let sign = decimalDegree < 0 ? -1 : 1;

    degrees *= sign;

    let direction = "";
    if (type == "lat") direction = sign < 0 ? "S" : "N";
    if (type == "lon") direction = sign < 0 ? "W" : "E";

    if (seconds == 60) {
      seconds = 0;
      minutes += 1;
    }

    const minString = _getDoubleZeroText(minutes);
    const secString = _getDoubleZeroText(seconds);

    let dms = {
      degrees: degrees * sign,
      minutes: minutes,
      seconds: seconds,
      direction: direction,
      toString:
        degrees * sign + "°" + minString + "'" + secString + '" ' + direction
    };

    return dms;
  }

  // Convert a lat or long from degrees, minutes, second notation to decimal.
  // Take string and return string
  // http://stackoverflow.com/questions/1140189/converting-latitude-and-longitude-to-decimal-values
  static convertDMStoDD2(value) {
    try {
      value = value.replace(/ /g, "").replace(/_/g, "0");
      var deg = parseInt(value.split("°")[0]);
      var min = parseInt(value.split("°")[1].split("'")[0]);
      var sec = parseInt(
        value
          .split("°")[1]
          .split("'")[1]
          .split('"')[0]
      );
      var dir = value
        .split("°")[1]
        .split("'")[1]
        .split('"')[1];

      return _convertDMStoDD2(deg, min, sec, dir);
    } catch (ReferenceError) {
      return "";
    }
  }

  /**
   * Get grid from geo position specifying which map to lookup
   * @param {*} latitude geoposition
   * @param {*} longitude geoposition
   * @param {*} map 1: generic, 3-4 are region specific
   * @returns String with grid label/name
   */
  static getGridFromPositioWithMap(latitude, longitude, map) {
    if (latitude && longitude && map) {
      if (map == 3) {
        return _getMap3Grid(latitude, longitude);
      }
      return whichGridPosition([latitude, longitude], map);
    }
    return false;
  }

  /**
   * OBSOLETE: works with non subform version
   */
  static getGridFromPosition(latitude, longitude, area = null, specie = null) {
    if (latitude && longitude) {
      var gridToUse = 1;
      if (area && specie) {
        gridToUse = whichGridToUse(area, specie);
      }
      return whichGridPosition([latitude, longitude], gridToUse);
    }
    return false;
  }

  // Nafo from decimal gps position
  // Grid is based 1, 4 if lobster Iles de la madeleine. area et specie sont donc optionnels
  // return array of string, user selectable possibilities
  static getNafoFromPosition(latitude, longitude) {
    if (latitude && longitude) {
      return whichOPANOsubdivision([latitude, longitude]);
    }

    return false;
  }
}

module.exports = Geolocation;
// used for double digit padding if required .. 00, 01, ...
function _getDoubleZeroText(val) {
  let valString = isNaN(val) ? "00" : val.toString();
  if (valString.length === 1) {
    valString = "0" + valString;
  }
  return valString;
}

function _convertDMStoDD2(deg, min, sec, dir) {
  var dd = deg + min / 60 + sec / (60 * 60);
  if (dir == "S" || dir == "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  dd = Math.round(dd * 10000) / 10000;
  return dd;
}

function _getMap3Grid(latitude, longitude) {
  const maxLat = 47.9667;
  const maxLon = -61.1667;
  const minLat = 46.9;
  const minLon = -62.2333;
  const mod = 0.033333;

  if (
    latitude >= minLat &&
    latitude <= maxLat &&
    longitude >= minLon &&
    longitude <= maxLon
  ) {
    const verticalIndex = Math.floor((maxLat - latitude) / 0.033334376);
    const horizontalIndex = Math.floor((longitude - minLon) / 0.03333126);
    return `${alpha3[verticalIndex]}${horizontalIndex + 1}`;
  }
  return false;
}

function whichGridToUse(areaId, speciesId) {
  // TODO: There are constants in main.js - Try to regroup them in one place.
  const AREA_ILES_DE_LA_MADELEINE_ID = 1534;
  const LOBSTERS = 1312;
  if (areaId == AREA_ILES_DE_LA_MADELEINE_ID && speciesId == LOBSTERS) {
    return 4;
  }
  return 1;
}

function whichGridPosition(point, grid) {
  var horizontalPosition = whichHorizontalGRID(point, grid);
  var verticalPosition = whichVerticalGRID(point, grid);
  if (
    (grid == 4 || grid == 3) &&
    horizontalPosition !== false &&
    verticalPosition !== false
  ) {
    return "" + verticalPosition + horizontalPosition;
  }

  if (horizontalPosition === false || verticalPosition === false) {
    horizontalPosition = whichHorizontalGRID(point, 1);
    verticalPosition = whichVerticalGRID(point, 1);
    if (horizontalPosition === false || verticalPosition === false) {
      return false;
    } else {
      grid = 1;
    }
  }
  var zeroPaddedVertical = verticalPosition;
  if (zeroPaddedVertical.length == 1) {
    zeroPaddedVertical = "0" + zeroPaddedVertical;
  }

  return "" + horizontalPosition + zeroPaddedVertical;
}

function whichHorizontalGRID(point, grid) {
  if (grid == 4) {
    for (var i in idlmGridHorizontal) {
      var polygon = idlmGridHorizontal[i];
      var isInside = inside(point, polygon);
      if (isInside) {
        return i;
      }
    }
  } else {
    for (var i in qcGridHorizontal) {
      var polygon = qcGridHorizontal[i];
      var isInside = inside(point, polygon);
      if (isInside) {
        return i;
      }
    }
  }
  return false;
}

function whichVerticalGRID(point, grid) {
  if (grid == 4) {
    for (var i in idlmGridVertical) {
      var polygon = idlmGridVertical[i];
      var isInside = inside(point, polygon);
      if (isInside) {
        return i;
      }
    }
  } else {
    for (var i in qcGridVertical) {
      var polygon = qcGridVertical[i];
      var isInside = inside(point, polygon);
      if (isInside) {
        return i;
      }
    }
  }
  return false;
}

// http://stackoverflow.com/questions/22521982/js-check-if-point-inside-a-polygon
function inside(point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}

function whichAreaOfAreasArray(point, areas) {
  var result = [];
  var erreur = 0.001;
  // square
  var pt1 = [point[0], point[1] + erreur];
  var pt2 = [point[0], point[1] - erreur];
  var pt3 = [point[0] + erreur, point[1]];
  var pt4 = [point[0] - erreur, point[1]];
  // cross
  var pt5 = [point[0] + erreur, point[1] + erreur];
  var pt6 = [point[0] - erreur, point[1] + erreur];
  var pt7 = [point[0] + erreur, point[1] - erreur];
  var pt8 = [point[0] - erreur, point[1] - erreur];
  for (var i in areas) {
    var polygon = areas[i];
    if (
      inside(pt1, polygon) ||
      inside(pt2, polygon) ||
      inside(pt3, polygon) ||
      inside(pt4, polygon) ||
      inside(pt5, polygon) ||
      inside(pt6, polygon) ||
      inside(pt7, polygon) ||
      inside(pt8, polygon)
    ) {
      result.push(i);
    }
  }
  return result;
}

function whichOPANOsubdivision(point) {
  var result = [];
  var subareas = whichAreaOfAreasArray(point, OPANO_HIERARCHY["positions"]);
  for (var i in subareas) {
    var divisions = whichAreaOfAreasArray(
      point,
      OPANO_HIERARCHY[subareas[i]]["positions"]
    );
    for (var j in divisions) {
      var subdivisions = whichAreaOfAreasArray(
        point,
        OPANO_HIERARCHY[subareas[i]][divisions[j]]
      );
      for (var k in subdivisions) {
        result.push(subdivisions[k]);
      }
    }
  }
  return result;
}

///// from opanogrid.js
var qcGridVertical = [];
var qcGridHorizontal = [];

var idlmGridVertical = [];
var idlmGridHorizontal = [];

var opanoSubareas = {};
var opanoSubarea2Divisions = {};
var opanoSubarea3Divisions = {};
var opanoSubarea4Divisions = {};
var opanoSubarea5Divisions = {};

var opano4Rsubdivisions = {};
var opano4Ssubdivisions = {};
var opano4Tsubdivisions = {};
var opano4Vsubdivisions = {};
var opano3Psubdivisions = {};
var opano3Ksubdivisions = {};
var opano2Jsubdivisions = {};

var OPANO_GPS_POINTS = {
  "1": [49.333333333333336, -67.38333333333334],
  "1n": [52.25, -67.38333333333334],
  "2": [49.38333333333333, -65.83333333333333],
  "3": [50.03333333333333, -65.83333333333333],
  "4": [50.25, -65.83333333333333],
  "4n": [52.25, -65.83333333333333],
  "5": [49.416666666666664, -64.66666666666667],
  "6": [49.75, -64.25],
  "6-7": [49.8416666667, -64.125],
  "7": [49.93333333333333, -64],
  "8": [50.03333333333333, -64],
  "9": [50.03333333333333, -62.93333333333333],
  // '10': [49.65, -61.166666666666664],
  "10": [49.66287116520544, -61.166666666666664], // RPPSG
  "11": [50.18333333333333, -61.166666666666664],
  "11n": [52.25, -61.166666666666664],
  "12": [49.416666666666664, -60],
  "13": [50.733333333333334, -59],
  "13n": [52.25, -59],
  // '14': [50.71666666666667, -58.15],
  "14": [50.71609143786155, -58.124636113643646], // RPPSG
  "15": [51.4, -57.11666666666667],
  "15n": [52.25, -57.11666666666667],
  "16": [50.7, -57.416666666666664],
  "16e": [50.737513, -56.659189],
  // '17': [49.95, -59.25],
  "17": [49.94588175336223, -59.24234077334404], // RPPSG
  "18": [49.4, -58.233333333333334],
  "19": [48.46666666666667, -60],
  "20": [48.46666666666667, -59.266666666666666],
  "20a": [48.530694, -59.138708],
  "20b": [48.557402, -58.726287],
  "21": [49, -60],
  "22": [49.083333333333336, -61.7],
  // '23': [48.7, -62.53333333333333],
  "23": [48.69902224890062, -62.53333333333333],
  // '24': [49.11666666666667, -63.75],
  "24": [49.109573705988424, -63.75],
  "25": [47.833333333333336, -60],
  "26": [47.61666666666667, -59.31666666666667],
  "27": [47.583333333333336, -57.63333333333333],
  // '28': [46.833333333333336, -58.833333333333336],
  "28": [46.84888495420007, -58.80862221121788],
  // '29': [45.666666666666664, -57.45],
  "29": [45.666666666666664, -57.406408935785294], // RPPSG
  "30": [45.666666666666664, -60],
  "31": [45.7, -60.25],
  "31n": [45.753143, -60.294841],
  "32": [47.0375, -60.415277777777774],
  "32s": [47.01, -60.415277777777774],
  // '33': [47.5, -59.583333333333336],
  "33": [47.4822988469138, -59.57262355132667], // RPPSG
  "34": [47.05, -60.6],
  "34s": [46.9, -60.6],
  "35": [46.833333333333336, -62.53333333333333],
  "36": [45.96666666666667, -62.46666666666667],
  "36w": [46.02263, -62.728771],
  "37": [45.78333333333333, -62.13333333333333],
  "38": [46.43333333333333, -63.25],
  "39": [46.583333333333336, -64.1],
  "39-41": [46.6416666667, -64.0083333333],
  "40": [46.46666666666667, -64.61666666666666],
  "41": [46.7, -63.916666666666664],
  "42": [47, -63.75],
  "43": [47, -62.53333333333333],
  "44": [47.61666666666667, -63.75],
  "45": [47.61666666666667, -64.83333333333333],
  "45-46": [47.7083333333, -64.875],
  // which 46-47 is true, no one knows
  // '46': [47.8, -64.91666666666667],
  // '47': [48.18333333333333, -64.91666666666667],
  // '47n': [49, -64.91666666666667],
  "46": [47.8, -64.833333333],
  "47": [48.183333333333333333333, -64.833333333],
  "47n": [49, -64.833333333],
  // ____________________________________//
  "48rppsg": [48.752282, -64.159791], // '48': [48.75, 64-.2]
  "48-27": [48.752282, -57.63333333333333],
  "49": [49, -67],
  "49s": [48.547518, -66.71172],
  "50": [49.35, -67],
  "51": [48.63333333333333, -68.16666666666667],
  "52": [48.88333333333333, -68.66666666666667],
  "53": [51.63333333333333, -55.43333333333333],
  "53a": [51.424469, -55.612426],
  "53b": [51.424469, -55.612426],
  "53c": [51.424469, -55.612426],
  "54": [52.25, -55.416666666666664],
  "55": [52.25, -55.68333333333333],
  amhersthead: [45.886565, -63.997559],
  birchridge: [46.315687, -65.115817],
  brackley: [46.317163, -63.134338],
  caledoniamills: [45.499879, -61.816581],
  deerlake: [49.172702, -57.434174],
  forillon: [48.89819, -64.353768],
  labrieville: [49.298895, -69.559707],
  christmasmountain: [47.166663, -66.666724],
  mtthom: [45.51247, -63.01045],
  mtstewart: [46.366507, -62.867473],
  nicolet: [46.225962, -72.618108],
  porthasting: [45.647689, -61.411342],
  saintanthonyairport: [51.390972, -56.090548],
  tracydepot: [47.800143, -67.534742],
  wellington: [46.453105, -64.024231],

  "4X-NB": [45.5, -66.903126],
  "4X-US-a": [43.833333, -66.903126],
  "4X-US-b": [43.833333, -67.407567],
  "4X-US-c": [42.887222, -67.743056],
  "4X-US-d": [42.518889, -67.468056],
  "4X-US-e": [42.333333, -67.303653],
  "4X-US-f": [42.333333, -66],
  "4X-US-g": [42, -65.666667],
  "4X-US-h": [39.0, -65.666667],
  "4X-4W-b": [39, -63.333333],
  "4X-4W-a": [44.333333, -63.333333],
  haverstockisland: [44.795375, -63.852414],

  "5Y-NW": [43.833333, -70],
  "5Y-SW": [42.518889, -70],

  "4W-4Vs-a": [44.166667, -60],
  "4W-4Vs-b": [44.166667, -59],
  "4W-4Vs-c": [39, -59],

  capefreels: [49.25, -53.583333],
  "nfl-center": [48.549464, -56.198079],
  fitzgeraldpond: [47.336545, -53.728718],
  goosepond: [48.11925, -54.095455],
  capestmary: [46.827392, -54.188063],

  "3K-3M": [49.25, -42],
  "3K-3L-3M": [49.25, -46.5],
  "3L-3M-3N": [46, -46.5],
  "3M-3N": [39, -46.5],
  "3M-SE": [39, -42],
  "3N-3O-4Vs": [39.94134013062942, -51],
  "3N-4Vs": [39, -50],
  "3L-3N-3O": [46, -51],
  "3L-3O-3Ps": [46, -54.5],
  "3O-3Ps-4Vs": [43.135026533898944, -54.5],

  "2G-NW": [61, -64.5],
  "2G-NE": [61, -59],
  "2J-3K": [52.25, -42],
  "2-SW": [52.25, -64.5],

  "2G-2H-E": [57.66666667, -52.04666666686535],
  "2G-2H-W": [57.66666667, -64.5],
  "2H-2J-E": [55.3333333, -47.55130313336849],
  "2H-2J-W": [55.3333333, -64.5]
};
var OPANO_SUBAREAS = {
  "2": ["2G-NW", "2G-NE", "2J-3K", "2-SW"],
  "3": [
    54,
    "2J-3K",
    "3M-SE",
    "3N-4Vs",
    33,
    26,
    "48-27",
    "deerlake",
    "16e",
    "saintanthonyairport",
    "53c",
    "53b",
    "53a",
    53
  ],
  "4": [
    "1n",
    54,
    53,
    "53a",
    "53b",
    "53c",
    "saintanthonyairport",
    "16e",
    "deerlake",
    "48-27",
    26,
    33,
    "3N-4Vs",
    "4X-US-h",
    "4X-US-g",
    "4X-US-f",
    "4X-US-e",
    "4X-US-d",
    "4X-US-c",
    "4X-US-b",
    "4X-US-a",
    "4X-NB",
    "birchridge",
    "christmasmountain",
    "tracydepot",
    "nicolet",
    "labrieville"
  ],
  "5": ["4X-NB", "4X-US-a", "4X-US-b", "4X-US-c", "4X-US-d", "5Y-NW", "5Y-SW"]
};
var OPANO_SUBAREA_2_DIVISIONS = {
  "2G": ["2G-NW", "2G-NE", "2G-2H-E", "2G-2H-W"],
  "2H": ["2G-2H-E", "2G-2H-W", "2H-2J-W", "2H-2J-E"],
  "2J": ["2H-2J-W", "2H-2J-E", "2J-3K", "2-SW"]
};
var OPANO_SUBAREA_3_DIVISIONS = {
  "3K": [
    54,
    53,
    "53a",
    "53b",
    "53c",
    "saintanthonyairport",
    "16e",
    "deerlake",
    "48-27",
    "nfl-center",
    "capefreels",
    "3K-3M",
    "2J-3K"
  ],
  "3L": [
    "capefreels",
    "3K-3L-3M",
    "3L-3M-3N",
    "3L-3O-3Ps",
    "capestmary",
    "fitzgeraldpond",
    "goosepond",
    "nfl-center"
  ],
  "3M": ["3K-3L-3M", "3K-3M", "3M-SE", "3M-3N"],
  "3N": ["3L-3N-3O", "3L-3M-3N", "3M-3N", "3N-4Vs", "3N-3O-4Vs"],
  "3O": ["3L-3N-3O", "3N-3O-4Vs", "3O-3Ps-4Vs", "3L-3O-3Ps"],
  "3P": [
    26,
    33,
    "3O-3Ps-4Vs",
    "3L-3O-3Ps",
    "capestmary",
    "fitzgeraldpond",
    "goosepond",
    "nfl-center",
    "48-27"
  ]
};

var OPANO_SUBAREA_5_DIVISIONS = {
  "5Y": ["4X-NB", "4X-US-a", "4X-US-b", "4X-US-c", "4X-US-d", "5Y-NW", "5Y-SW"]
};

var OPANO_SUBAREA_4_DIVISIONS = {
  "4R": [
    26,
    "33",
    25,
    12,
    15,
    "15n",
    54,
    53,
    "53a",
    "53b",
    "53c",
    "saintanthonyairport",
    "16e",
    "deerlake",
    "48-27"
  ],
  "4S": [1, 50, 5, 25, 12, 15, "15n", "1n"],
  "4T": [
    1,
    50,
    5,
    25,
    32,
    "32s",
    "34s",
    "porthasting",
    "caledoniamills",
    "mtthom",
    "amhersthead",
    "birchridge",
    "christmasmountain",
    "tracydepot",
    "nicolet",
    "labrieville",
    "1n"
  ],
  "4X": [
    "4X-NB",
    "4X-US-a",
    "4X-US-b",
    "4X-US-c",
    "4X-US-d",
    "4X-US-e",
    "4X-US-f",
    "4X-US-g",
    "4X-US-h",
    "4X-4W-b",
    "4X-4W-a",
    "haverstockisland",
    "mtthom",
    "amhersthead",
    "birchridge"
  ],
  "4W": [
    "4W-4Vs-a",
    "4W-4Vs-b",
    "4W-4Vs-c",
    "4X-4W-b",
    "4X-4W-a",
    "haverstockisland",
    "mtthom",
    "caledoniamills",
    "porthasting",
    "31n",
    31,
    30
  ],
  "4V": [
    31,
    30,
    "4W-4Vs-a",
    "4W-4Vs-b",
    "4W-4Vs-c",
    "3N-4Vs",
    25,
    32,
    "32s",
    "34s",
    "porthasting",
    "31n"
  ]
};
var OPANO_4R_SUBDIVISIONS = {
  "4Ra": [
    55,
    54,
    53,
    "53a",
    "53b",
    "53c",
    "saintanthonyairport",
    "16e",
    16,
    14,
    15,
    "15n"
  ],
  "4Rb": [18, 17, 14, 16, "16e", "deerlake"],
  "4Rc": [18, 17, 12, 19, 20, "20a", "20b", "48-27", "deerlake"],
  "4Rd": [26, "33", 25, 19, 20, "20a", "20b", "48-27"]
};
var OPANO_4S_SUBDIVISIONS = {
  "4Si": [7, 8, 3, 2, 5, 6, "6-7"],
  "4Ss": [22, 21, 25, 5, 6, "6-7"],
  "4Sv": [10, 12, 14, 13, "13n", "11n"],
  "4Sw": [14, 15, "15n", "13n", 13],
  "4Sx": [22, 21, 12, 9, 8, 7, "6-7"],
  "4Sy": [10, 9, 8, 3, "4n", "11n"],
  "4Sz": [1, 50, 2, "4n", "1n"]
};
var OPANO_4T_SUBDIVISIONS = {
  "4Tf": [23, 35, 34, "34s", "32s", 32, 25],
  "4Tg": [
    38,
    35,
    34,
    "34s",
    "porthasting",
    "caledoniamills",
    37,
    36,
    "36w",
    "mtstewart",
    "brackley"
  ],
  "4Th": [
    40,
    39,
    "39-41",
    "wellington",
    "brackley",
    "mtstewart",
    "36w",
    36,
    37,
    "caledoniamills",
    "mtthom",
    "amhersthead",
    "birchridge"
  ],
  "4Tj": [38, 35, 43, 42, 41, "39-41", "wellington", "brackley"],
  "4Tk": [42, 43, 23, 24],
  "4Tl": [
    41,
    42,
    44,
    45,
    "45-46",
    "christmasmountain",
    "birchridge",
    40,
    39,
    "39-41"
  ],
  "4Tm": [46, 47, "47n", "49s", "tracydepot", "christmasmountain", "45-46"],
  "4Tn": [46, 47, "47n", "forillon", "48rppsg", 24, 44, 45, "45-46"],
  "4To": [24, 5, 50, 49, "49s", "47n", "forillon", "48rppsg"],
  "4Tp": [51, 52, "labrieville", "nicolet", "tracydepot"],
  "4Tq": [51, 52, "labrieville", "1n", 1, 50, 49, "49s", "tracydepot"]
};
var OPANO_4V_SUBDIVISIONS = {
  "4Vs": [29, 30, "4W-4Vs-a", "4W-4Vs-b", "4W-4Vs-c", "3N-4Vs"],
  "4Vn": [31, 30, 29, 25, 32, "32s", "34s", "porthasting", "31n"]
};
var OPANO_4W_SUBDIVISIONS = {};
var OPANO_4X_SUBDIVISIONS = {};
var OPANO_3P_SUBDIVISIONS = {
  "3Ps": [
    27,
    28,
    "3O-3Ps-4Vs",
    "3L-3O-3Ps",
    "capestmary",
    "fitzgeraldpond",
    "goosepond",
    "nfl-center"
  ],
  "3Pn": [26, 33, 28, 27, "nfl-center", "48-27"]
};
var OPANO_3K_SUBDIVISIONS = {
  // FAKE, en realite, jai mis les positions de la 3K, le pecheur doit selectioner lui même.
  "3K": OPANO_SUBAREA_3_DIVISIONS["3K"]
  // '3Ka': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kb': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kc': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kd': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Ke': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kf': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kg': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Kh': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // '3Ki': OPANO_SUBAREA_3_DIVISIONS['3K'],
  // // '3Kj': OPANO_SUBAREA_3_DIVISIONS['3K'],  // pas la ?
  // '3Kk': OPANO_SUBAREA_3_DIVISIONS['3K'],
};
var OPANO_2J_SUBDIVISIONS = {
  // FAKE, en realite, jai mis les positions de la 3K, le pecheur doit selectioner lui même.
  "2J": OPANO_SUBAREA_2_DIVISIONS["2J"]
  // '2Ja': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jb': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jc': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jd': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Je': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jf': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jg': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Ji': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jj': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jl': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jm': OPANO_SUBAREA_2_DIVISIONS['2J'],
  // '2Jn': OPANO_SUBAREA_2_DIVISIONS['2J'],
};

var i, j;

for (i in OPANO_SUBAREAS) {
  opanoSubareas[i] = [];
  for (j in OPANO_SUBAREAS[i]) {
    opanoSubareas[i][j] = OPANO_GPS_POINTS[OPANO_SUBAREAS[i][j]];
  }
}

for (i in OPANO_SUBAREA_2_DIVISIONS) {
  opanoSubarea2Divisions[i] = [];
  for (j in OPANO_SUBAREA_2_DIVISIONS[i]) {
    opanoSubarea2Divisions[i][j] =
      OPANO_GPS_POINTS[OPANO_SUBAREA_2_DIVISIONS[i][j]];
  }
}

for (i in OPANO_SUBAREA_3_DIVISIONS) {
  opanoSubarea3Divisions[i] = [];
  for (j in OPANO_SUBAREA_3_DIVISIONS[i]) {
    opanoSubarea3Divisions[i][j] =
      OPANO_GPS_POINTS[OPANO_SUBAREA_3_DIVISIONS[i][j]];
  }
}

for (i in OPANO_SUBAREA_4_DIVISIONS) {
  opanoSubarea4Divisions[i] = [];
  for (j in OPANO_SUBAREA_4_DIVISIONS[i]) {
    opanoSubarea4Divisions[i][j] =
      OPANO_GPS_POINTS[OPANO_SUBAREA_4_DIVISIONS[i][j]];
  }
}

for (i in OPANO_SUBAREA_5_DIVISIONS) {
  opanoSubarea5Divisions[i] = [];
  for (j in OPANO_SUBAREA_5_DIVISIONS[i]) {
    opanoSubarea5Divisions[i][j] =
      OPANO_GPS_POINTS[OPANO_SUBAREA_5_DIVISIONS[i][j]];
  }
}

for (i in OPANO_4R_SUBDIVISIONS) {
  opano4Rsubdivisions[i] = [];
  for (j in OPANO_4R_SUBDIVISIONS[i]) {
    opano4Rsubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_4R_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_4S_SUBDIVISIONS) {
  opano4Ssubdivisions[i] = [];
  for (j in OPANO_4S_SUBDIVISIONS[i]) {
    opano4Ssubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_4S_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_4T_SUBDIVISIONS) {
  opano4Tsubdivisions[i] = [];
  for (j in OPANO_4T_SUBDIVISIONS[i]) {
    opano4Tsubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_4T_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_4V_SUBDIVISIONS) {
  opano4Vsubdivisions[i] = [];
  for (j in OPANO_4V_SUBDIVISIONS[i]) {
    opano4Vsubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_4V_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_3P_SUBDIVISIONS) {
  opano3Psubdivisions[i] = [];
  for (j in OPANO_3P_SUBDIVISIONS[i]) {
    opano3Psubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_3P_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_3K_SUBDIVISIONS) {
  opano3Ksubdivisions[i] = [];
  for (j in OPANO_3K_SUBDIVISIONS[i]) {
    opano3Ksubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_3K_SUBDIVISIONS[i][j]];
  }
}

for (i in OPANO_2J_SUBDIVISIONS) {
  opano2Jsubdivisions[i] = [];
  for (j in OPANO_2J_SUBDIVISIONS[i]) {
    opano2Jsubdivisions[i][j] = OPANO_GPS_POINTS[OPANO_2J_SUBDIVISIONS[i][j]];
  }
}

var OPANO_HIERARCHY = {
  positions: opanoSubareas,
  "2": {
    positions: opanoSubarea2Divisions,
    "2G": opanoSubarea2Divisions,
    "2H": opanoSubarea2Divisions,
    "2J": opano2Jsubdivisions
  },
  "3": {
    positions: opanoSubarea3Divisions,
    // '3K': opanoSubarea3Divisions,
    "3K": opano3Ksubdivisions,
    "3L": opanoSubarea3Divisions,
    "3M": opanoSubarea3Divisions,
    "3N": opanoSubarea3Divisions,
    "3O": opanoSubarea3Divisions,
    "3P": opano3Psubdivisions
  },
  "4": {
    positions: opanoSubarea4Divisions,
    "4R": opano4Rsubdivisions,
    "4S": opano4Ssubdivisions,
    "4T": opano4Tsubdivisions,
    "4X": opanoSubarea4Divisions,
    "4W": opanoSubarea4Divisions,
    "4V": opano4Vsubdivisions
  },
  "5": {
    positions: opanoSubarea5Divisions,
    "5Y": opanoSubarea5Divisions
  }
};

var northWestCorner;
var northEastCorner;
var southWestCorner;
var southEastCorner;
var alpha1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
var alpha3 = [
  "LA",
  "LB",
  "LC",
  "LD",
  "LE",
  "LF",
  "LG",
  "LH",
  "LI",
  "LJ",
  "LK",
  "LL",
  "LM",
  "LN",
  "LO",
  "LP",
  "LQ",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LW",
  "LX",
  "LY",
  "LZ",
  "MA",
  "MB",
  "MC",
  "MD",
  "ME",
  "MF"
];
var alpha4 = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "BB",
  "CC",
  "DD",
  "EE",
  "FF",
  "GG"
];
var horizontalPosition = "";
var verticalPosition = "";

var maxLat = 78;
var maxLon = -70;
var minLat = 39;
var minLon = -42;
var mod = 0.1666666666666666667;
var lat = maxLat;
var lon = maxLon;

lat = 78;
lon = -70;
i = 0;
while (lon <= minLon) {
  i++;
  northWestCorner = [maxLat, lon - 0.0001];
  southWestCorner = [minLat, lon - 0.0001];
  lon += mod;
  northEastCorner = [maxLat, lon - 0.0001];
  southEastCorner = [minLat, lon - 0.0001];
  qcGridVertical[i] = [
    northWestCorner,
    northEastCorner,
    southEastCorner,
    southWestCorner
  ];
}

i = 0;
while (lat >= minLat) {
  i++;
  northWestCorner = [lat, maxLon];
  northEastCorner = [lat, minLon];
  lat -= mod;
  southWestCorner = [lat, maxLon];
  southEastCorner = [lat, minLon];
  horizontalPosition =
    alpha1[Math.floor((i - 1) / alpha1.length)] +
    alpha1[(i - 1) % alpha1.length];
  qcGridHorizontal[horizontalPosition] = [
    northWestCorner,
    northEastCorner,
    southEastCorner,
    southWestCorner
  ];
}

// 'Magdeleine island lobster grid for map #4
maxLat = 47.96666666666667;
maxLon = -62.233334;
minLat = 47;
minLon = -61.13333333333333;
mod = 0.03333333333333286;
lat = maxLat;
lon = maxLon;
i = 0;
while (lon < minLon) {
  i++;
  northWestCorner = [parseFloat(maxLat.toFixed(8)), parseFloat(lon.toFixed(8))];
  southWestCorner = [parseFloat(minLat.toFixed(8)), parseFloat(lon.toFixed(8))];
  lon += mod;
  northEastCorner = [parseFloat(maxLat.toFixed(8)), parseFloat(lon.toFixed(8))];
  southEastCorner = [parseFloat(minLat.toFixed(8)), parseFloat(lon.toFixed(8))];
  if (alpha4[i - 1]) {
    verticalPosition = alpha4[i - 1];
    idlmGridVertical[verticalPosition] = [
      northWestCorner,
      northEastCorner,
      southEastCorner,
      southWestCorner
    ];
  }
}

i = 0;
while (parseFloat(lat.toFixed(8)) > parseFloat(minLat.toFixed(8))) {
  i++;
  northWestCorner = [parseFloat(lat.toFixed(8)), parseFloat(maxLon.toFixed(8))];
  northEastCorner = [parseFloat(lat.toFixed(8)), parseFloat(minLon.toFixed(8))];
  lat -= 0.03333333333333286;
  southWestCorner = [parseFloat(lat.toFixed(8)), parseFloat(maxLon.toFixed(8))];
  southEastCorner = [parseFloat(lat.toFixed(8)), parseFloat(minLon.toFixed(8))];
  idlmGridHorizontal[i] = [
    northWestCorner,
    northEastCorner,
    southEastCorner,
    southWestCorner
  ];
}
