<template>
  <div class="page-main">
    <h1>{{ $t("connexion.lock") }}</h1>

    <p class="username">{{ username }}</p>
    <p class="userFIN">{{ $t("fin.short") }} : {{ fin }}</p>

    <form id="unlockJobelForm" @submit="validate" class="password_lock">
      <fieldset>
        <input hidden type="text" name="password" autoComplete="password" />
        <PasswordInput
          v-model.trim="password"
          :label="$t('fields.password')"
          :displayPassword="true"
          :resetPassword="true"
          :required="true"
          :refeshError="refeshError"
          :forceErrorMsg="passwordError"
          autocomplete="current-password"
          @error="checkError"
        />
      </fieldset>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.unlock") }}
      </button>
    </form>

    <div class="eraseBrowser">
      <p>
        {{ $t("connexion.delete") }},
        <a @click.stop="deleteApp">{{ $t("click_here") }}.</a>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import db from "@/service/db";

export default {
  name: "lock",
  computed: {},
  components: {
    PasswordInput
  },
  data() {
    return {
      password: null,
      refeshError: false,
      username: null,
      fin: null,
      passwordError: null
    };
  },
  beforeMount() {
    this.$store.dispatch("lock");
  },
  mounted() {
    const vuejs = this;
    const profile = this.$store.getters.USER_PROFILE;
    if (profile) {
      vuejs.username = profile.userfullname;
      vuejs.fin = profile.fisherIdentificationNumber;
    } else {
      db.getItem("userProfile").then(function(data) {
        vuejs.username = data.userfullname;
        vuejs.fin = data.fisherIdentificationNumber;
      });
    }
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    validate: function(e) {
      e.preventDefault();
      const vuejs = this;
      vuejs.$store
        .dispatch("unlock", vuejs.password)
        .then(function(success) {
          db.getLastVisitedUrl().then(function(url) {
            Promise.resolve(
              vuejs.$router.replace(url).catch(error => {
                // ignore because of exception on navigation guard...
              })
            );
          });
        })
        .catch(error => {
          vuejs.passwordError = vuejs.$i18n.t("password.bad");
        });
    },
    deleteApp: function(e) {
      const vuejs = this;
      vuejs.$store.dispatch("delete").then(function() {
        Promise.resolve(
          vuejs.$router.replace("/").catch(error => {
            // ignore because of exception on navigation guard...
          })
        );
      });
      // @TODO check if an open trip and alert
    }
  }
};
</script>

<style lang="scss" scoped>
.eraseBrowser {
  margin-top: 4em;
  text-align: center;
  font-size: 0.88em;
  line-height: 1.5;
  color: $gris-light;
  a {
    color: $lightColor !important;
  }
}
.username,
.userFIN {
  margin: 10px;
  display: block;
  font-weight: bold;
  text-align: center;
  font-size: 1.3em;
}
.password_lock {
  padding: 12px !important;
}
</style>
