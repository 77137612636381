<template>
  <div class="page-main">
    <h1>{{ $t("connexion.login") }}</h1>

    <p class="topContentError" v-if="badBrowser">
      {{ $t("connexion.badBrowser") }}
    </p>

    <p class="topContentError" v-if="needCookies">
      {{ $t("connexion.needCookies") }}
    </p>

    <p class="noAccount">
      {{ $t("connexion.noaccount") }}
      <router-link :to="{ name: 'connexionsignup' }">
        {{ $t("connexion.signup") }}
      </router-link>
    </p>

    <form
      id="connection"
      class="connection"
      method="POST"
      @submit="validateLogin"
    >
      <fieldset>
        <GenericInput
          v-model.trim="inputs.username"
          :label="$t('fin.label')"
          :help="$t('fin.desc')"
          :refeshError="refeshError"
          :required="true"
          :finValidator="true"
          type="text"
          autocomplete="username"
          @error="checkError"
        />
        <br />
        <PasswordInput
          v-model.trim="inputs.password"
          :label="$t('fields.password')"
          :refeshError="refeshError"
          :resetPassword="true"
          :required="true"
          :displayPassword="true"
          autocomplete="current-password"
          @error="checkError"
        />
      </fieldset>
      <p class="loginError error" v-if="badPassword">
        {{ $t("connexion.bad") }}
      </p>
      <p class="serverReachError error" v-if="badInternet">
        {{ this.$store.getters.GET_CURRENT_ONLINE_ERROR }}
      </p>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.login") }}
      </button>
    </form>
  </div>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import DB from "@/service/db";

export default {
  name: "connexion-login",
  data() {
    return {
      inputs: {
        username: "",
        password: ""
      },
      refeshError: false,
      badBrowser: false,
      needCookies: false,
      badPassword: false,
      badInternet: false
    };
  },
  components: {
    GenericInput,
    PasswordInput
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    validateLogin: function(e) {
      e.preventDefault();
      const vuejs = this;
      const username = vuejs.inputs.username;
      const password = vuejs.inputs.password;
      if (username && password) {
        this.badPassword = false;
        this.badInternet = false;

        vuejs.$store
          .dispatch("logIn", {
            username,
            password
          })
          .then(function(b) {
            return Promise.resolve(
              vuejs.$router.push({ name: "modules" }).catch(error => {
                // ignore exception on navigation guard,
                // so we dont get treated by next catch
              })
            );
          })
          .catch(error => {
            if (error.errorType == "user") {
              vuejs.badPassword = true;
            } else {
              vuejs.$alertUserApiError(error);
            }
          });
      }
    }
  },
  mounted() {
    const vm = this;
    if (!navigator?.cookieEnabled) {
      document.cookie = "cookietest=1";
      vm.needCookies = document.cookie.indexOf("cookietest=") != -1;
      document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    }

    DB.getItem("isClientAuthenticatable").then(isClientAuthenticatable => {
      // isClientAuthenticatable can be null (not tested yet), false (failed), true (succeeded)
      if (isClientAuthenticatable === false) {
        vm.badBrowser = true; // only if failed
      } else {
        vm.badBrowser = false;
      }
    });
  }
};
</script>
