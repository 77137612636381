var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',[(
        _vm.hasEffortDetailStartDatetime ||
          (!_vm.hasPositionInEffort && _vm.hasEffortDetailStartPosition) ||
          _vm.hasFarGearPosition
      )?_c('h4',[_vm._v(" "+_vm._s(_vm.isTowTextMode ? _vm.$t("editTrip.effortStep.startTow") : _vm.$t("editTrip.effortStep.startDeployment"))+" ")]):_vm._e(),(_vm.hasEffortDetailStartDatetime)?_c('GenericInput',{key:'tow-startEffort-' + _vm.openTrip.departure['datetime'] + _vm.effort['start'],attrs:{"label":_vm.$t('editTrip.summary.datetime'),"help":_vm.dateHelpText ? _vm.$t('editTrip.effortStep.helpDate') : null,"type":"datetime","required":_vm.hasEffortDetailStartDatetime === _vm.$const.MANDATORY,"autodatetime":true,"minDates":[
        {
          value: _vm.openTrip.departure['datetime'],
          text: _vm.$t('editTrip.beforeDepartureDate')
        },
        {
          value: _vm.effort['start'],
          text: _vm.$t('editTrip.effortStep.effortStart')
        }
      ],"maxDates":[
        {
          value: _vm.effort['end'],
          text: _vm.$t('editTrip.effortStep.effortEnd')
        }
      ],"refeshError":_vm.refreshError},on:{"error":_vm.addError},model:{value:(_vm.tow['startDate']),callback:function ($$v) {_vm.$set(_vm.tow, 'startDate', $$v)},expression:"tow['startDate']"}}):_vm._e(),(!_vm.hasPositionInEffort && _vm.hasEffortDetailStartPosition)?_c('GeopositionWidget',{attrs:{"position":_vm.tow['startPosition'],"hasNafo":_vm.hasGeopositionNafo,"hasGrid":_vm.hasGeopositionGrid,"hasLobsterGrid":_vm.hasLobsterGrid,"required":_vm.hasEffortDetailStartPosition === _vm.$const.MANDATORY,"minLat":[_vm.areaData.latMin ?? _vm.geoLimits.minLat],"maxLat":[_vm.areaData.latMax ?? _vm.geoLimits.maxLat],"minLong":[_vm.areaData.lonMin ?? _vm.geoLimits.minLon],"maxLong":[_vm.areaData.lonMax ?? _vm.geoLimits.maxLon],"gridValidationArray":_vm.gridValidationArray,"nafoGridValidationArray":_vm.nafoGridValidationArray,"refeshError":_vm.refreshError,"lgrid":_vm.effort.lgrid},on:{"binding":value => {
          _vm.tow['startPosition'] = value;
          this.update();
        },"error":_vm.addError}}):_vm._e(),(_vm.hasEffortDetailFma)?_c('Select',{attrs:{"label":_vm.$t('fields.area'),"options":_vm.fishingAreaOptions,"required":_vm.hasEffortDetailFma === _vm.$const.MANDATORY,"refeshError":_vm.refreshError},on:{"error":_vm.addError},model:{value:(_vm.tow['startArea']),callback:function ($$v) {_vm.$set(_vm.tow, 'startArea', $$v)},expression:"tow['startArea']"}}):_vm._e()],1),_c('fieldset',[(_vm.hasEndDateTimeOrPosition)?_c('h4',[_vm._v(" "+_vm._s(_vm.isTowTextMode ? _vm.$t("editTrip.effortStep.endTow") : _vm.$t("editTrip.effortStep.endDeployment"))+" ")]):_vm._e(),(_vm.hasEndDatetime)?_c('GenericInput',{key:'towEndDate-' +
          _vm.tow['startDate'] +
          _vm.openTrip.departure['datetime'] +
          _vm.effort['start'],attrs:{"label":_vm.$t('editTrip.summary.datetime'),"help":_vm.dateHelpText ? _vm.$t('editTrip.effortStep.helpDate') : null,"type":"datetime","required":_vm.hasEndDatetime === _vm.$const.MANDATORY,"autodatetime":true,"minDates":[
        {
          value: _vm.openTrip.departure['datetime'],
          text: _vm.$t('editTrip.beforeDepartureDate')
        },
        {
          value: _vm.effort['start'],
          text: _vm.$t('editTrip.effortStep.effortStart')
        },
        {
          value: _vm.tow['startDate'],
          text: _vm.$t('editTrip.effortStep.towDate')
        }
      ],"maxDates":[
        {
          value: _vm.effort['end'],
          text: _vm.$t('editTrip.effortStep.effortEnd')
        }
      ],"refeshError":_vm.refreshError},on:{"error":_vm.addError},model:{value:(_vm.tow['endDate']),callback:function ($$v) {_vm.$set(_vm.tow, 'endDate', $$v)},expression:"tow['endDate']"}}):_vm._e(),(_vm.hasEndPosition)?_c('GeopositionWidget',{attrs:{"position":_vm.tow['endPosition'],"hasNafo":_vm.hasGeopositionNafo,"hasGrid":_vm.hasGeopositionGrid,"hasLobsterGrid":_vm.hasLobsterGrid,"required":_vm.hasEndPosition === _vm.$const.MANDATORY,"minLat":[_vm.gpsMinMax.minLat],"maxLat":[_vm.gpsMinMax.maxLat],"minLong":[_vm.gpsMinMax.minLong],"maxLong":[_vm.gpsMinMax.maxLong],"gridValidationArray":_vm.gridValidationArray,"nafoGridValidationArray":_vm.nafoGridValidationArray,"refeshError":_vm.refreshError,"lgrid":_vm.effort.lgrid},on:{"binding":value => {
          _vm.tow['endPosition'] = value;
          this.update();
        },"error":_vm.addError}}):_vm._e(),(_vm.hasFarGearPosition)?_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.farGear"))+" ")]),_c('GeopositionWidget',{attrs:{"position":_vm.tow.farGearPosition,"area":_vm.effort.area,"required":true,"minLat":[_vm.gpsMinMax.minLat],"maxLat":[_vm.gpsMinMax.maxLat],"minLong":[_vm.gpsMinMax.minLong],"maxLong":[_vm.gpsMinMax.maxLong],"refeshError":_vm.refreshError},on:{"binding":value => {
            _vm.tow.farGearPosition = value;
            this.update();
          },"error":_vm.addError}})],1):_vm._e(),(_vm.hasEffortDetailFma)?_c('Select',{attrs:{"label":_vm.$t('fields.area'),"options":_vm.fishingAreaOptions,"required":_vm.hasEffortDetailFma === _vm.$const.MANDATORY,"refeshError":_vm.refreshError},on:{"error":_vm.addError},model:{value:(_vm.tow['endArea']),callback:function ($$v) {_vm.$set(_vm.tow, 'endArea', $$v)},expression:"tow['endArea']"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }