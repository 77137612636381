import * as crypt from "crypto-js";
import localforage from "localforage";

function _encrypt(data, key) {
  var encJson = crypt.AES.encrypt(JSON.stringify(data), key).toString();
  var encData = crypt.enc.Base64.stringify(crypt.enc.Utf8.parse(encJson));
  return encData;
}

function _decrypt(data, key) {
  var decData = crypt.enc.Base64.parse(data).toString(crypt.enc.Utf8);
  var decryptedData = crypt.AES.decrypt(decData, key);
  var bytes = decryptedData.toString(crypt.enc.Utf8);
  return JSON.parse(bytes);
}

function _setSpecialK(password, offlineToken, csrfToken) {
  return new Promise(function(resolve, reject) {
    var hash = crypt.SHA256(password + csrfToken).toString(crypt.enc.Base64);
    var encOffTok = _encrypt(offlineToken, hash);
    var encSalt = _encrypt(csrfToken, hash);

    const specialK = {
      hash: hash,
      salt: csrfToken,
      encOffTok: encOffTok,
      encSalt: encSalt
    };

    resolve(specialK);
  });
}

function _loadOfflineToken() {
  return localforage.getItem("specialK").then(specialK => {
    return new Promise(function(resolve, reject) {
      if (!specialK) {
        reject(new Error("not special enough ;-)"));
      }

      const offlineToken = _decrypt(specialK.encOffTok, specialK.hash);
      if (process.env.VUE_APP_DEBUG_ONLINE) {
        console.debug("offlineToken =", offlineToken); // eslint-disable-line no-console
        console.debug("specialK.salt =", specialK.salt); // eslint-disable-line no-console
      }
      resolve({
        offlineToken: offlineToken,
        csrfToken: specialK.salt
      });
    });
  });
}

export default {
  getItem(key) {
    return localforage.getItem(key);
  },
  setItem(key, value) {
    return localforage.setItem(key, value);
  },
  setSpecialK(password, offlineToken, csrfToken) {
    const db = this;
    return _setSpecialK(password, offlineToken, csrfToken).then(function(
      specialK
    ) {
      return db.setItem("specialK", specialK);
    });
  },
  getTokens() {
    return _loadOfflineToken();
  },
  setSubscriptions(subscriptionData) {
    const uuids = new Array();
    const operations = new Array();

    for (var i = 0; i < subscriptionData.length; i++) {
      const data = subscriptionData[i];
      uuids.push(data.uuid);
      operations.push(localforage.setItem(`subscription_${data.uuid}`, data));
    }

    operations.push(localforage.setItem("subscriptionRef", uuids));

    return Promise.all(operations);
  },
  setLastVisitedUrl(url) {
    return this.setItem("last_url", url);
  },
  getLastVisitedUrl() {
    return this.getItem("last_url");
  },
  isLocked() {
    return localforage.getItem("applocked").then(function(isLocked) {
      if (isLocked) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    });
  },
  lock() {
    return localforage.setItem("applocked", true);
  },
  unlock(password) {
    return localforage.getItem("specialK").then(specialK => {
      if (!specialK) {
        return Promise.reject(new Error("not special enough :-("));
      }
      var hash = crypt
        .SHA256(password + specialK.salt)
        .toString(crypt.enc.Base64);

      var decSalt = _decrypt(specialK.encSalt, hash);
      if (decSalt === specialK.salt) {
        return localforage.setItem("applocked", false).then(function() {
          return Promise.resolve(true);
        });
      } else {
        // @TODO: Force open Jobel with a new password on the server.
        // eslint-disable-next-line no-console
        console.debug(
          "Force open Jobel with a new password on the server.",
          specialK,
          hash,
          decSalt
        );
      }

      return Promise.resolve(false);
    });
  },
  logout() {
    // a logout should be a delete() BUT using this until network session reactivity issue is fixed
    return localforage.removeItem("specialK").catch(function(err) {
      console.error("Failed to logout", err); // eslint-disable-line no-console
    });
  },
  delete() {
    return localforage.clear().catch(function(err) {
      console.error("Failed to clear localforage", err); // eslint-disable-line no-console
    });
  }
};
