<template>
  <div id="pageTripList_modules" class="page modulesPage triplist insideApp">
    <h1>{{ $t("header.inactivities") }}</h1>
    <h2>{{ $t("formList.subtitle") }}</h2>

    <div class="content">
      <div class="topPageMenu">
        <b-col cols="12" sm="8">
          <a @click="$showDfoInstructions('f233')">
            {{ $t("triplist.dfoInstructions.inactivity") }}
          </a>
        </b-col>
        <br />
        <b-col cols="12" sm="8">
          <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
        </b-col>
      </div>
      <router-link
        :class="hasOpen && 'deactivated'"
        class="btn-add"
        :to="!hasOpen ? { name: 'editForm', params: { reportId: id } } : {}"
      >
        <span>
          <span>
            <span>{{ $t("formList.add") }}</span>
          </span>
        </span>
      </router-link>
      <div class="trip-list-wrapper">
        <router-link
          :class="hasOpen && 'deactivated'"
          class="btn-add"
          :to="!hasOpen ? { name: 'editForm', params: { reportId: id } } : {}"
        >
          <span>
            <span>
              <span>{{ $t("formList.add") }}</span>
            </span>
          </span>
        </router-link>
        <h2 class="tableTitle">
          <span>
            <span>
              <span>
                {{ $t("formList.open") }}
                ({{ numOpened | formatNumber(this) }})
              </span>
            </span>
          </span>
        </h2>
        <table class="tableList open">
          <tbody>
            <tr class="tableHead">
              <th class="sortingDatetime-column column-title">
                {{ $t("date") }}
              </th>
              <th class="status-column column-title">{{ $t("status") }}</th>
              <th class="actions-btn-column column-title">
                {{ $t("actions") }}
              </th>
            </tr>
            <tr v-if="!hasOpen" class="empty-list">
              <td class="empty-list sortingDatetime-column column-title">
                ...
              </td>
              <td class="empty-list status-column column-title">...</td>
              <td class="empty-list actions-btn-column column-title">...</td>
            </tr>
            <tr v-else class="open">
              <td class="sortingDatetime-column sortingDatetime">
                {{ $showDate(openSortingDatetime) }}
              </td>
              <td class="status-column status">
                <span class="open">
                  !
                  <ul class="tooltiptext">
                    <li class="open">{{ $t("triplist.status.open") }}</li>
                  </ul>
                </span>
              </td>
              <td class="actions-btn-column actions-btn">
                <router-link
                  class="btn-edit"
                  :to="{ name: 'editForm', params: { reportId: id } }"
                >
                  <span>
                    <span>{{ $t("formList.action.edit") }}</span>
                  </span>
                </router-link>
                <router-link
                  class="btn-view"
                  :to="{
                    name: 'reviewForm',
                    params: { reportId: id, luid: openForm['reportUID'] }
                  }"
                  v-if="openForm.status === 'review'"
                >
                  <span>
                    <span>{{ $t("formList.action.close") }}</span>
                  </span>
                </router-link>
                <a class="btn-delete" @click="clickDeleteForm()">
                  <span>{{ $t("formList.action.delete") }}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="tableTitle">
        <span>
          <span>
            <span>
              {{ $t("formList.closed") }}
              ({{ closedForms.length | formatNumber(this) }})
            </span>
          </span>
        </span>
      </h2>
      <table class="tableList open">
        <tbody>
          <tr class="tableHead">
            <th class="sortingDatetime-column column-title">
              {{ $t("date") }}
            </th>
            <th class="status-column column-title">{{ $t("status") }}</th>
            <th class="actions-btn-column column-title">
              {{ $t("actions") }}
            </th>
          </tr>
          <tr v-if="hasOpen.length === 0" class="empty-list">
            <td class="empty-list sortingDatetime-column column-title">...</td>
            <td class="empty-list status-column column-title">...</td>
            <td class="empty-list actions-btn-column column-title">...</td>
          </tr>
          <tr
            v-for="trip in closedForms"
            :key="trip.reportUID"
            class="close2"
            :class="closedTripClass(trip)"
          >
            <td class="sortingDatetime-column sortingDatetime">
              {{ $showDate(trip["creation"]) }}
            </td>
            <td class="status-column status">
              <span class="close2 ready" v-if="trip['status'] === 'closed'">
                !
                <ul class="tooltiptext">
                  <li class="closeStatus">{{ $t("triplist.status.close") }}</li>
                  <li class="ready">{{ $t("triplist.status.ready") }}</li>
                </ul>
              </span>
              <span class="close2 notReady" v-if="trip['status'] === 'landing'">
                !
                <ul class="tooltiptext">
                  <li class="closeStatus">{{ $t("triplist.status.close") }}</li>
                  <li class="notReady">
                    {{ $t("triplist.status.notReady") }}
                  </li>
                </ul>
              </span>
            </td>
            <td class="actions-btn-column actions-btn">
              <a class="btn-view" @click="clickSendForm(trip['reportUID'])">
                <span>
                  <span>{{ $t("formList.action.send") }}</span>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <h2 class="tableTitle">
        <span>
          <span>
            <span>
              {{ $t("formList.sent") }}
              ({{ syncCount | formatNumber(this) }})
              <span class="titleNote">
                {{ $t("triplist.message.uneditable") }}
              </span>
            </span>
          </span>
        </span>
      </h2>
      <p v-if="checkSyncError" class="failTripWarning">
        {{ $t("triplist.message.failure") }}
      </p>
      <table class="tableList synched">
        <tbody>
          <tr class="tableHead">
            <th class="sortingDatetime-column column-title">
              {{ $t("date") }}
            </th>
            <th class="status-column column-title">{{ $t("status") }}</th>
            <th class="actions-btn-column column-title">
              {{ $t("actions") }}
            </th>
          </tr>
          <tr v-if="!syncCount" class="empty-list">
            <td class="empty-list sortingDatetime-column column-title">...</td>
            <td class="empty-list status-column column-title">...</td>
            <td class="empty-list actions-btn-column column-title">...</td>
          </tr>
          <tr v-for="trip in formList" :key="trip.reportUID">
            <td class="sortingDatetime-column sortingDatetime">
              {{ $showDate(trip["creation"]) }}
            </td>
            <td class="status-column status">
              <span v-if="trip.sentToDFO" class="synched sent">
                ✓
                <ul class="tooltiptext">
                  <li class="synched">{{ $t("triplist.status.synched") }}</li>
                  <li class="sent">{{ $t("triplist.status.sent") }}</li>
                </ul>
              </span>
              <span v-else class="synched notSent">
                X
                <ul class="tooltiptext">
                  <li class="synched">{{ $t("triplist.status.synched") }}</li>
                  <li class="notSent">{{ $t("triplist.status.notSent") }}</li>
                </ul>
              </span>
            </td>
            <td class="actions-btn-column actions-btn">
              <a class="btn-view" @click="clickViewForm(trip['reportUID'])">
                {{ $t("formList.action.view") }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "FormList",
  data: function() {
    return {
      id: ""
    };
  },
  computed: {
    ...mapState({
      openForm: state => state.currentOpenTrip,
      closedForms: state => state.closedTrips,
      formList: state => state.currentTripList,
      userProfile: state => state.userProfile
    }),
    numOpened() {
      return this.hasOpen ? 1 : 0;
    },
    hasOpen() {
      return ["open", "review"].includes(this.openForm.status);
    },
    openSortingDatetime() {
      var sortingDatetime = this.openForm["creation"];
      return sortingDatetime;
    },
    syncCount() {
      return this.formList.length;
    },
    checkSyncError() {
      for (let trip of this.formList) {
        if (!trip.sentToDFO) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions(["selectReportContext", "setCurrentOpenTrip"]),
    closedTripClass(trip) {
      return trip["status"] === "closed" ? "ready" : "notReady";
    },
    clickDeleteForm() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("closeDeleteFormReport"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(deleteForm => {
        if (deleteForm) {
          this.setCurrentOpenTrip({
            uuid: this.id,
            currentOpenTrip: {}
          });
        }
      });
    },
    clickSendForm(id) {
      this.$router.push({
        name: "sendForm",
        params: {
          reportId: this.id,
          luid: id
        }
      });
    },
    clickViewForm(id) {
      this.$router.push({
        name: "viewForm",
        params: {
          reportId: this.id,
          luid: id
        }
      });
    }
  },
  created() {
    this.id = this.$route.params.reportId;
    this.selectReportContext(this.id);
  },
  mounted() {
    this.$store.dispatch("getRemoteForms", this.id);
  },
  beforeRouteLeave(to, from, next) {
    if (["closeForm", "reviewForm", "sendForm", "viewForm"].includes(to.name)) {
      const luid = to.params.luid;
      this.$store.dispatch("selectTrip", luid).then(next());
    } else {
      next();
    }
  },
  filters: {
    formatNumber(value, context) {
      return value ? value : context.$t("triplist.none");
    }
  }
};
</script>
