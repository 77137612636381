import axios from "axios";
import store from "../store/";

// "Content-Type": "application/json" we use url-encoded
export default {
  default() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_DJANGO_BASE_URL,
      timeout: 10000, // this is response timeout instead of connection timeout
      headers: {
        Accept: "application/json"
      },
      withCredentials: true,
      transformRequest: [
        (data, headers) => {
          const token = store.getters.OFFLINETOKEN;
          const language = store.getters.LANGUAGE;
          const clientTag = require("@/buildId.json").clientTag;
          if (data instanceof FormData) {
            if (token) {
              data.append("offlineToken", token);
            }
            if (language) {
              data.append("language", language);
            }
            if (clientTag) {
              data.append("clientTag", clientTag);
            }
          }
          return data;
        }
      ]
    });

    // Add a response interceptor (applies to all server's responses)
    instance.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        store.commit("SET_TIMEOUTERROR", false); // LF: added to clear internet errors
        store.commit("SET_INTERNET", true);

        const data = response.data;

        // Jobel client version check
        if (process.env.NODE_ENV === "production") {
          const latestClientVersion = data.activeTag;
          const currentClientVersion = response.config.data.get("clientTag");
          if (
            latestClientVersion &&
            currentClientVersion &&
            currentClientVersion !== latestClientVersion
          ) {
            // eslint-disable-next-line no-console
            console.warn(
              "Server report Jobel client version is obsolete",
              currentClientVersion,
              latestClientVersion
            );
            // asks service worker to check for update manually
            window.navigator.serviceWorker.ready.then(sw => sw.update());
          }
        }

        if (data.error === 600) {
          // past this point, no one else needs the response object, return data.
          delete data.error;
          return data;
        }

        // Jobel has answered but server says there is a problem :(
        if (process.env.VUE_APP_DEBUG_ONLINE) {
          console.info("Jobel server responded with an error", data); // eslint-disable-line no-console
        }

        data.errorType = "server";
        if (data.error === 622) {
          data.errorType = "user";
        }

        if (data.error === 604) {
          data.errorType = "noupdate";
        }

        if (data.error === 601) {
          data.errorType = "session";
          return store.dispatch("logout").then(() => Promise.reject(data));
        }

        return Promise.reject(data);
      },
      function(error) {
        // Jobel server unavailable, generic Network Error are translated to status 0 by axios

        // No need for the error object outside this function, return interpreted data
        const data = {
          message: error?.message,
          code: error?.code,
          errorType: "network"
        };

        if (process.env.VUE_APP_DEBUG_ONLINE) {
          console.info("HTTP error occured during call", error); // eslint-disable-line no-console
        }

        if (error?.request?.status == 0 && error?.code == "ECONNABORTED") {
          // THIS IS A JOBEL TIMEOUT (connection has openned but never concluded)
          if (process.env.VUE_APP_DEBUG_ONLINE) {
            console.warn("Online timeout error ", error); // eslint-disable-line no-console
          }
          store.commit("SET_TIMEOUTERROR", true);
          data.errorType = "timeout";
          data.i18n = "message.timeOutError";
        } else if (error?.response?.status == 502) {
          // Bad gateway happens usually when server is available (nginx) but django is not
          if (process.env.VUE_APP_DEBUG_ONLINE) {
            console.warn("Django unavailable?", error); // eslint-disable-line no-console
          }
          data.errorType = "unavailable";
          data.i18n = "message.unavailableError";
        } else if (error?.code == "ERR_NETWORK") {
          data.i18n = "message.noConnectionError";
        }

        return Promise.reject(data);
      }
    );

    return instance;
  }
};
