<template>
  <div class="page trip insideApp">
    <h1>
      {{ $t("editTrip.inactivityStep.title") }}
    </h1>
    <h2>
      {{ $t("reviewForm.subtitle") }}
    </h2>

    <div class="content">
      <div class="topPageMenu">
        <a @click="returnClick" v-html="$t('editForm.returnToRegister')" />
      </div>
      <div class="tripContent">
        <div>
          <h3>
            <span>
              <span>
                {{ $t("editTrip.subscriptionStep.title") }}
              </span>
            </span>
          </h3>
          <fieldset>
            <h4>
              {{ $t("editForm.identification") }}
            </h4>
            <b-row>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="currentForm.inactivity['reportUID']"
                  type="text"
                  :label="$t('editTrip.inactivityStep.reportUID')"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="currentForm.software['version']"
                  :label="$t('editTrip.subscriptionStep.version')"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="currentForm.software['provider']"
                  :label="$t('editTrip.subscriptionStep.provider')"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editForm.creation')"
                  :value="$showDate(currentForm['creation'])"
                  type="text"
                />
              </b-col>
            </b-row>
            <h4>
              {{ $t("editTrip.subscriptionStep.dfo") }}
            </h4>
            <b-row>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.subscriptionStep.dfo')"
                  :value="currentForm['dfoRegion']"
                  :options="systemsLists.dfoRegions"
                  type="select"
                />
              </b-col>
            </b-row>
            <h4 v-if="formHasOperator">
              {{ $t("editTrip.subscriptionStep.operator") }}
            </h4>
            <b-row v-if="formHasOperator">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.operator['name']"
                  :label="$t('editTrip.subscriptionStep.operatorName')"
                  :value="currentForm.operator['name']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.operator['fisherIdentificationNumber']"
                  :label="$t('editTrip.subscriptionStep.operatorFin')"
                  :value="currentForm.operator['fisherIdentificationNumber']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.operator['email']"
                  :label="$t('fields.email')"
                  :value="currentForm.operator['email']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.operator['address']"
                  :label="$t('fields.address')"
                  :value="currentForm.operator['address']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.operator['phoneNumber']"
                  :label="$t('fields.phone')"
                  :value="currentForm.operator['phoneNumber']"
                  type="text"
                />
              </b-col>
            </b-row>
            <h4 v-if="formHasVessel">
              {{ $t("editTrip.subscriptionStep.vessel") }}
            </h4>
            <b-row v-if="formHasVessel">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.vessel['name']"
                  :label="$t('editTrip.subscriptionStep.vesselName')"
                  :value="currentForm.vessel['name']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  v-if="currentForm.vessel['registeredNumber']"
                  :label="$t('editTrip.subscriptionStep.vesselNumber')"
                  :value="currentForm.vessel['registeredNumber']"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
          <InactivityViewer
            v-if="
              currentForm.inactivity &&
                currentForm.inactivity !== null &&
                currentForm.inactivity.reportUID
            "
            :hideGeneralInfo="true"
            :inactivity="currentForm.inactivity"
          />
        </div>
        <br />
        <br />
        <div v-if="formHasTransmissionLogs || formHasTransmissionFiles">
          <h3>
            <span>
              <span>
                {{ $t("editTrip.summary.transmissionLog") }}
              </span>
            </span>
          </h3>
          <table v-if="formHasTransmissionLogs">
            <tbody>
              <tr class="center">
                <th>{{ $t("date") }}</th>
                <th>{{ $t("status") }}</th>
                <th>{{ $t("messages") }}</th>
              </tr>
              <tr
                v-for="(transmission, index) in currentForm['transmissions']"
                :key="'transmission-' + index"
              >
                <td class="center">{{ $showDate(transmission.date) }}</td>

                <td class="success" v-if="transmission.status">✓</td>
                <td class="errorMsg" v-else-if="transmission.status === false">
                  ✕
                </td>
                <td class="success" v-else-if="transmission.dfoValid">✓</td>
                <td
                  class="errorMsg"
                  v-else-if="transmission.dfoValid === false"
                >
                  ✕
                </td>
                <td v-else></td>

                <td>
                  {{ transmission.message }}
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="formHasTransmissionFiles">
            <tbody>
              <tr class="center">
                <th>{{ $t("forms") }}</th>
                <th>{{ $t("messages") }}</th>
              </tr>
              <tr
                v-for="(file, fileIndex) in currentForm['files']"
                :key="'file-' + fileIndex"
              >
                <td>
                  {{ $t("form") }} {{ file.form }}
                  <br />
                  {{ $t("file") }}
                  <a
                    @click="clickDownload(file.id)"
                    class="btn-downloadxml"
                    style="display: inline"
                  >
                    {{ file.filename }}
                  </a>
                  <span class="success" v-if="file.dfoConfirmation">
                    <br />
                    {{ $t("triplist.confirmationNumber") }}
                    {{ file.dfoConfirmation }}
                  </span>
                </td>
                <td>
                  <table class="nomargin">
                    <tr
                      v-for="(log, logIndex) in file['transmissions']"
                      :key="'file-' + fileIndex + '-' + logIndex"
                    >
                      <td class="noborder nopadding">
                        {{ $showDate(log.date) }} -
                        <span :class="log.status ? 'success' : 'errorMsg'">
                          {{ log.message }}
                        </span>
                      </td>
                      <td class="nopadding">
                        <span class="success" v-if="log.status">✓</span>
                        <span class="errorMsg" v-else>✕</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="action-btn-wrapper">
        <div class="action-btn-wrapper-left">
          <a
            v-if="currentForm.status === 'review'"
            @click="clickEdit"
            class="action-btn action-btn-edit"
          >
            <span>
              <span>{{ $t("formList.action.edit") }}</span>
            </span>
          </a>
          <a
            v-if="currentForm.status === 'review'"
            @click="clickDelete"
            class="action-btn action-btn-delete secondLine"
          >
            <span>
              <span>{{ $t("formList.action.delete") }}</span>
            </span>
          </a>
        </div>
        <div class="action-btn-wrapper-right">
          <a
            v-if="currentForm.status === 'review'"
            @click="clickClose"
            class="action-btn action-btn-close"
            id="actionButton"
          >
            <span>
              <span>{{ $t("formList.action.close") }}</span>
            </span>
          </a>
          <a
            v-else-if="currentForm.status === 'closed'"
            @click="clickDfo"
            class="action-btn action-btn-send"
            id="actionButton"
          >
            <span>
              <span>{{ $t("formList.action.send") }}</span>
            </span>
          </a>
        </div>
      </div>
      <div class="action-btn-wrapper-center">
        <a @click="returnClick" v-html="$t('editForm.returnToRegister')" />
      </div>
    </div>
  </div>
</template>
<script>
import Jobel from "@/service/jobel.js";
import { mapState, mapActions } from "vuex";
import LabelView from "@/components/LabelView.vue";
import InactivityViewer from "@/components/viewtrip/InactivityViewer.vue";
export default {
  name: "ViewForm",
  components: {
    LabelView,
    InactivityViewer
  },
  data: () => ({
    id: ""
  }),
  props: {
    action: {
      type: String,
      required: true,
      validator: function(value) {
        return ["close", "review", "send", "view"].includes(value);
      }
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      openForm: state => state.currentOpenTrip,
      currentForm: state => state.selectedTrip,
      userProfile: state => state.userProfile
    }),
    formHasOperator() {
      return (
        this.currentForm.operator &&
        (this.currentForm.operator["name"] ||
          this.currentForm.operator["fisherIdentificationNumber"])
      );
    },
    formHasVessel() {
      return (
        this.currentForm.vessel &&
        (this.currentForm.vessel["name"] ||
          this.currentForm.vessel["registeredNumber"])
      );
    },
    formHasTransmissionLogs() {
      return (
        this.currentForm["transmissions"] &&
        this.currentForm["transmissions"].length > 0
      );
    },
    formHasTransmissionFiles() {
      return this.currentForm["files"] && this.currentForm["files"].length > 0;
    }
  },
  watch: {
    openForm() {
      this.$store.dispatch("selectTrip", this.$route.params.luid);
    }
  },
  methods: {
    ...mapActions([
      "setCurrentOpenTrip",
      "setCurrentClosedTrip",
      "resetEditTripState",
      "sendTrip"
    ]),
    scrollIf() {
      if (["close", "send"].includes(this.action)) {
        this.$nextTick(function() {
          let button = document.getElementById("actionButton");
          if (button) button.scrollIntoView();
        });
      }
    },
    returnClick() {
      this.$router.push({
        name: "formList"
      });
    },
    clickEdit() {
      this.$router.push({
        name: "editForm",
        params: { reportId: this.id }
      });
    },
    clickDelete() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("closeDeleteFormReport"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(deleteForm => {
        if (deleteForm) {
          this.setCurrentOpenTrip({
            uuid: this.id,
            currentOpenTrip: {}
          });
          this.$router.push({
            name: "formList",
            params: { reportId: this.id }
          });
        }
      });
    },
    clickDownload(id) {
      Jobel.getXML(id)
        .then(function(data) {
          var blob = new Blob([data["content"]], {
            type: "text/xml",
            endings: "native"
          });
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = data["filename"];
          var anchor = document.body.appendChild(a);
          anchor.click();
          document.body.removeChild(anchor);
        })
        .catch(error => vuejs.$alertUserApiError(error));
    },
    clickClose() {
      let form = this.currentForm;
      this.$swal({
        title: this.$t("reallyCloseForm"),
        text: this.$t("closeFormTrip"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(closeForm => {
        if (closeForm) {
          form.status = "closed";
          form["closeDatetime"] = new Date().toISOString();
          this.setCurrentOpenTrip({
            uuid: this.id,
            currentOpenTrip: {}
          });
          this.setCurrentClosedTrip({
            uuid: this.id,
            currentClosedTrip: form
          });

          this.$swal({
            title: this.$t("formClosed"),
            icon: "success",
            button: "OK"
          });

          this.$router.push({
            name: "formList",
            params: { reportId: this.id }
          });
        }
      });
    },
    clickDfo() {
      const vm = this;
      vm.sendTrip({ trip: this.currentForm, type: this.id }).then(() => {
        vm.$router.push({
          name: "formList",
          params: { reportId: this.id }
        });
      });
    }
  },
  created() {
    this.id = this.$route.params.reportId;
    this.resetEditTripState();
  },
  mounted() {
    this.scrollIf();
  }
};
</script>
<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.tripContent {
  padding: 1.2%;
  background-color: #fff;
}

.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}
.errorMsg {
  text-align: center;
  color: red;
}

.dfo-complete {
  color: green;
}

.uvalue {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
