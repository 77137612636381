<template>
  <div>
    <fieldset class="fieldsetDetails">
      <h4>{{ $t("catch.details") }}</h4>
      <fieldset
        class="fieldsetDetailsNoBorder"
        v-for="(detail, indexDetail) in details"
        :key="indexDetail"
      >
        <h4>
          {{ $t("catch.detail") }} {{ indexDetail + 1 }}
          <span
            v-b-modal="'modal-Detail-' + indexDetail + '-remark'"
            class="add-remark-btn-title"
          />
        </h4>
        <Popup
          v-model="detail['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'modal-Detail-' + indexDetail + '-remark'"
        />
        <Select
          :label="$t('catch.size')"
          :options="specimenSizeOptions"
          v-model="detail['size']"
          :required="true"
          @error="addError"
          :refeshError="showErrorData"
        />
        <UnitConverter
          :label="$t('editTrip.lastStep.weight')"
          v-model="detail['weight']"
          :required="true"
          :min="0"
          :max="999999.999"
          unit="weight"
          @error="addError"
          :refresh="showErrorData"
        />
      </fieldset>
      <div class="center">
        <fieldset class="suppEntryButtonWrapper">
          <input
            v-if="details.length > 0"
            @click="removeDetail()"
            class="removeSuppEntryButton"
            type="button"
            :value="$t('catch.removeDetail')"
          />
          <input
            @click="addDetail()"
            class="addSuppEntryButton"
            type="button"
            :disabled="disableAdd"
            :value="$t('catch.addDetail')"
          />
        </fieldset>
      </div>
    </fieldset>
  </div>
</template>
<script>
import Select from "@/components/Select.vue";
import Popup from "@/components/widgets/Popup.vue";

import { mapState, mapGetters } from "vuex";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "Details",
  components: {
    Select,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number,
    addError: Function,
    details: Array,
    catch: Object
  },
  data() {
    return {
      disableAdd: true,
      refreshIndex: [],
      triggerShowError: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      systemsLists: state => state.systemsLists
    }),
    specimenSizes() {
      return this.getPropertyValue(
        "specimenSizes",
        { subforms: this.subform },
        []
      );
    },
    specimenSizeOptions() {
      return this.buildOptions(
        this.specimenSizes,
        this.systemsLists.productSize
      );
    }
  },
  methods: {
    addDetail() {
      const vm = this;
      vm.triggerShowError++;
      vm.$nextTick(() => {
        vm.details.push({
          size: null,
          productForm: this.catch["productForm"],
          weight: null,
          remark: null
        });
      });
      vm.refreshIndex.push(0);
    },
    removeDetail() {
      this.details.pop();
      this.refreshIndex.pop();
    },
    reviewEmptyCatch() {
      if (
        this.catch["keptWeight"] != null ||
        this.catch["discWeight"] != null ||
        this.catch["keptNbSpecimen"] != null ||
        this.catch["discNbSpecimen"] != null
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    catch: {
      handler: function() {
        this.disableAdd = this.reviewEmptyCatch();
        if (!this.disableAdd) {
          return;
        }
        for (let index = 0; index < this.details.length; index++) {
          this.removeDetail();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.disableAdd = this.reviewEmptyCatch();
  }
};
</script>

<style lang="scss" scoped>
.fieldsetDetailsNoBorder {
  border: false;
  margin-top: 15px;
  width: 95%;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
}
.fieldsetDetails {
  border: 1px solid$darkColor;
  margin-top: 15px;
  width: 95%;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
}
</style>
