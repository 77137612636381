<template>
  <div v-if="hasTripTotals">
    <h4>
      {{ $t("editTrip.lastStep.additionalInfo") }}
    </h4>

    <fieldset>
      <UnitConverter
        :label="$t('editTrip.lastStep.weightOnBoardAditionalInfo')"
        v-model="form['weightOnBoard']"
        :required="hasTripTotals === $const.MANDATORY"
        :min="0"
        :max="999999.999"
        unit="weight"
        @error="addErrorToParent"
        :refresh="showErrorDataParent"
      />
      <IntegerInput
        v-model.number="form.nbFrozenBlocks"
        :label="$t('editTrip.lastStep.nbFrozenBlocks')"
        :required="hasTripTotals === $const.MANDATORY"
        :max="999999"
        :min="0"
        :refeshError="showErrorDataParent"
        @error="addErrorToParent"
      />
      <IntegerInput
        v-model.number="form.nbFreshContainers"
        :label="$t('editTrip.lastStep.nbFreshContainers')"
        :required="hasTripTotals === $const.MANDATORY"
        :max="999999"
        :min="0"
        :refeshError="showErrorDataParent"
        @error="addErrorToParent"
      />
    </fieldset>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  components: {
    IntegerInput,
    UnitConverter
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: {
        weightOnBoard: null,
        nbFrozenBlocks: null,
        nbFreshContainers: null
      }
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      subform: state => state.editTripSubform.subform
    }),
    hasTripTotals() {
      return this.getPropertyValue("hasTripTotals", { subforms: this.subform });
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-additionalInfo", this.form);
      }
    },
    hasTripTotals: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.form.weightOnBoard = null;
          this.form.nbFrozenBlocks = null;
          this.form.nbFreshContainers = null;
        }
      }
    }
  },
  beforeMount() {
    if (this.openTrip && "creation" in this.openTrip) {
      this.form.weightOnBoard = this.openTrip.weightOnBoard;
      this.form.nbFrozenBlocks = this.openTrip.nbFrozenBlocks;
      this.form.nbFreshContainers = this.openTrip.nbFreshContainers;
    }
  }
};
</script>
