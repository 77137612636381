<template>
  <p class="field-wrapper">
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
    </span>

    <!--default-->
    <b-input
      v-if="!isDoubleInput && !needNumberValidation"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="referenceValue"
      :name="id"
      type="number"
      @input="onChange($event)"
      :min="min"
      :max="max"
      :inputmode="'decimal'"
      :class="errorClass"
      @blur="focusChanged"
    />
    <b-input
      v-else-if="!isDoubleInput && needNumberValidation"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="referenceValue"
      :name="id"
      type="text"
      @input="onChange($event)"
      :formatter="formatValue"
      @keydown="onKeydown($event)"
      :min="min"
      :max="max"
      :inputmode="'decimal'"
      :class="errorClass"
      @blur="focusChanged"
    />

    <!--DoubleInput (metric)-->
    <b-input-group class="mb-2 border" v-if="isDoubleInput && isMetric">
      <b-input-group class="leftHalf">
        <template #append>
          <b-input-group-text class="grey">
            {{ getCurrentUnit1 }}
          </b-input-group-text>
        </template>
        <b-form-input
          v-if="!needNumberValidation"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="referenceValue"
          class="doubleInput"
          type="number"
          @input="onChange($event)"
          :min="min"
          :max="max"
          inputmode="decimal"
          :name="id"
          :class="errorClass"
          @blur="focusChanged"
        />
        <b-form-input
          v-else-if="needNumberValidation"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="referenceValue"
          class="doubleInput"
          type="text"
          @input="onChange($event)"
          :formatter="formatValue"
          @keydown="onKeydown($event)"
          :min="min"
          :max="max"
          inputmode="decimal"
          :name="id"
          :class="errorClass"
          @blur="focusChanged"
        />
      </b-input-group>
      <b-input-group class="rightHalf">
        <template #append>
          <b-input-group-text class="white">
            {{ getCurrentUnit2 }}
          </b-input-group-text>
        </template>
        <b-form-input
          :disabled="true"
          :value="convertedValue"
          type="text"
          class="doubleInput"
          :class="errorClass"
        />
      </b-input-group>
    </b-input-group>

    <!--DoubleInput (non-metric)-->
    <b-input-group class="mb-2 border" v-if="isDoubleInput && !isMetric">
      <b-input-group class="leftHalf">
        <template #append>
          <b-input-group-text class="grey">
            {{ getCurrentUnit2 }}
          </b-input-group-text>
        </template>
        <b-form-input
          v-if="!needNumberValidation"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="convertedValue"
          :min="min"
          :max="max"
          type="number"
          @input="onChange($event)"
          inputmode="decimal"
          class="doubleInput"
          :name="id"
          :class="errorClass"
          @blur="focusChanged"
        />
        <b-form-input
          v-else-if="needNumberValidation"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="convertedValue"
          :min="min"
          :max="max"
          type="text"
          @input="onChange($event)"
          :formatter="formatValue"
          @keydown="onKeydown($event)"
          inputmode="decimal"
          class="doubleInput"
          :name="id"
          :class="errorClass"
          @blur="focusChanged"
        />
      </b-input-group>
      <b-input-group class="rightHalf">
        <template #append>
          <b-input-group-text class="white">
            {{ getCurrentUnit1 }}
          </b-input-group-text>
        </template>
        <b-form-input
          :disabled="true"
          :value="referenceValue"
          type="text"
          class="doubleInput"
          :class="errorClass"
        />
      </b-input-group>
    </b-input-group>
    <!--Error-->
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "DecimalInput",
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      default: undefined
    },
    minlength: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    refeshError: {
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    maxDecimal: {
      default: undefined
    },
    weightConversion: {
      default: false
    },
    heightConversion: {
      default: false
    },
    distanceConversion: {
      default: false
    }
  },
  data() {
    return {
      id: Math.round(Math.random() * 100000) + "",
      referenceValue: null,
      convertedValue: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      filter: {
        type: "number",
        required: this.required,
        min: this.min,
        max: this.max,
        maxlength: this.maxlength,
        minlength: this.minlength
      },
      conversionValues: {
        weightIndex: 2.20462,
        heightIndex: 3.28084,
        distanceIndex: 0.5468
      },
      conversionAttributes: {
        weightAttribute: "KG",
        heightAttribute: "M",
        distanceAttribute: "M"
      },
      unitConversionValues: {
        weightUnits: {
          unit1: "kg",
          unit2: "lb"
        },
        heightUnits: {
          unit1: "m",
          unit2: "ft"
        },
        distanceUnits: {
          unit1: "m",
          unit2: "fm"
        }
      },
      isError: false,
      unit1: "",
      unit2: ""
    };
  },
  computed: {
    getConversionValue() {
      let returnedValue = 1;
      if (this.weightConversion) {
        returnedValue = this.conversionValues.weightIndex;
      } else if (this.heightConversion) {
        returnedValue = this.conversionValues.heightIndex;
      } else if (this.distanceConversion) {
        returnedValue = this.conversionValues.distanceIndex;
      }
      /*if (this.maxDecimal) {
        returnedValue =
          Math.round(returnedValue * Math.pow(10.0, this.maxDecimal)) /
          Math.pow(10.0, this.maxDecimal);
      }*/
      return returnedValue;
    },
    getCurrentUnit1() {
      if (this.weightConversion) {
        return this.unitConversionValues.weightUnits.unit1;
      } else if (this.heightConversion) {
        return this.unitConversionValues.heightUnits.unit1;
      } else if (this.distanceConversion) {
        return this.unitConversionValues.distanceUnits.unit1;
      }
      return null;
    },
    getCurrentUnit2() {
      if (this.weightConversion) {
        return this.unitConversionValues.weightUnits.unit2;
      } else if (this.heightConversion) {
        return this.unitConversionValues.heightUnits.unit2;
      } else if (this.distanceConversion) {
        return this.unitConversionValues.distanceUnits.unit2;
      }
      return null;
    },
    needNumberValidation() {
      if (this.$store) {
        return this.$store.getters.IS_NEED_BROWSER_NUMBER_VALIDATION;
      } else {
        return (
          navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
          (navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
            navigator.userAgent.toLowerCase().indexOf("chrome") == -1)
        );
      }
    },
    isDoubleInput() {
      return (
        this.weightConversion ||
        this.heightConversion ||
        this.distanceConversion
      );
    },
    isMetric() {
      if (this.weightConversion == this.conversionAttributes.weightAttribute)
        return true;
      if (this.heightConversion == this.conversionAttributes.heightAttribute)
        return true;
      if (
        this.distanceConversion == this.conversionAttributes.distanceAttribute
      )
        return true;
      return false;
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }
      return msg;
    }
  },
  watch: {
    refeshError() {
      this.refreshErrorMethod();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value() {
      // LF: In which circomstance is this called with values undefined ?
      if (this.referenceValue === null || this.referenceValue == undefined) {
        if (this.convertedValue === null || this.convertedValue == undefined) {
          this.initValues(this.value);
        }
      }
    }
  },
  methods: {
    initValues(value) {
      if (value === null || value === undefined) {
        return;
      }
      this.referenceValue = value;

      // LF: next 5 lines seems overkill, when isnt value already an accepted value with correct maxDecimal ?
      // if (this.maxDecimal) {
      //   this.referenceValue =
      //     Math.round(this.referenceValue * Math.pow(10.0, this.maxDecimal)) /
      //     Math.pow(10.0, this.maxDecimal);
      // }

      if (this.isDoubleInput) {
        if (this.isMetric) {
          this.convertedValue = Math.round(
            this.getConvertedValue(this.referenceValue, true)
          );
        } else {
          let converted = this.getConvertedValue(this.referenceValue, true);
          if (this.maxDecimal === 0) {
            this.convertedValue = Math.round(converted);
          } else if (this.maxDecimal) {
            converted =
              Math.round(converted * Math.pow(10.0, this.maxDecimal - 1)) /
              Math.pow(10.0, this.maxDecimal - 1);
            this.convertedValue = converted;
          }
          this.referenceValue = Math.round(this.referenceValue);
        }
      }
    },
    refreshErrorMethod() {
      this.setError();
    },
    setError() {
      let rt;
      let object = {}; // the object that will be emitted as error

      // normally we only have to check user input, but value based checks (min/max)
      // have to be performed only on the metric value (reference value)
      if (!this.isMetric && this.isDoubleInput) {
        let imperialFilters = {};
        let metricFilters = {};
        let metricOnlyProps = ["maxlength", "minlength", "min", "max"];
        Object.assign(imperialFilters, this.filter);
        Object.assign(metricFilters, this.filter);
        const keys = Object.keys(this.filter);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (metricOnlyProps.includes(key)) {
            metricFilters[key] = this.filter[key];
            imperialFilters[key] = undefined;
          } else {
            imperialFilters[key] = this.filter[key];
            metricFilters[key] = undefined;
          }
        }

        rt = Validator.genericValidation(this.convertedValue, imperialFilters);
        if (!rt.error) {
          metricFilters.type = "number";
          rt = Validator.genericValidation(this.referenceValue, metricFilters);
        }
      } else {
        rt = Validator.genericValidation(this.referenceValue, this.filter);
      }

      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;

      if (rt.notBlocked) {
        return;
      }

      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    focusChanged(e) {
      this.setError();
      this.$emit("binding", this.referenceValue);
    },
    formatValue(value) {
      let x = value;
      x = x.trim();
      x = x.replace(/[^0-9\.]/g, "");
      return x;
    },
    onKeydown(e) {
      if (e.key == ".") {
        let x = "";
        if (this.isMetric || !this.isDoubleInput) {
          x += this.referenceValue;
        } else {
          x += this.convertedValue;
        }
        let dotAparitions = 0;
        for (var i = 0; i < x.length; i++) {
          if (x[i] === ".") dotAparitions++;
        }
        if (dotAparitions > 0) e.preventDefault();
      }
    },
    onChange(userInput) {
      let value = userInput;
      if (value === undefined || value === "") {
        value = null;
        this.referenceValue = value;
      } else {
        this.referenceValue = parseFloat(value);
        if (this.isDoubleInput) {
          if (this.isMetric) {
            this.convertedValue = Math.round(
              this.getConvertedValue(this.referenceValue)
            );
          } else {
            this.convertedValue = parseFloat(value);
            let emitedValue = this.getConvertedValue(this.referenceValue);
            this.referenceValue = Math.round(emitedValue);
            this.$emit("input", emitedValue);
            return;
          }
        }
      }
      this.$emit("input", this.referenceValue);
    },
    getConvertedValue(currentValue, isFromInit = false) {
      let convertedValue = 0;
      if (this.isMetric || isFromInit) {
        convertedValue = currentValue * this.getConversionValue;
      } else {
        convertedValue = currentValue / this.getConversionValue;
      }
      if (this.maxDecimal === 0) {
        convertedValue = Math.round(convertedValue);
      } else if (this.maxDecimal > 0) {
        convertedValue =
          Math.round(convertedValue * Math.pow(10.0, this.maxDecimal)) /
          Math.pow(10.0, this.maxDecimal);
      }
      return convertedValue;
    }
  },
  mounted() {
    if (this.maxDecimal !== undefined) {
      this.filter.maxDecimal = this.maxDecimal;
      if (!this.isMetric && this.maxDecimal > 0 && this.isDoubleInput) {
        this.filter.maxDecimal = this.maxDecimal - 1;
      }
    }
    if (this.referenceValue === null || this.referenceValue === undefined) {
      if (this.convertedValue === null || this.convertedValue === undefined) {
        this.initValues(this.value);
      }
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
