<template>
  <div id="pageConnexion" class="page" :style="backgroundImage">
    <div class="page-content">
      <div class="page-header" v-if="!isLocked">
        <p class="languageSwitcher">
          <span v-if="!isEnglish">FR</span>
          <a
            class="languageSwitcher_fr"
            @click.stop="changeLanguage()"
            v-if="isEnglish"
            >FR</a
          >
          |
          <span v-if="isEnglish">EN</span>
          <a
            class="languageSwitcher_en"
            @click.stop="changeLanguage()"
            v-if="!isEnglish"
            >EN</a
          >
        </p>
        <a :href="homeUrl" class="jobel-homepage-heading">
          <img class="logo" :src="homepageLogo" />
          <span>{{ $t("connexion.home") }}</span>
        </a>
      </div>

      <div class="page-header" v-if="isLocked">
        <img
          class="logo"
          :src="homepageLogo"
          style="padding-top:100px; padding-bottom:30px;"
        />
      </div>

      <router-view />

      <div class="page-footer">
        <p>{{ $t("footer.text") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import homepageLogo from "@/assets/homepage-logo.png";

export default {
  name: "Connexion",
  computed: {
    isEnglish() {
      return this.$i18n.locale == "en";
    },
    isLocked() {
      return this.$store.getters.IS_APP_LOCKED;
    },
    homeUrl() {
      if (this.isEnglish) {
        return "https://www.jobel.ca/en/";
      }
      return "https://www.jobel.ca/";
    },
    backgroundImage() {
      return {
        "background-image":
          "url('" +
          process.env.VUE_APP_DJANGO_BASE_URL +
          "/static/images/background-01.webp')"
      };
    }
  },
  data() {
    return {
      homepageLogo
    };
  },
  methods: {
    changeLanguage: function(e) {
      let language = "en";
      if (this.$i18n.locale == "en") {
        language = "fr";
      }
      this.$store.dispatch("setLanguage", language);
    }
  }
};
</script>
