<template>
  <b-modal
    :id="modalName"
    :title="titleLabel"
    :ref="modalName"
    @shown="$refs[id].focus()"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form @submit.prevent="handleOk">
      <b-input
        :disabled="disabled"
        :ref="id"
        :placeholder="placeholder"
        :value="inputValue"
        :name="id"
        :class="errorClass"
        @input="onChange($event)"
        @blur="focusChanged"
      />
    </form>
    <span v-if="showCharCount" style="font-size: 0.7em; float: right">
      <span>{{ valueLength }}</span>
      <span>/</span>
      <span>{{ maxlength }}</span>
    </span>
    <!--Error-->
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </b-modal>
</template>
<script>
import Validator from "@/utils/validator.js";

export default {
  name: "Popup",
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    modalName: {
      type: String,
      required: true
    },
    titleLabel: {
      type: String,
      default: ""
    },
    help: {
      type: String,
      default: ""
    },
    maxlength: {
      default: undefined
    },
    minlength: {
      default: undefined
    },
    forceErrorMsg: {
      default: undefined
    }
  },
  data() {
    return {
      id: "PopupInput-" + (Math.random() * 100000).toFixed(0),
      isError: false,
      defaultErrorMsg: "",
      inputValue: null,
      errorClass: { error: false }
    };
  },
  methods: {
    onChange(value) {
      this.inputValue = value;
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    focusChanged(e) {
      this.setError(e.target.value);
    },
    handleOk(bvModalEvent) {
      this.setError(this.inputValue);
      if (this.isError) {
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit("input", this.inputValue);
      this.$refs[this.modalName].hide();
    },
    resetModal() {
      this.inputValue = this.value;
    }
  },
  computed: {
    filter() {
      return {
        type: "text",
        required: this.required,
        maxlength: this.maxlength,
        minlength: this.minlength
      };
    },
    valueLength() {
      return this.inputValue ? this.inputValue.length : null;
    },
    showCharCount() {
      return this.maxlength > 40;
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      }
      return msg;
    }
  },
  mounted() {
    this.inputValue = this.value;
  }
};
</script>
