<template>
  <div>
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.effortStep.tags") }}</span>
        </span>
      </span>
    </h3>
    <fieldset>
      <Tags
        :tags="form.tags"
        :checkError="
          error => {
            this.addErrors(error);
          }
        "
        :showErrorData="showErrorData"
      />
    </fieldset>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Tags from "./EffortStepComponents/Tags.vue";

const name = "tagsStep";

export default {
  components: {
    Tags
  },
  data: () => ({
    form: {
      tags: []
    },
    refreshError: 0,
    showErrorData: 0
  }),
  computed: {
    ...mapGetters(["errorMessageDateOverlap"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      currentPage: state => state.editTripSubform.currentPage,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      showError: state => state.editTripSubform.showError
    })
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addErrors(errors) {
      this.addErrorsToState({ page: name, errors: errors });
    },
    updateForm() {
      if (this.openTrip && "creation" in this.openTrip) {
        this.form.tags = this.openTrip.tags ?? [];
      }
    }
  },
  mounted() {
    this.updateForm();
  }
};
</script>
