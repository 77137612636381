import Base from "./Base.js";
// TODO: Seal the class
export default class Tow extends Base {
  catches = [];
  bycatches = [];
  tags = [];
  baitUsages = [];
  remark = null;
  soakDuration = null;
  nbGearHauled = null;
  nbGearLost = null;
  endDate = null;
  secteur = null;
  section = null;

  // TODO: a static var is sufficient to obtain a unique number but it's not
  // exactly what we need for the tow number. The requirement defined by rule
  // 797, the tow number is reset to 1 for each ELOG.

  // I don't believe that the solution comes from this class. Or at least, it
  // does not reside in changing the idx variable. I foresee the need for a
  // post-processing of the whole trip before sending it to the server. The
  // operation would resequence all tows and update all references.
  static idx = 1;
  constructor() {
    super();
    this.towNumIdx = Tow.idx++;
  }
}
