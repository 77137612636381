<template>
  <div class="page-main">
    <h1>{{ $t("connexion.reset") }}</h1>
    <p class="help">
      {{ $t("connexion.resetdone") }}
    </p>
    <div class="topPageMenu">
      <router-link :to="{ name: 'connexion' }">
        {{ $t("connexion.connexion") }}
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "connexion-reset-mail"
};
</script>

<style lang="scss" scoped>
.topPageMenu {
  color: $darkColor;
  padding-bottom: 25px;
}
.topPageMenu a {
  color: $darkColor;
  display: block;
  text-align: center;
  padding: 7px;
}
.topPageMenu a:hover {
  text-decoration: underline;
}
.topPageMenu .separator {
  display: none;
}
p.help {
  margin-bottom: 25px;
}
</style>
