<template>
  <fieldset v-if="hasGearBaitFastener" class="suppEntryButtonWrapper">
    <h4>{{ $t("editTrip.effortStep.baitFasteners") }}</h4>
    <fieldset v-for="(baitFastener, index) in baitFasteners" :key="index">
      <Select
        :label="$t('editTrip.effortStep.baitFastener') + ' ' + (index + 1)"
        :options="systemsLists.baitFastener"
        v-model="baitFasteners[index]"
        :forceErrorMsg="uniqElementMessage(baitFastener, index)"
        :required="true"
        @error="addErrorToParent"
        :refeshError="showErrorData"
        :key="'baitFastener-' + index"
      />
    </fieldset>
    <p>
      <input
        v-if="canRemove"
        @click="remove()"
        class="removeSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.removeFastener')"
      />
      <input
        v-if="canAdd"
        @click="add()"
        class="addSuppEntryButton"
        type="button"
        :value="$t('editTrip.effortStep.addFastener')"
      />
    </p>
  </fieldset>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Select from "@/components/Select.vue";

export default {
  components: {
    Select
  },
  props: {
    addErrorToParent: Function,
    showErrorData: Number,
    initialGearBaitFastner: Array,
    subform: Number,
    gear: Number
  },
  data() {
    return {
      baitFasteners: []
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      systemsLists: state => state.systemsLists
    }),
    canAdd() {
      if (this.baitFasteners && this.baitFasteners.length > 0) {
        const lastEntry = this.baitFasteners[this.baitFasteners.length - 1];
        return lastEntry != null && lastEntry !== "";
      }
      return true;
    },
    canRemove() {
      if (this.baitFasteners.length === 0) {
        return false;
      }
      if (
        this.hasGearBaitFastener === this.$const.MANDATORY &&
        this.baitFasteners.length === 1
      ) {
        return false;
      }
      return true;
    },
    hasGearBaitFastener() {
      return this.getPropertyValue("hasGearBaitFastener", {
        subforms: this.subform,
        gears: this.gear
      });
    }
  },
  watch: {
    gear: function() {
      this.updateMapping();
    },
    subform: function() {
      this.updateMapping();
    },
    baitFasteners: {
      deep: true,
      handler: function() {
        this.$emit("update-baitFasteners", this.baitFasteners);
      }
    }
  },
  methods: {
    updateMapping() {
      if (
        this.hasGearBaitFastener === this.$const.MANDATORY &&
        this.baitFasteners.length === 0
      ) {
        this.add();
      }
    },
    remove() {
      this.baitFasteners = this.baitFasteners.slice(0, -1);
      this.baitFasteners = [...this.baitFasteners];
    },
    add() {
      if (this.baitFasteners == null) {
        this.baitFasteners = [];
      }
      this.baitFasteners.push("");
      this.baitFasteners = [...this.baitFasteners];
    },
    uniqElementMessage(selectedValue, index) {
      const filteredArray = this.baitFasteners.filter(
        value => value === selectedValue
      );
      const firstIndex = this.baitFasteners.indexOf(selectedValue);
      if (filteredArray.length > 1 && firstIndex !== index) {
        return this.$t("fields.baitFastenerError");
      }
      return undefined;
    }
  },
  beforeMount() {
    this.baitFasteners = this.initialGearBaitFastner;
    this.updateMapping();
  }
};
</script>

<style></style>
