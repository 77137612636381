<template>
  <p class="field-wrapper" :class="{ uneditable: uneditable }">
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
      <a v-if="hasHelp" class="dot" :id="'tooltipButton' + id"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        :target="'tooltipButton' + id"
        placement="topleft"
        :title="help"
        variant="primary"
        triggers="click"
      >
      </b-tooltip>
    </span>
    <b-form-select
      :disabled="disabled"
      :multiple="multi"
      :select-size="multi ? 4 : 0"
      v-model="selected"
      :options="sortOptions"
      :name="id"
      :class="errorClass"
      @blur.native="focusChanged"
    />
    <!--Error-->
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>
<script>
import Validator from "@/utils/validator.js";

export default {
  name: "Select",
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    forceErrorMsg: {
      default: undefined
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    errorMsg: {
      type: String,
      default: ""
    },
    help: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    arrayValidator: {
      type: Array,
      default: function() {
        return [];
      }
    },
    multi: {
      type: Boolean,
      default: false
    },
    refeshError: {
      type: Number,
      default: 0
    },
    enableSort: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: "Select #" + (Math.random() * 100000).toFixed(0),
      selected: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      hasHelp: !!this.help,
      isError: false
    };
  },
  watch: {
    selected() {
      this.onChange(this.selected);
      this.checkOptionIfExist();
    },
    value() {
      if (!this.multi) {
        if (this.value) {
          this.selected = this.value;
        }
      } else {
        if (this.value) {
          this.selected = this.value;
        } else {
          this.selected = [];
        }
      }
    },
    refeshError() {
      this.refreshErrorMethod();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    options() {
      this.checkOptionIfExist();
    }
  },
  methods: {
    focusChanged(e) {
      this.setError(e.target.value);
      let value = e.target.value;
      this.$emit("binding", value);
    },

    onChange(value) {
      if (value === undefined || value === "" || value === null) {
        value = null;
      }
      this.setError(value);
      this.$emit("input", value);
    },
    setError(value) {
      let type = "select";
      if (this.multi) {
        type = "selectmulti";
      }
      const fieldAttr = {
        type: type,
        required: this.required,
        arrayValidator: this.arrayValidator,
        oneFieldAtMax: this.oneFieldAtMax
      };
      const rt = Validator.genericValidation(value, fieldAttr);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;

      if (rt.notBlocked) {
        return;
      }
      this.$emit("error", object);
    },
    checkOptionIfExist() {
      if (this.selected && this.options) {
        if (!this.options.some(x => x.value === this.selected)) {
          this.selected = null;
        }
        if (this.options.length === 2) {
          this.selected = this.options[1].value;
        }
      }
    },
    refreshErrorMethod() {
      this.setError(this.value);
    }
  },
  computed: {
    sortOptions() {
      if (!this.enableSort) {
        if (this.options) {
          return this.options;
        } else {
          return [];
        }
      }
      if (this.options) {
        return this.options.slice(0).sort(function(a, b) {
          if (!a.text) {
            return false;
          }
          return a.text.localeCompare(b.text, "en", { numeric: true });
        });
      } else {
        return [];
      }
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }

      return msg;
    }
  },
  mounted() {
    let value = this.value;
    if (this.initialValue) {
      value = this.initialValue;
    }
    if (this.multi) {
      if (value) {
        this.selected = value;
        this.checkOptionIfExist();
      }
    } else if (!this.multi) {
      if (value) {
        this.selected = value;
      }
    }
  },
  created() {
    if (this.multi) {
      this.selected = [];
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
