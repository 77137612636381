<template>
  <fieldset>
    <h4 v-if="isEdit">
      {{ $t("editTrip.lastStep.arrival") }}
      <span
        v-b-modal="'arrival-remark'"
        v-if="isEdit && arrival.closeDatetime == null"
        class="add-remark-btn"
      ></span>
      <Popup
        v-model="arrival['remark']"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="'arrival-remark'"
      />
    </h4>
    <b-overlay
      :show="arrival['closeDatetime'] !== undefined"
      :key="'arrival' + arrival['closeDatetime']"
      rounded="sm"
      blur="none"
    >
      <template #overlay>
        <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
        <p class="redMessage">{{ $t("closeMsg") }}</p>
        <p class="redMessage">
          {{ $t("closeMsgDate") }}{{ showDate(arrival["closeDatetime"]) }}
        </p>
      </template>
      <fieldset :disabled="arrival['closeDatetime'] !== undefined">
        <GenericInput
          v-if="hasLandingArrivalDate"
          v-model="arrival['datetime']"
          :label="$t('landing.datetime')"
          :required="hasLandingArrivalDate === $const.MANDATORY"
          :autodatetime="true"
          :minDates="landingArrivalDateMinimums"
          :forceErrorMsg="dateOverlapeMessage('datetime')"
          :refeshError="showErrorData"
          type="datetime"
          @error="addError"
        />
        <GenericInput
          v-if="hasLandingStartDate"
          v-model="arrival['landingStartDatetime']"
          :label="$t('landing.startDatetime')"
          :required="hasLandingStartDate === $const.MANDATORY"
          :autodatetime="true"
          :minDates="landingStartDateMinimums"
          :forceErrorMsg="dateOverlapeMessage('landingStartDatetime')"
          :refeshError="showErrorData"
          type="datetime"
          @error="addError"
        />
        <GenericInput
          v-if="hasLandingPort"
          v-model="arrival['port']"
          :label="$t('editTrip.lastStep.port')"
          :areasandports="systemsLists.areasandports"
          :required="hasLandingPort === $const.MANDATORY"
          :refeshError="showErrorData"
          type="regionsubregion"
          @error="addError"
        />
        <GenericInput
          v-if="hasLandingWharf"
          v-model="arrival['wharf']"
          :required="hasLandingWharf === $const.MANDATORY"
          :label="$t('editTrip.lastStep.wharf')"
          :maxlength="100"
          :refeshError="showErrorData"
          type="text"
          @error="addError"
        />
        <GenericInput
          v-if="hasLandingOffloadSite"
          v-model="arrival['offloadSite']"
          :label="$t('editTrip.lastStep.offloadSite')"
          :maxlength="100"
          :required="hasLandingOffloadSite === $const.MANDATORY"
          :refeshError="showErrorData"
          type="text"
          @error="addError"
        />
        <br />
        <GenericInput
          v-if="hasLandingSarIndicator"
          v-model="arrival['allSarDeclared']"
          :label="$t('editTrip.lastStep.allSarDeclared')"
          :options="systemsLists.response"
          :required="hasLandingSarIndicator === $const.MANDATORY"
          :refeshError="showErrorData"
          type="radio"
          @error="addError"
        />
      </fieldset>
      <LandingDetail
        :cargoEvents="arrival.cargoEvents"
        :showErrorDataParent="showErrorDataParent"
        :addErrorToParent="addErrorToParent"
        :subform="this.currentTrip.subform"
      />
    </b-overlay>
    <fieldset
      v-if="isEdit && arrival.closeDatetime == null"
      class="suppEntryButtonWrapper"
      style="display: block;"
    >
      <p>
        <input
          :value="$t('editTrip.lastStep.arrivalCloseButton')"
          class="closeSuppEntryButton"
          type="button"
          @click="closeArrival()"
        />
      </p>
    </fieldset>
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import LandingDetail from "./LandingDetail.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    GenericInput,
    Popup,
    LandingDetail
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    subscription: {
      type: Object,
      required: true
    },
    currentTrip: {
      type: Object,
      required: true
    },
    departureDate: String
  },
  data() {
    return {
      showErrorData: 0,
      type: "arrival",
      lastDateValue: "",
      arrival: {
        datetime: null,
        port: null,
        landingStartDatetime: null,
        closeDatetime: null,
        offloadSite: null,
        allSarDeclared: null,
        wharf: null,
        cargoEvents: []
      }
    };
  },
  computed: {
    ...mapGetters(["errorMessageDateOverlap", "showDate", "getPropertyValue"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile
    }),
    tripStartDate() {
      return this.currentTrip.departure?.datetime;
    },
    hasLandingSarIndicator() {
      return this.getPropertyValue("hasLandingSarIndicator", {
        subforms: this.currentTrip.subform
      });
    },
    hasLandingWharf() {
      return this.getPropertyValue("hasLandingWharf", {
        subforms: this.currentTrip.subform
      });
    },
    hasLandingStartDate() {
      return this.getPropertyValue("hasLandingStartDate", {
        subforms: this.currentTrip.subform
      });
    },
    // Implements rules 45, 46, and 160
    landingStartDateMinimums() {
      let minimums = [];

      // Rule 160: landing start date >= landing arrival date
      if (this.landingArrivalDate) {
        minimums.push({
          value: this.landingArrivalDate,
          text: this.$t("editTrip.afterLandingArrivalDate")
        });
      }

      //  Rule 46: landing start date >= last effort end date
      if (this.lastEffortEndDate) {
        minimums.push({
          value: this.lastEffortEndDate,
          text: this.$t("editTrip.afterLastEffortEndDate")
        });
      }

      //  Rule 45: landing start date >= trip start date
      if (this.tripStartDate) {
        minimums.push({
          value: this.tripStartDate,
          text: this.$t("editTrip.afterTripStartDate")
        });
      }

      return minimums;
    },
    hasLandingArrivalDate() {
      for (let i = 0; i < this.currentTrip.efforts.length; i++) {
        const effort = this.currentTrip.efforts[i];
        const value = this.getPropertyValue("hasLandingArrivalDate", {
          subforms: this.currentTrip.subform,
          targetSpecies: effort.target
        });
        if (value) return value;
      }
      return false;
    },
    landingArrivalDate() {
      return this.arrival.datetime;
    },
    // Implements rules 246, 442
    landingArrivalDateMinimums() {
      let minimums = [];

      // Rule 246: landing arrival date >= last effort end date
      if (this.lastEffortEndDate) {
        minimums.push({
          value: this.lastEffortEndDate,
          text: this.$t("editTrip.afterLastEffortEndDate")
        });
      }

      // Rule 442: landing arrival date >= trip start date
      if (this.tripStartDate) {
        minimums.push({
          value: this.tripStartDate,
          text: this.$t("editTrip.afterTripStartDate")
        });
      }

      return minimums;
    },
    lastEffortEndDate() {
      return this.currentTrip.efforts.at(-1)?.end;
    },
    hasLandingPort() {
      return this.getPropertyValue("hasLandingPort", {
        subforms: this.currentTrip.subform
      });
    },
    hasLandingOffloadSite() {
      return this.getPropertyValue("hasLandingOffloadSite", {
        subforms: this.currentTrip.subform
      });
    },
    dateOverlapeMessage() {
      return field => {
        return this.errorMessageDateOverlap(
          this,
          this.arrival[field],
          !this.isEdit
        );
      };
    }
  },
  watch: {
    showErrorDataParent: function() {
      this.showErrorData++;
    },
    arrival: {
      handler() {
        this.$emit("update-arrival", this.arrival);
      },
      deep: true
    },
    "currentTrip.departure": {
      handler: function() {
        this.getLastDateValue();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["closeObject", "addErrorsToState", "lastDate"]),
    async getLastDateValue() {
      const departureDateTime = this.currentTrip.departure?.datetime;
      const result = await this.lastDate({
        departureDateTime: departureDateTime,
        vm: this
      });
      this.lastDateValue = result;
    },
    addError(error) {
      this.addErrorsToState({ page: this.type, errors: error });
      this.addErrorToParent(error);
    },
    closeArrival() {
      this.showErrorData++;
      this.closeObject({
        value: this.arrival,
        type: this.type,
        index: undefined,
        vm: this
      });
    }
  },
  beforeMount() {
    if (this.currentTrip && "creation" in this.currentTrip) {
      this.arrival.datetime = this.currentTrip.arrival.datetime;
      this.arrival.port = this.currentTrip.arrival.port;
      this.arrival.wharf = this.currentTrip.arrival.wharf;
      this.arrival.closeDatetime = this.currentTrip.arrival.closeDatetime;
      this.arrival.offloadSite = this.currentTrip.arrival.offloadSite;
      this.arrival.allSarDeclared = this.currentTrip.arrival.allSarDeclared;
      if (this.isEdit) {
        this.arrival.landingStartDatetime = this.currentTrip.arrival.datetime;
      }
      if (this.currentTrip.arrival.cargoEvents?.length > 0) {
        this.arrival.cargoEvents = this.currentTrip["arrival"].cargoEvents;
        this.arrival.landingStartDatetime = this.currentTrip[
          "arrival"
        ].cargoEvents[0]["datetime"];
      }
    }
  }
};
</script>
