<template>
  <div :class="floatLeft ? 'floatLeft' : 'labelBox'">
    <span v-if="label" class="label">
      <label v-html="formattedLabel" test-id="label" />
    </span>
    <span :class="margin ? 'margin' : ''" class="uvalue" test-id="value">
      {{ msg + extra }}
    </span>
  </div>
</template>
<script>
export default {
  name: "LabelView",
  props: {
    type: {
      type: String,
      required: false,
      validator: function(value) {
        return ["text", "select", "multiselect", "regionsubregion"].includes(
          value
        );
      }
    },
    margin: {
      type: Boolean,
      required: false
    },
    label: {
      required: false
    },
    floatLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      // type: [String, Number, Array, Boolean],
      required: true,
      default: "" //In the case that the value is not initiated in time.
    },
    extra: {
      type: String,
      required: false,
      default: ""
    },
    options: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    areasandports: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    msg() {
      if (this.type === "select") {
        return this.setText(this.value);
      } else if (this.type === "regionsubregion") {
        return this.setPort();
      } else if (this.type === "multiselect") {
        return this.setMulti();
      } else {
        return this.value;
      }
    },
    formattedLabel() {
      let txt = this.label.toString();
      if (this.floatLeft) {
        txt += this.$t("labelSuffix");
      }
      return txt;
    }
  },
  methods: {
    setText(value) {
      let option = this.options.find(option => option.value === value);
      return option ? option.text : value;
    },
    setPort() {
      const allArresAndPorts = this.areasandports;
      for (let currentArea in this.areasandports) {
        for (let currentSubRegion in allArresAndPorts[currentArea].subregions) {
          for (let currentPorts in allArresAndPorts[currentArea].subregions[
            currentSubRegion
          ].ports) {
            if (
              allArresAndPorts[currentArea].subregions[currentSubRegion].ports[
                currentPorts
              ].id == this.value
            ) {
              return allArresAndPorts[currentArea].subregions[currentSubRegion]
                .ports[currentPorts].desc;
            }
          }
        }
      }
      return this.value;
    },
    setMulti() {
      let array = [];
      if (Array.isArray(this.value)) {
        for (let value of this.value) {
          array.push(this.setText(value));
        }
      }
      return array.join(", ");
    }
  }
};
</script>
<style lang="scss" scoped>
.labelBox {
  padding: 3% 0;
  display: table-cell;
  height: 32px;
  width: 33.33%;
  margin-bottom: 10px;
  text-align: left;
  span {
    display: block;
  }

  label {
    display: inherit;
    color: $lightColor;
  }

  input,
  select {
    display: inline;
    border: none;
    height: 32px;
    font-size: 1em;
    line-height: 1em;
    width: auto;
    background: white;

    color: rgba(48, 48, 48, 1);
    -webkit-text-fill-color: rgba(48, 48, 48, 1);

    opacity: 1;
    -webkit-opacity: 1;
  }
}

.margin {
  margin-right: 10px;
}

.floatLeft {
  display: table-cell;
  width: 33.33%;
  text-align: left;
  padding: 0 1em 10px;
  label {
    color: $lightColor;
  }
}

.uvalue {
  word-break: break-word;
  font-weight: bold;
  font-size: 1em;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
