<template>
  <fieldset>
    <h4 class="sectionTitle">
      <span
        v-b-modal="'suppTransportEntry-remark'"
        class="add-remark-btn-title"
      ></span>
      {{ $t("editTrip.lastStep.carrierTitle") }}
    </h4>
    <Popup
      v-model="form['remark']"
      :title-label="$t('editTrip.remarkTitle')"
      :maxlength="2000"
      :modalName="'suppTransportEntry-remark'"
    />
    <fieldset>
      <GenericInput
        :label="$t('editTrip.lastStep.carrierName')"
        v-model="form['vessel'].name"
        type="text"
        :maxlength="50"
        @error="addError"
        :refeshError="showErrorDataParent"
        key="carrierName"
      />
      <GenericInput
        :label="$t('editTrip.lastStep.carrier')"
        v-model="form['vessel'].registeredNumber"
        type="text"
        :required="true"
        :minlength="4"
        :min="1000"
        :maxlength="$const.VRN_MAX_LENGTH"
        :digits="true"
        @error="addError"
        :refeshError="showErrorDataParent"
        key="carrier"
      />
      <GenericInput
        :label="$t('editTrip.lastStep.pondNumber')"
        :help="$t('editTrip.lastStep.pondNumberHelp')"
        v-model="form['pondNumber']"
        type="text"
        :maxlength="30"
        :required="true"
        @error="addError"
        :refeshError="showErrorDataParent"
      />
      <GenericInput
        :help="$t('editTrip.lastStep.pondNumberHelp')"
        :label="$t('editTrip.lastStep.isLoad')"
        v-model="form['isLoad']"
        :options="$t('productDestination.items')"
        :required="true"
        type="radio"
        @error="addError"
        :refeshError="showErrorDataParent"
      />
      <br />
      <!-- TODO: Déplacer les autres éléments de 'carrier' dans sa propre section du fichier JSON. -->
      <GenericInput
        :label="$t('carrier.datetime')"
        v-model="form['datetime']"
        type="datetime"
        :required="true"
        :autodatetime="true"
        :passDate="true"
        :minDates="[
          {
            value: departureDate,
            text: $t('editTrip.beforeDepartureDate')
          }
        ]"
        @error="addError"
        :refeshError="showErrorDataParent"
      />
      <br />
      <UnitConverter
        :label="$t('editTrip.lastStep.weight')"
        v-model="form.cargos[0]['weight']"
        :required="true"
        :min="1"
        :max="999999"
        unit="weight"
        @error="addError"
        :refresh="showErrorDataParent"
      />
      <GenericInput
        :label="$t('editTrip.remarkTitle')"
        v-model="form.cargos[0]['remark']"
        type="text"
        :maxlength="2000"
        @error="addError"
        :refeshError="showErrorDataParent"
      />
      <br />
      <LabelView
        class="labelViewLeftMargin"
        :label="$t('editTrip.lastStep.specie')"
        type="select"
        :floatLeft="true"
        :value="form.cargos[0]['species']"
        :options="systemsLists.species"
      />
      <br />
      <LabelView
        class="labelViewLeftMargin"
        :label="$t('editTrip.lastStep.productForm')"
        type="select"
        :floatLeft="true"
        :value="form.cargos[0]['productForm']"
        :options="systemsLists.productForm"
      />
    </fieldset>
  </fieldset>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    GenericInput,
    Popup,
    LabelView,
    UnitConverter
  },
  props: {
    transport: {
      type: Object,
      required: true
    },
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: this.transport
    };
  },
  computed: {
    ...mapGetters(["errorMessageDateOverlap"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      openTrip: state => state.currentOpenTrip
    }),
    departureDate() {
      return this.openTrip.departure?.datetime ?? "";
    }
  },
  watch: {
    transport: {
      handler: function(val) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("carrier: -> carrier watch", this.transport); // eslint-disable-line no-console
        for (let key in this.transport) {
          const value = this.transport[key];
          if (this.form[key] != value) this.form[key] = value;
        }
      },
      deep: true
    },
    form: {
      handler: function(val) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("carrier: <- form watch", val); // eslint-disable-line no-console
        this.$emit("update", val);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addError(error) {
      // this.addErrorsToState({ page: "carrierStep", errors: error });
      this.addErrorsToState({
        page: `transportStep`,
        errors: error
      });
      this.addErrorToParent(error);
    }
  }
};
</script>
