<template>
  <div>
    <h4 v-if="localBaitUssage && localBaitUssage.length > 0" class="darkTitle">
      <span>
        <span>
          {{ $t("editTrip.baitStep.title") }}
        </span>
      </span>
    </h4>
    <div
      v-for="(bait, index) in baitUsages"
      :key="'bait-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="listItemTitle startContext subTitle">
            {{ $t("editTrip.baitStep.bait") }}&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="bait['datetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.datetime')"
                :value="$showDate(bait['datetime'])"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="bait['type']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.type')"
                :value="bait['type']"
                :options="systemsLists.baitType"
                type="select"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12" v-if="bait['weight']">
              <UnitConverter
                :label="$t('editTrip.baitStep.weight')"
                :value="bait['weight']"
                unit="weight"
                viewOnly
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="bait['condition']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.condition')"
                :value="bait['condition']"
                :options="systemsLists.baitCondition"
                type="select"
              />
            </b-col>
            <b-col v-if="bait['origin']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.origin')"
                :value="bait['origin']"
                :options="$t('baitsOrigin.items')"
                type="select"
              />
            </b-col>
            <b-col v-if="bait['averageWeightPerGear']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.baitStep.averageWeightPerGear')"
                :value="bait['averageWeightPerGear']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="bait['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="bait['remark']"
                type="text"
              />
            </b-col>
            <b-col v-if="bait['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(bait['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "ViewBaitStep",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    baitUsages: Array
  },
  data: () => ({
    localBaitUssage: []
  }),
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile //utilisé dans $showdate, car $showdate utilise le context de celui qui l'appelle.
    })
  },
  watch: {
    systemsLists() {
      this.$router.go();
    }
  },
  mounted() {
    this.localBaitUssage = this.baitUsages;
    if (
      this.baitUsages[0].category != undefined ||
      this.baitUsages[0].category != null
    ) {
      for (let baitIndex in this.baitUsages) {
        for (let itemIndex in this.baitUsages[baitIndex].category) {
          this.localBaitUssage[baitIndex][itemIndex] = this.baitUsages[
            baitIndex
          ].category[itemIndex];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.tripContent {
  padding: 1.2%;
  background-color: #fff;
}

.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}
.errorMsg {
  text-align: center;
  color: red;
}

.dfo-complete {
  color: green;
}

.uvalue {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
