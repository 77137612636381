<template>
  <div>
    <h4 class="darkTitle">
      {{ $t("editTrip.effortStep.title") }}
    </h4>
    <div>
      <div
        v-for="(effort, index) in currentTrip.efforts"
        :key="'effort-' + index"
      >
        <h4 class="startContext">
          {{ $t("editTrip.effortStep.effort") }}&nbsp;{{ index + 1 }}
        </h4>
        <fieldset>
          <h4>
            {{ $t("editTrip.effortStep.fishingInfos") }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.licenceNumber')"
                :value="effort['licenceNumber']"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.target')"
                :value="effort['target']"
                :options="systemsLists.species"
                type="select"
              />
            </b-col>
            <b-col
              v-if="effort['targetOtherDescription']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('fields.targetOtherDescription')"
                :value="effort['targetOtherDescription']"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                v-if="effort['area']"
                :label="$t('fields.area')"
                :value="effort['area']"
                :options="systemsLists.areas"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['nbGearMax']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbGearMax')"
                :value="effort['nbGearMax']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['fishingType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.fishingType')"
                :value="effort['fishingType']"
                :options="systemsLists.fishingType"
                type="select"
              />
            </b-col>
            <b-col v-if="effort.ssSection" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.ssSection')"
                :value="effort.ssSection"
                :options="systemsLists.ssSections"
                type="select"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset v-if="effort.start || effort.startPosition">
          <h4>
            {{ $t("editTrip.effortStep.beginningOfEffort") }}
          </h4>
          <b-row v-if="effort.start">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.start')"
                :value="$showDate(effort.start)"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="effort.startPosition && effort.startPosition['latitude']"
          >
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(effort.startPosition['latitude'], 'lat') +
                    ' = ' +
                    effort.startPosition['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(effort.startPosition['longitude'], 'lon') +
                    ' = ' +
                    effort.startPosition['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="effort.startPosition['nafo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('nafo')"
                :value="effort.startPosition['nafo']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort.startPosition['grid']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('grid')"
                :value="effort.startPosition['grid']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="effort.parea" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.parea')"
                :value="effort.parea"
                :options="pacAreas(effort)"
                type="select"
              />
            </b-col>
            <b-col v-if="effort.setsCount" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.setsCount')"
                :value="effort.setsCount"
                type="text"
              />
            </b-col>
            <b-col v-if="effort.licenceNumber2" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.licenceNumber2')"
                :value="effort.licenceNumber2"
                type="text"
              />
            </b-col>
            <b-col v-if="effort.sonarStart" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.sonarStart')"
                :value="$showDate(effort.sonarStart)"
                type="text"
              />
            </b-col>
            <b-col v-if="effort.waterbody" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.waterbody')"
                :value="effort.waterbody"
                :options="waterbodies"
                type="select"
              />
            </b-col>
            <b-col v-if="effort.licenceSubtype" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.licenceSubtype')"
                :value="effort.licenceSubtype"
                :options="systemsLists.licenceSubtype"
                type="select"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset>
          <b-row v-if="effort['remark']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="effort['remark']"
                type="text"
              />
            </b-col>
          </b-row>
          <h4>
            {{ $t("editTrip.effortStep.fishingGears") }}
          </h4>
          <b-row>
            <b-col v-if="effort['gears']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.gears')"
                :value="effort['gears']"
                :options="systemsLists.gearType"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['subType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.subType')"
                :value="effort['subType']"
                :options="systemsLists.gearSubTypes"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['length']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.length')"
                :value="effort['length']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['meshSize']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.meshSize')"
                :value="effort['meshSize']"
                unit="mm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['meshType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.meshType')"
                :value="effort['meshType']"
                :options="systemsLists.meshTypes"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['monofilamentGauge']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.monofilamentGauge')"
                :value="effort['monofilamentGauge']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['gangesSpacing']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.gangesSpacing')"
                :value="effort['gangesSpacing']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['gangionLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.gangionLength')"
                :value="effort['gangionLength']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['trawlConfig']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.trawlConfig')"
                :value="effort['trawlConfig']"
                :options="systemsLists.trawlConfigs"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['trapSize']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.trapSize')"
                :value="effort['trapSize']"
                :options="systemsLists.trapSizes"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['trapSizeNFL']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.trapSizeNFL')"
                :value="effort['trapSizeNFL']"
                :options="systemsLists.trapSizes"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['hookSize']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hookSize')"
                :value="effort['hookSize']"
                :options="systemsLists.hookSize"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['hookType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hookType')"
                :value="effort['hookType']"
                :options="systemsLists.hookType"
                type="select"
              />
            </b-col>
            <b-col v-if="effort['height']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.height')"
                :value="effort['height']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['diameter']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.diameter')"
                :value="effort['diameter']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['spacing']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.spacing')"
                :value="effort['spacing']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['otherGear']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.otherGear')"
                :value="effort['otherGear']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['hookGape']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.hookGape')"
                :value="effort['hookGape']"
                unit="mm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['hooksPerSkate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.hooksPerSkate')"
                :value="effort['hooksPerSkate']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['model']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.model')"
                :value="effort['model']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['size']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.size')"
                :value="effort['size']"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['escapeConfig']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.escapeConfig')"
                :value="effort['escapeConfig']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['escapeDiameter']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.escapeDiameter')"
                :value="effort['escapeDiameter']"
                unit="mm"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['escapeCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.escapeCount')"
                :value="effort['escapeCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="effort['selectivityDevice']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.selectivityDevice')"
                :value="effort['selectivityDevice']"
                type="select"
                :options="systemsLists.gearSelectivityDevices"
              />
            </b-col>
            <b-col v-if="effort['headropeLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.headropeLength')"
                :value="effort['headropeLength']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['footropeLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.footropeLength')"
                :value="effort['footropeLength']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['riseNet']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.riseNet')"
                :value="effort['riseNet']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="effort['gearNameNum']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.gearNameNum')"
                :value="effort['gearNameNum']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row v-if="effort['fishingGearsRemark']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="effort['fishingGearsRemark']"
                type="text"
              />
            </b-col>
          </b-row>
          <div
            v-if="
              effort['fishingMethods'] && effort['fishingMethods'].length > 0
            "
          >
            <h4>{{ $t("editTrip.effortStep.fishingMethods") }}</h4>
            <div
              v-for="(method, index2) in effort['fishingMethods']"
              :key="'method-' + index2"
            >
              <b-row>
                <b-col lg="4" sm="6" cols="12">
                  <LabelView
                    :label="
                      $t('editTrip.effortStep.fishingMethod') +
                        ' ' +
                        (index2 + 1)
                    "
                    :value="method"
                    :options="systemsLists.fishingMethod"
                    type="select"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            v-if="effort['baitFasteners'] && effort['baitFasteners'].length > 0"
          >
            <h4>{{ $t("editTrip.effortStep.baitFasteners") }}</h4>
            <div
              v-for="(baitFastener, index2) in effort['baitFasteners']"
              :key="'baitFastener-' + index2"
            >
              <b-row>
                <b-col lg="4" sm="6" cols="12">
                  <LabelView
                    :label="
                      $t('editTrip.effortStep.baitFastener') +
                        ' ' +
                        (index2 + 1)
                    "
                    :value="baitFastener"
                    :options="systemsLists.baitFastener"
                    type="select"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <TowViewer
            :tows="effort.tows"
            :area="effort.area"
            :target="effort.target"
          />
          <h4 v-if="effort.speciesAtRisk && effort.speciesAtRisk.length > 0">
            {{ $t("editTrip.speciesAtRiskStep.title") }}
          </h4>
          <fieldset
            v-for="(sar, index2) in effort.speciesAtRisk"
            :key="'sar-' + index2"
          >
            <h4 v-if="effort.speciesAtRisk.length > 1">
              {{
                $t("editTrip.speciesAtRiskStep.sarInteraction") +
                  " " +
                  (index2 + 1)
              }}
            </h4>
            <b-row v-if="sar['occursAt']['datetime']">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.speciesAtRiskStep.datetime')"
                  :value="sar['occursAt']['datetime']"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="sar['occursAt']['latitude']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(sar['occursAt']['latitude'], 'lat') +
                      ' = ' +
                      sar['occursAt']['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                v-if="sar['occursAt']['longitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(sar['occursAt']['longitude'], 'lon') +
                      ' = ' +
                      sar['occursAt']['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="sar['species']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.speciesAtRiskStep.species')"
                  :value="sar['species']"
                  :options="systemsLists.species"
                  type="select"
                />
              </b-col>
              <b-col v-if="sar['specimenCondition']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.speciesAtRiskStep.specimenCondition')"
                  :value="sar['specimenCondition']"
                  :options="systemsLists.sarCondition"
                  type="select"
                />
              </b-col>
              <b-col v-if="sar['nbSpecimen']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.speciesAtRiskStep.nbSpecimen')"
                  :value="sar['nbSpecimen']"
                  type="text"
                />
              </b-col>
              <b-col v-if="sar['weight']" lg="4" sm="6" cols="12">
                <UnitConverter
                  :label="$t('editTrip.speciesAtRiskStep.weight')"
                  :value="sar['weight']"
                  unit="weight"
                  viewOnly
                />
              </b-col>
            </b-row>
            <b-row v-if="sar['remark']">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="sar['remark']"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
          <div v-if="effort.farGearPosition">
            <h4>
              {{ $t("editTrip.effortStep.farGear") }}
            </h4>
            <b-row v-if="effort.farGearPosition">
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.farGearPosition['latitude']"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(effort.farGearPosition['latitude'], 'lat') +
                      ' = ' +
                      effort.farGearPosition['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.farGearPosition['longitude']"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(
                      effort.farGearPosition['longitude'],
                      'lon'
                    ) +
                      ' = ' +
                      effort.farGearPosition['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.farGearPosition['nafo']"
              >
                <LabelView
                  :label="$t('nafo')"
                  :value="effort.farGearPosition['nafo']"
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.farGearPosition['grid']"
              >
                <LabelView
                  :label="$t('grid')"
                  :value="effort.farGearPosition['grid']"
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.farGearPosition['lgrid']"
              >
                <LabelView
                  :label="$t('lgrid')"
                  :value="effort.farGearPosition['lgrid']"
                  :options="systemsLists.lGrids"
                  type="select"
                />
              </b-col>
            </b-row>
          </div>
          <h4 v-if="effort.samples && effort.samples.length > 0">
            {{ $t("editTrip.effortStep.sampleEffort") }}
          </h4>
          <fieldset
            v-for="(sample, sampleIndex) in effort.samples"
            :key="sampleIndex"
          >
            <h4>
              {{ $t("editTrip.effortStep.sampleEffort") }} {{ sampleIndex + 1 }}
            </h4>
            <b-row>
              <b-col lg="4" sm="6" cols="12" v-if="sample['remark']">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="sample['remark']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="sample['species']">
                <LabelView
                  :label="$t('editTrip.effortStep.specie')"
                  :value="sample['species']"
                  :options="systemsLists.species"
                  type="select"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="sample['length']">
                <UnitConverter
                  :label="$t('editTrip.effortStep.effortSampleSpecimenLength')"
                  :value="sample['length']"
                  unit="cm"
                  viewOnly
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="sample['number']">
                <LabelView
                  :label="$t('editTrip.effortStep.effortSampleSpecimenNumber')"
                  :value="sample['number']"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
          <div v-if="effort.endPosition || effort.end">
            <h4>
              {{ $t("editTrip.effortStep.endEffort") }}
            </h4>
            <b-row v-if="effort.end">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.end')"
                  :value="$showDate(effort.end)"
                  type="text"
                />
              </b-col>
              <b-col v-if="effort['fishingDays']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.nbFishingDays')"
                  :value="effort['fishingDays']"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row v-if="effort.endPosition">
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.endPosition['latitude']"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(effort.endPosition['latitude'], 'lat') +
                      ' = ' +
                      effort.endPosition['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="effort.endPosition['longitude']"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(effort.endPosition['longitude'], 'lon') +
                      ' = ' +
                      effort.endPosition['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="effort.endPosition['nafo']">
                <LabelView
                  :label="$t('nafo')"
                  :value="effort.endPosition['nafo']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="effort.endPosition['grid']">
                <LabelView
                  :label="$t('grid')"
                  :value="effort.endPosition['grid']"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="effort.endPosition['lgrid']">
                <LabelView
                  :label="$t('lgrid')"
                  :value="effort.endPosition['lgrid']"
                  :options="systemsLists.lGrids"
                  type="select"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="effort['confirmationSar']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.hadSAR')"
                  :value="effort['confirmationSar']"
                  :options="systemsLists.response"
                  type="select"
                />
              </b-col>
              <b-col v-if="effort['confirmationMmi']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.hadMammal')"
                  :value="effort['confirmationMmi']"
                  :options="systemsLists.response"
                  type="select"
                />
              </b-col>
              <b-col v-if="effort['confirmationGear']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.hadGearLoss')"
                  :value="effort['confirmationGear']"
                  :options="systemsLists.response"
                  type="select"
                />
              </b-col>
              <b-col
                v-if="effort['spotterPlaneDuration']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.effortStep.spotterPlaneDuration')"
                  :value="effort['spotterPlaneDuration']"
                  type="text"
                />
              </b-col>
              <b-col v-if="effort['zonesSearch']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.zonesSearch')"
                  :value="effort['zonesSearch']"
                  type="text"
                />
              </b-col>
              <b-col v-if="currentTrip.fishingDays" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.fishingDays')"
                  :value="currentTrip.fishingDays"
                  type="text"
                />
              </b-col>
              <b-col v-if="currentTrip.seasonYear" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.lastStep.seasonYear')"
                  :value="currentTrip.seasonYear"
                  type="text"
                />
              </b-col>
              <b-col
                v-if="currentTrip.functionalityTestCert"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.functionalityTestCert')"
                  :value="currentTrip.functionalityTestCert"
                  type="text"
                />
              </b-col>
              <b-col
                v-if="currentTrip.functionalityTestDateTime"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.lastStep.functionalityTestDateTime')"
                  :value="$showDate(currentTrip.functionalityTestDateTime)"
                  type="text"
                />
              </b-col>
              <b-col v-if="effort.sonarEnd" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.sonarEnd')"
                  :value="$showDate(effort.sonarEnd)"
                  type="text"
                />
              </b-col>
              <b-col v-if="effort['closeDatetime']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('dataGroupClosureDate')"
                  :value="$showDate(effort['closeDatetime'])"
                  type="text"
                />
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import TowViewer from "@/components/viewtrip/TowViewer.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "EffortsViewer",
  components: {
    LabelView,
    TowViewer,
    UnitConverter
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile // parce que $showdate utilise le contexte de l'appelant
    }),
    // TODO: Should be a method
    pacAreas() {
      return function(effort) {
        return this.getPropertyValue("pacAreas", {
          areas: effort.area
        });
      };
    },
    waterbodies() {
      return this.getPropertyValue("waterbodies", {
        subforms: this.currentTrip.subform
      });
    }
  },
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}
</style>
