import i18n from "@/i18n.js";

export function formatTimeIntervalInMinutes(interval) {
  const minutesPerDay = 24 * 60;
  let parts = [];
  let durationLeft = interval;

  if (durationLeft >= minutesPerDay) {
    const days = Math.floor(interval / minutesPerDay);
    parts.push(i18n.tc("unitsOfTime.day", days));
    durationLeft = durationLeft % minutesPerDay;
  }
  if (durationLeft >= 60) {
    const hours = Math.floor((interval % minutesPerDay) / 60);
    parts.push(i18n.tc("unitsOfTime.hour", hours));
    durationLeft = durationLeft % 60;
  }
  if (durationLeft > 0) {
    const minutes = Math.floor(interval % 60);
    parts.push(i18n.tc("unitsOfTime.minute", minutes));
  }

  return parts.join(" ");
}

export function compareObjects(obj1, obj2) {
  const obj1Json = JSON.stringify(obj1);
  const obj2Json = JSON.stringify(obj2);
  return obj1Json === obj2Json;
}

export function keysMatchingValue(objectData, valueToCheck, key = undefined) {
  if (objectData == null) {
    return [];
  }
  if (key && !(key in objectData)) {
    return [];
  }

  const dataToCheck = key ? objectData[key] : objectData;
  let fields = [];
  Object.keys(dataToCheck).forEach(fieldName => {
    if (dataToCheck[fieldName] === valueToCheck) {
      fields.push(fieldName);
    }
  });
  return fields;
}

export function scrollToTopField(fieldsNames) {
  var top = undefined;
  var topElementName = undefined;

  fieldsNames.forEach(fieldName => {
    var list = document.getElementsByName(fieldName);
    if (list.length > 0) {
      var y = list[0].getBoundingClientRect().y;
      if (!top || y < top) {
        top = y;
        topElementName = fieldName;
      }
    }
  });
  if (!topElementName) return;
  let element = document.getElementsByName(topElementName);
  if (element.length > 0) {
    element[0].parentElement.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center"
    });
  }
}

export function clearFieldsFromObject(object, fields) {
  fields.forEach(field => {
    delete object[field];
  });
}

export function createEffort(preferences, licence) {
  return {
    subType: preferences.subType,
    trapSize: preferences.trapSize,
    gears: preferences.gears,
    meshSize: preferences.meshSize,
    meshType: preferences.meshType,

    licenceNumber: licence.fishingLicenceNumber,
    target: licence.target,
    targetOtherDescription: licence.targetOtherDescription,
    area: licence.area,
    fishingType: licence.fishingType,
    nbGearMax: licence.nbGear,
    ssSection: licence.section,
    secteur: licence.secteur,
    endPosition: { lgrid: licence.lgrid },
    startPosition: { lgrid: licence.lgrid },

    tows: [],
    samples: [],
    fishingMethods: [],
    baitFasteners: [],
    end: null,
    start: null,
    remark: null
  };
}

export function emptyGearInfo() {
  return {
    nbGear: null,
    frameMaterial: null,
    remark: null,
    bottomDiameter: null,
    width: null,
    height: null,
    meshMaterial: null,
    escapeDiameter: null,
    gearType: null,
    nbTunnels: null,
    topDiameter: null,
    length: null,
    trapSubType: null
  };
}
