<template>
  <LabelView
    v-if="viewOnly"
    :label="$t('fields.towSelected')"
    :value="selectedTowGuid"
    :options="towOptions"
    type="select"
  />
  <Select
    v-else
    v-model="selectedTowGuid"
    :label="$t('fields.towSelector')"
    :options="towOptions"
    :required="required"
    :refeshError="refeshError"
    :enableSort="false"
    @error="checkError"
  />
</template>
<script>
import Select from "@/components/Select.vue";
import LabelView from "@/components/LabelView.vue";
export default {
  name: "TowSelector",
  components: {
    Select,
    LabelView
  },
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    refeshError: {
      default: null
    },
    efforts: {
      default: null
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "towSelector #" + (Math.random() * 100000).toFixed(0),
      selectedTowGuid: null
    };
  },
  computed: {
    defaultTowOption() {
      return {
        value: null,
        text:
          this.$t("editTrip.effortStep.effort") +
          " | " +
          this.$t("editTrip.effortStep.tow") +
          " | " +
          this.$t("catch.keptWeight"),
        disabled: this.required
      };
    },
    towOptions() {
      let options = [this.defaultTowOption];
      let effortIndex = 1;
      for (const effort of this.efforts) {
        let towIndex = 1;
        const target = effort.target;
        for (const tow of effort.tows) {
          let targetCatch = tow.catches.find(x => x.species === target);
          if (targetCatch) {
            const towOption = {
              value: tow.towNumIdx ?? tow.towNumber,
              text:
                effortIndex +
                " | " +
                towIndex +
                " | " +
                targetCatch.keptWeight +
                " kg",
              disabled: false
            };
            options.push(towOption);
          }
          ++towIndex;
        }
        ++effortIndex;
      }
      return options;
    }
  },
  watch: {
    selectedTowGuid() {
      this.$emit("input", this.selectedTowGuid);
    }
  },
  mounted() {
    this.selectedTowGuid = this.value;
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  },
  methods: {
    checkError(value) {
      this.$emit("error", value);
    }
  }
};
</script>
