<template>
  <div>
    <Carrier
      v-if="hasTransport"
      :transport="form.transport"
      :addErrorToParent="addErrorToParent"
      :showErrorDataParent="showErrorDataParent"
      @update="updateParentTransport"
    />
    <h4 class="startContext sectionTitle">
      {{ $t("editTrip.lastStep.transfers") }}
    </h4>
    <Transfer
      v-for="(transfer, index) in form.transfers"
      :key="'transfer-' + index"
      :transfer="transfer"
      :index="index"
      :addErrorToParent="addErrorToParent"
      :showErrorDataParent="showErrorData + refreshIndex[index]"
      @update="
        transfer => {
          updateTransfer(transfer, index);
        }
      "
      @updatePattern="update"
      @close="closeTransfer"
    />
    <fieldset class="suppEntryButtonWrapper">
      <p>
        <input
          v-if="
            form.transfers?.length > 0 && !$isLastItemClosed(form.transfers)
          "
          @click="removeTransfer"
          class="removeSuppEntryButton"
          type="button"
          :value="$t('editTrip.lastStep.removeTransfer')"
        />
        <input
          @click="addTransfer"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.lastStep.addTransfer')"
        />
      </p>
    </fieldset>
  </div>
</template>

<script>
import Vue from "vue";

import { mapActions, mapGetters, mapState } from "vuex";
import Transfer from "@/components/subformEditTrip/EffortStepComponents/LastStep/Transfer.vue";
import Carrier from "@/components/subformEditTrip/EffortStepComponents/LastStep/Carrier.vue";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";

export default {
  name: "Transfers",
  components: {
    Transfer,
    Carrier
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showErrorData: 0,
      useCarrier: false,
      form: {
        transfers: null,
        transport: null
      },
      refreshIndex: []
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "errorMessageDateOverlap"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      openTrip: state => state.currentOpenTrip,
      currentPage: state => state.editTripSubform.currentPage,
      subscription: state => state.currentSubscription,
      stateErrors: state => state.editTripSubform.errors
    }),
    hasTransport() {
      return this.form.transport != null;
    },
    hasLandingVrn() {
      return this.getPropertyValue("hasLandingVrn", { subforms: this.subform });
    },
    hasTransferFromVrn() {
      return this.getPropertyValue("hasTransferFromVrn", {
        subforms: this.subform
      });
    },
    hasTransferFromVname() {
      return this.getPropertyValue("hasTransferFromVname", {
        subforms: this.subform
      });
    },
    hasTransferToVrn() {
      return this.getPropertyValue("hasTransferToVrn", {
        subforms: this.subform
      });
    },
    hasTransferToVname() {
      return this.getPropertyValue("hasTransferToVname", {
        subforms: this.subform
      });
    }
  },
  watch: {
    "openTrip.useCarrier": function() {
      this.useCarrier = this.openTrip.useCarrier == this.$const.YES;
      if (!this.useCarrier) {
        if (this.form.transport != null) {
          this.form.transport = null;
        }
      } else {
        // this.autoCreateTransfers(); // only on last page
      }
    },
    "form.transfers": {
      handler: function() {
        this.$emit("update-transfers", this.form.transfers);
      },
      deep: true
    },
    "form.transport": {
      handler: function() {
        this.$emit("update-transport", this.form.transport);
      },
      deep: true
    },
    showErrorDataParent: function() {
      this.showErrorData++;
    },
    currentPage() {
      if (this.currentPage == "lastStep") {
        this.autoCreateTransfers();
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    update() {
      this.$emit("update");
    },
    updateTransfer(transfer, index) {
      let hasChange = 0;
      for (let key in transfer) {
        const value = transfer[key];
        if (this.form.transfers[index][key] != value) {
          hasChange++;
          this.form.transfers[index][key] = value;
        }
      }

      if (process.env.VUE_APP_DEBUG_TRANSFER) {
        // eslint-disable-next-line no-console
        console.debug(
          "transfers: updateTransfers <-",
          index,
          hasChange,
          transfer
        );
      }

      if (hasChange > 0) {
        this.updateParentTransfers(this.form.transfers);
      }
    },
    updateTransport(transport) {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: updateTransport <-", transport); // eslint-disable-line no-console
      this.form.transport = JSON.parse(JSON.stringify(transport));
      this.updateParentTransport(this.form.transport);
    },
    updateParentTransport(transport) {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: <- updateParentTransport", transport); // eslint-disable-line no-console
      this.$emit("update-transport", transport);
    },
    updateParentTransfers(transfers) {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: <- updateParentTransfers", transfers); // eslint-disable-line no-console
      this.$emit("update-transfers", transfers);
    },
    closeTransfer(index) {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: ! closeTransfer", transfers); // eslint-disable-line no-console
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      this.closeObject({
        value: this.form.transfers,
        type: `transferStep-${index}`,
        index: index,
        vm: this
      });
    },
    async addTransfer() {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: ! addTransfer"); // eslint-disable-line no-console
      this.showErrorData++;
      await this.$nextTick();
      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `transferStep-${this.form.transfers.length - 1}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }
      this.form.transfers.push(this.getEmptyTransfer());
      this.refreshIndex.push(0);
      this.updateParentTransfers(this.form.transfers);
    },
    removeTransfer() {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfers: ! removeTransfer"); // eslint-disable-line no-console
      this.form.transfers.pop();
      this.refreshIndex.pop();
      this.updateParentTransfers(this.form.transfers);
    },
    autoCreateTransfers() {
      if (
        this.form.transfers == null &&
        (this.subscription.preferences.hasTransfer === this.$const.YES ||
          this.useCarrier)
      ) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("transfers: ! autoCreateTransfers"); // eslint-disable-line no-console
        this.form.transfers = [this.getEmptyTransfer()];
        this.refreshIndex.push(0);
      } else if (this.form.transfers == null) {
        // no user pref but should allow for user to add
        this.form.transfers = [];
      }

      // transport is a transfer
      if (this.useCarrier && this.form.transport == null) {
        if (this.hasLandingVrn) {
          if (process.env.VUE_APP_DEBUG_TRANSFER)
            console.debug("transfers: ! autoCreateTransport"); // eslint-disable-line no-console
          this.form.transport = this.getEmptyTransport();
        }
      } else if (!this.useCarrier && this.form.transport != null) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("transfers: ! cleanedTransport"); // eslint-disable-line no-console
        this.form.transport = null;
      }
    },
    getEmptyTransport() {
      let transport = {
        vessel: {
          registeredNumber: null,
          name: null
        },
        vesselTo: {
          registeredNumber: null,
          name: null
        },
        vesselFrom: {
          registeredNumber: null,
          name: null
        },
        pondNumber: null,
        // TODO: This cargo model goes into Cargo.js or TransferDetail.js
        cargos: [
          {
            species: this.subscription.preferences.licences[0].target,
            productForm: this.$const.FORM_ROUND, // @TODO: hardcoded productForm
            weight: null,
            mortalityWeight: null,
            towNumIdx: null,
            logbookNumber: null,
            remark: null
          }
        ],
        isLoad: null,
        datetime: null,
        remark: null
      };

      transport.isLoad = true;
      if (this.subscription.preferences.hasOwnProperty("carrier")) {
        const carrier = this.subscription.preferences.carrier;
        transport.vessel.name = carrier.name;
        transport.vessel.registeredNumber = carrier.registeredNumber;
      }
      return transport;
    },
    getEmptyTransfer() {
      let transfer;
      transfer = {
        vessel: {
          registeredNumber: null,
          name: null
        },
        vesselTo: {
          registeredNumber: null,
          name: null
        },
        vesselFrom: {
          registeredNumber: null,
          name: null
        },
        pondNumber: null,
        // TODO: There is a similar definition of a cargo in getEmptyTransport
        cargos: [
          {
            species: this.subscription.preferences.licences[0].target,
            productForm: this.$const.FORM_ROUND, // @TODO: hardcoded productForm
            weight: null,
            remark: null
          }
        ],
        isLoad: null,
        datetime: null,
        remark: null
      };
      if (
        this.subscription &&
        this.subscription.preferences &&
        this.subscription.preferences.vessel
      ) {
        const vessel = this.subscription.preferences.vessel;
        const vesselFromTrip = this.openTrip.vessel;
        const carrier = this.subscription.preferences.carrier;

        if (this.hasTransferFromVrn) {
          transfer.vesselFrom.registeredNumber = vessel.registeredNumber;
          if (vesselFromTrip?.registeredNumber) {
            transfer.vesselFrom.registeredNumber =
              vesselFromTrip.registeredNumber;
          }
        }
        if (this.hasTransferFromVname) {
          transfer.vesselFrom.name = vessel.name;
          if (vesselFromTrip?.name) {
            transfer.vesselFrom.name = vesselFromTrip.name;
          }
        }
        if (this.hasTransferToVrn && carrier?.registeredNumber)
          transfer.vesselTo.registeredNumber = carrier.registeredNumber;
        if (this.hasTransferToVname && carrier?.name)
          transfer.vesselTo.name = carrier.name;
      }

      return transfer;
    }
  },
  mounted() {
    this.useCarrier = this.openTrip.useCarrier == this.$const.YES;

    if (this.openTrip.transfers?.length > 0) {
      this.form.transfers = this.openTrip.transfers;
      this.refreshIndex = Array(this.form.transfers.length).fill(0);
    } else {
      this.refreshIndex = [];
    }

    if (this.openTrip.transport != null) {
      this.form.transport = this.openTrip.transport;
    }
  }
};
</script>
