<template>
  <div v-if="showTermsOfUsePanel" class="termsOfUsePanel">
    <span>
      <span>
        <div>
          <h3 v-if="termsOfUseAccepted">
            {{ $t("termsOfUse.thanks") }}
          </h3>
          <h3 v-else>
            {{ $t("termsOfUse.readAndConfirm") }}
          </h3>
          <br />
          <p
            id="termsOfUseParagraph"
            v-html="termsOfUse?.content"
            @scroll.stop.passive="handleScrollEvent"
          />
          <br />
          <form @submit.prevent="hideTermsOfUsePanel">
            <b-container fluid>
              <b-form-row>
                <b-col cols="auto">
                  <input
                    type="checkbox"
                    name="userConfirmationCheckbox"
                    v-model="termsOfUseAccepted"
                    :class="termsOfUseRead ? 'hover' : 'no_hover'"
                    :disabled="!termsOfUseRead"
                  />
                </b-col>
                <b-col
                  align-self="center"
                  v-html="$t('termsOfUse.confirmation')"
                >
                </b-col>
              </b-form-row>
            </b-container>
            <button
              name="closeStatement"
              id="closeStatement"
              type="submit"
              :disabled="!termsOfUseAccepted"
            >
              {{ $t("termsOfUse.close") }}
            </button>
          </form>
        </div>
      </span>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import db from "@/service/db";
export default {
  name: "TermsOfUse",
  data: function() {
    return {
      termsOfUseRead: true,
      termsOfUseAccepted: false,
      termsOfUseTimestamp: undefined
    };
  },
  computed: {
    ...mapState({
      termsOfUse: state => state.systemsLists?.DFOPrivacyNoticeStatement
    }),
    showTermsOfUsePanel() {
      return (
        this.termsOfUse?.content !== undefined &&
        this.termsOfUse?.modified !== this.termsOfUseTimestamp
      );
    }
  },
  mounted() {
    let vuejs = this;
    db.getItem("termsOfUseTimestamp").then(function(termsOfUseTimestamp) {
      vuejs.termsOfUseTimestamp = termsOfUseTimestamp;
    });
  },
  methods: {
    hideTermsOfUsePanel: function(e) {
      this.termsOfUseTimestamp = this.termsOfUse?.modified;
      db.setItem("termsOfUseTimestamp", this.termsOfUseTimestamp);
    },
    handleScrollEvent(e) {
      // Consider the bottom reached when the scrollbar is sufficiently close to the bottom
      const p = e.target;
      const distanceFromBottom = p.scrollHeight - p.scrollTop - p.clientHeight;
      const bottomReached = distanceFromBottom <= 25;
      this.termsOfUseRead ||= bottomReached;
    }
  }
};
</script>
