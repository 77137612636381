var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{attrs:{"disabled":_vm.effort['closeDatetime'] !== undefined,"name":"BeginEffort"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.beginningOfEffort"))+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-Trip-' + _vm.index + '-startEffort'),expression:"'modal-Trip-' + index + '-startEffort'"},{name:"show",rawName:"v-show",value:(_vm.effort['closeDatetime'] === undefined),expression:"effort['closeDatetime'] === undefined"}],staticClass:"add-remark-btn"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'modal-Trip-' + _vm.index + '-startEffort'},model:{value:(_vm.effort['remark']),callback:function ($$v) {_vm.$set(_vm.effort, 'remark', $$v)},expression:"effort['remark']"}})],1),_c('GenericInput',{attrs:{"label":_vm.isNewEffort
        ? _vm.$t('editTrip.summary.datetime')
        : _vm.$t('editTrip.effortStep.start'),"required":true,"passDate":true,"autodatetime":true,"minDates":[
      {
        value: _vm.openTrip.departure['datetime'],
        text: _vm.$t('editTrip.beforeDepartureDate')
      }
    ],"forceErrorMsg":_vm.lastTripDateAndEffort(_vm.effort.start, _vm.index),"refeshError":_vm.showErrorData,"type":"datetime"},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.start),callback:function ($$v) {_vm.$set(_vm.effort, "start", $$v)},expression:"effort.start"}}),(_vm.hasPositionInEffort > 0 && _vm.hasEffortDetailStartPosition > 0)?_c('GeopositionWidget',{attrs:{"position":_vm.effort.startPosition,"hasNafo":_vm.hasGeopositionNafo,"hasGrid":_vm.hasGeopositionGrid,"hasLobsterGrid":_vm.hasLobsterGrid,"area":_vm.effort.area,"required":true,"minLat":[_vm.area.latMin ?? _vm.geoLimits.minLat],"maxLat":[_vm.area.latMax ?? _vm.geoLimits.maxLat],"minLong":[_vm.area.lonMin ?? _vm.geoLimits.minLon],"maxLong":[_vm.area.lonMax ?? _vm.geoLimits.maxLon],"gridValidationArray":_vm.gridValidationArray,"nafoGridValidationArray":_vm.nafoGridValidationArray,"refeshError":_vm.showErrorData,"lgrid":_vm.effort.lgrid},on:{"binding":value => {
        _vm.effort.startPosition = value;
      },"error":_vm.addErrorToParent}}):_vm._e(),(_vm.hasPfmaId)?_c('Select',{attrs:{"label":_vm.$t('editTrip.effortStep.parea'),"required":_vm.hasPfmaId === _vm.$const.MANDATORY,"options":_vm.pacAreaOptions,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.parea),callback:function ($$v) {_vm.$set(_vm.effort, "parea", $$v)},expression:"effort.parea"}}):_vm._e(),(_vm.hasEffortNbSets)?_c('IntegerInput',{attrs:{"label":_vm.$t('editTrip.effortStep.setsCount'),"min":1,"max":999,"required":_vm.hasEffortNbSets === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.setsCount),callback:function ($$v) {_vm.$set(_vm.effort, "setsCount", $$v)},expression:"effort.setsCount"}}):_vm._e(),(_vm.hasLicNo2)?_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.effortStep.licenceNumber2'),"minlength":4,"maxlength":_vm.$const.licenceMaxLength,"forceErrorMsg":_vm.uniqueLicenceNumber(_vm.effort.licenceNumber2),"required":_vm.hasLicNo2 === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData,"type":"text"},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.licenceNumber2),callback:function ($$v) {_vm.$set(_vm.effort, "licenceNumber2", $$v)},expression:"effort.licenceNumber2"}}):_vm._e(),_c('br'),(_vm.hasEffortSonar)?_c('GenericInput',{attrs:{"label":_vm.$t('editTrip.effortStep.sonarStart'),"required":_vm.hasEffortSonar === _vm.$const.MANDATORY,"passDate":true,"autodatetime":true,"minDates":[
      {
        value: _vm.effort.start,
        text: _vm.$t('editTrip.beforeStartDate')
      },
      {
        value: _vm.openTrip.departure['datetime'],
        text: _vm.$t('editTrip.beforeDepartureDate')
      }
    ],"refeshError":_vm.showErrorData,"type":"datetime"},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.sonarStart),callback:function ($$v) {_vm.$set(_vm.effort, "sonarStart", $$v)},expression:"effort.sonarStart"}}):_vm._e(),(_vm.hasWaterbodyId)?_c('Select',{attrs:{"label":_vm.$t('editTrip.effortStep.waterbody'),"required":_vm.hasWaterbodyId === _vm.$const.MANDATORY,"options":_vm.waterbodies,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.waterbody),callback:function ($$v) {_vm.$set(_vm.effort, "waterbody", $$v)},expression:"effort.waterbody"}}):_vm._e(),(_vm.hasLicSbtypId)?_c('Select',{attrs:{"label":_vm.$t('editTrip.effortStep.licenceSubtype'),"required":_vm.hasLicSbtypId === _vm.$const.MANDATORY,"options":_vm.licenceSubtypeOptions,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.licenceSubtype),callback:function ($$v) {_vm.$set(_vm.effort, "licenceSubtype", $$v)},expression:"effort.licenceSubtype"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }