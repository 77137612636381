<template>
  <div v-if="isBuildIdRequired">
    <br />
    <p class="gen-txtCenter gen-center copyrightInformation">
      {{ jobelId }}
      <br />
      {{ serverId }}
      <br />
      {{ clientId }}
    </p>
  </div>
</template>

<script>
import buildId from "@/buildId.json";
import { mapState } from "vuex";

export default {
  name: "BuildID",
  computed: {
    ...mapState({
      serverTag: state => state.systemsLists.serverTag
    }),
    isBuildIdRequired() {
      return this.$store.getters.IS_BUILDID_REQUIRED;
    },
    jobelId() {
      return "Jobel " + buildId.versionNumber;
    },
    serverId() {
      return this.$t("server") + " " + this.serverTag;
    },
    clientId() {
      return "Client " + buildId.clientTag;
    }
  }
};
</script>
