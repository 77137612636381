//import Error from '@/lang/messages';
import i18n from "../i18n";
const getDefaultState = () => {
  return {
    hailOutCallProviders: [{ value: null, text: "---------" }],
    hailInCallProviders: [{ value: null, text: "---------" }],
    operatorsFin: [],
    specieOptions: [{ value: null, text: "---------" }],
    gearTypeOptions: [{ value: null, text: "---------" }],
    trapSizeOptions: [],
    refeshErrorByIndex: {},
    allowedDfoRegion: {},
    fishingAreasOptions: [{ value: null, text: "---------" }],
    effortAreas: [],
    gearLossAreas: {
      minLat: [],
      maxLat: [],
      minLong: [],
      maxLong: []
    },
    fishingBaitOptions: [{ value: null, text: "---------" }],
    contextDfoRegion: null,
    allowedFishingAreas: [
      {
        hasNbGear: false
      }
    ],
    DFOregionOptions: [
      { value: null, text: "---------" },
      { value: 1008, text: i18n.t("dfoRegion.centralArctic") },
      { value: 1014, text: i18n.t("dfoRegion.gulf") },
      { value: 1004, text: i18n.t("dfoRegion.maritimes") },
      { value: 1002, text: i18n.t("dfoRegion.newfoundland") },
      { value: 1010, text: i18n.t("dfoRegion.pacific") },
      { value: 1006, text: i18n.t("dfoRegion.quebec") }
    ],
    generalError: {},
    suboperators: [],
    suboperatorsFin: [],
    vessels: [],
    vesselsRN: []
  };
};

export default {
  namespaced: false,
  state: getDefaultState(),
  actions: {
    performRefreshByIndex({ commit }, index) {
      commit("updateRefeshErrorByIndex", index);
    },
    updateStateGeneric({ commit }, payload) {
      commit("updateStateGeneric", payload);
    },
    resetEditTripState({ commit }) {
      console.log("Resetting editTrip state"); // eslint-disable-line no-console
      commit("resetEditTripState");
    },
    emitError({ commit }, error) {
      commit("commitError", error);
    }
  },
  getters: {
    isOldModule: (state, getters) => {
      if (getters.SUBSCRIPTION.module.dfoRegions) {
        const randomRegion = getters.SUBSCRIPTION.module.dfoRegions[0];
        return randomRegion.isOldModule;
      }
      return false;
    },
    // TODO: move to the quota component.
    calculateQuotas: (state, getters) => {
      let calculatedQuotas = [];
      const licences = getters.SUBSCRIPTION.preferences.licences;
      if (!licences) {
        return calculatedQuotas;
      }
      for (let licence of licences) {
        const quotas = licence.quotas;
        if (quotas) {
          for (let quota of quotas) {
            let calculatedQuota = {
              keptWeight: 0,
              maxWeight: quota.weight
            };
            for (let trip of getters.TRIP_LIST) {
              calculatedQuota.keptWeight += getLandingWeight(
                trip,
                licence,
                quota
              );
            }
            for (let trip of getters.CLOSED_TRIPS) {
              calculatedQuota.keptWeight += getLandingWeight(
                trip,
                licence,
                quota
              );
            }
            calculatedQuota.keptWeight += getLandingWeight(
              getters.OPEN_TRIP,
              licence,
              quota
            );

            calculatedQuota.leftWeight =
              calculatedQuota.maxWeight - calculatedQuota.keptWeight;
            if (calculatedQuota.leftWeight < 0) calculatedQuota.leftWeight = 0;

            const ratio =
              (calculatedQuota.keptWeight / calculatedQuota.maxWeight) * 100;

            if (ratio < 75) {
              calculatedQuota.color = "success";
            } else if (ratio < 100) {
              calculatedQuota.color = "warning";
            } else {
              calculatedQuota.color = "danger";
            }

            calculatedQuotas.push(calculatedQuota);
          }
        }
      }
      return calculatedQuotas;
    }
  },
  mutations: {
    updateRefeshErrorByIndex(state, index) {
      if (state.refeshErrorByIndex[index]) {
        state.refeshErrorByIndex[index]++;
      } else {
        state.refeshErrorByIndex[index] = 1;
      }
      // Idiom to trigger watchers
      state.refeshErrorByIndex = JSON.parse(
        JSON.stringify(state.refeshErrorByIndex)
      );
    },

    updateStateGeneric(state, payload) {
      state[payload.name] = payload.value;
      // Idiom to trigger watchers
      state[payload.name] = JSON.parse(JSON.stringify(state[payload.name]));
    },
    resetEditTripState(state) {
      Object.assign(state, getDefaultState());
    },
    commitError(state, error) {
      state.generalError = { ...state.generalError, ...error };
      // Idiom to trigger watchers
      state.generalError = JSON.parse(JSON.stringify(state.generalError));
    }
  }
};

// The landing weight is obtained from the arrival cargo events with cargos of the target species.
function getLandingWeight(trip, licence, quota) {
  if (!trip || !licence || !quota) return 0;

  let creation;
  if (trip.sortingDatetime) {
    creation = new Date(trip.sortingDatetime);
  } else if (trip.departure && trip.departure.datetime) {
    creation = new Date(trip.departure.datetime);
  } else if (trip.arrival && trip.arrival.datetime) {
    creation = new Date(trip.arrival.datetime);
  } else {
    return 0;
  }

  let landingWeight = 0;

  const start = new Date(quota.start);
  const end = new Date(quota.end);

  if (creation >= start && creation <= end) {
    if (trip.arrival && trip.arrival.cargoEvents) {
      for (const cargoEvent of trip.arrival.cargoEvents) {
        if (cargoEvent.datetime) {
          const datetime = new Date(cargoEvent.datetime);
          if (datetime < start || datetime > end) {
            continue;
          }
        }
        if (cargoEvent.cargos) {
          for (const cargo of cargoEvent.cargos) {
            if (cargo.species === licence.target && cargo.weight) {
              landingWeight += cargo.weight;
            }
          }
        }
      }
    }
  }

  return landingWeight;
}
