<template>
  <fieldset class="gears" v-if="nbFieldVisible > 0">
    <h4>{{ $t("section.gears") }}</h4>
    <Select
      v-if="hasEffortGearSubtype && gearSubTypeOptions.length > 1"
      :label="$t('editTrip.effortStep.subType')"
      :options="gearSubTypeOptions"
      v-model="form.subType"
      :required="true"
      @error="checkError"
      :refeshError="refreshError"
    />
    <Select
      v-if="hasTrapSize"
      :label="$t('fields.trapSize')"
      v-model="form.trapSize"
      :options="trapSizeOptions"
      :required="hasTrapSize === $const.MANDATORY"
      @error="checkError"
      :refeshError="refreshError"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Select from "@/components/Select.vue";
import { mapping } from "@/utils/FormStateMapping";

export default {
  name: "Gears",
  components: {
    Select
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object
  },
  data() {
    return {
      isVisible: true,
      hasTrapSize: 0 // TODO: BL - Not necessary since hasTrapSizeId is computed.
    };
  },
  computed: {
    ...mapState({
      gearSubTypeOptions: state =>
        state.editTripSubform[mapping.GearSubTypes.stateName],
      trapSizeOptions: state =>
        state.editTripSubform[mapping.GearTrapSizes.stateName],
      systemsLists: state => state.systemsLists
    }),
    ...mapGetters(["getPropertyValue"]),
    nbFieldVisible() {
      var total = 0;
      // total = this.GearTypes.length > 2 ? total + 1 : total; LF: gears now in subform selector
      total = this.gearSubTypeOptions.length > 2 ? total + 1 : total;
      total = this.hasTrapSize ? total + 1 : total;
      // total = this.allowedDfoRegion.hasOptionMeshType ? total + 1 : total; LF: properties do not exists yet
      // total = this.allowedDfoRegion.maxMeshSize ? total + 1 : total;
      // total = this.allowedDfoRegion.hasOptionTrawlConfig ? total + 1 : total;
      return total;
    },
    hasEffortGearSubtype() {
      return this.getPropertyValue("hasEffortGearSubtype", {
        subforms: this.form.subform,
        gears: this.form.gears
      });
    },
    hasTrapSizeId() {
      return this.getPropertyValue("hasTrapSizeId", {
        subforms: this.form.subform,
        gears: this.form.gears
      });
    }
  },
  watch: {
    nbFieldVisible() {
      this.updateFormBasedOnVisibility();
    },
    "form.subform"() {
      this.updateHasTrapSize();
    },
    "form.gears"() {
      this.updateHasTrapSize();
    }
  },
  methods: {
    updateHasTrapSize() {
      this.hasTrapSize = this.hasTrapSizeId;
      // TODO: BL - Watch hasTrapSizeId instead.
      if (this.hasTrapSize === 0) {
        delete this.form.trapSize;
      }
    },
    updateFormBasedOnVisibility() {
      // this.form.gears = this.GearTypes.length > 2 ? this.form.gears : null; LF: gears now in subform selector
      this.form.subType =
        this.gearSubTypeOptions.length > 2 ? this.form.subType : null;
      this.form.trapSize =
        this.trapSizeOptions.length > 2 ? this.form.trapSize : null;
      // this.form.meshType = this.allowedDfoRegion.hasOptionMeshType LF: properties do not exists yet
      //   ? this.form.meshType
      //   : null;
      // this.form.meshSize = this.allowedDfoRegion.maxMeshSize
      //   ? this.form.meshSize
      //   : null;
    }
  },
  beforeMount() {
    this.updateHasTrapSize();
  }
};
</script>
