//import Error from '@/lang/messages';

export default {
  namespaced: true,
  state: {
    type: null,
    message: null,
    toggle: false
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    clear({ commit }, message) {
      commit("success", message);
    }
  },
  mutations: {
    success(state, message) {
      state.type = "success";
      state.message = message;
      state.toggle = !state.toggle;
    },
    error(state, message) {
      state.type = "error";
      state.message = message;
      state.toggle = !state.toggle;
    },
    clear(state) {
      state.type = null;
      state.message = null;
    }
  }
};
