<template>
  <div v-if="faqEnabled === '1'">
    <div class="faqs_container">
      <div>
        <div class="header" @click="() => handleClick()">
          <h2 class="question">
            {{ "FAQ" }}
          </h2>
          <a>
            <b-icon :icon="isFaqOpen ? 'dash' : 'plus'" scale="2"></b-icon>
          </a>
        </div>
      </div>
      <div v-if="isFaqOpen" class="faqs">
        <Faq
          v-for="faq in faqs"
          :key="faq.id"
          :faq="faq"
          @toggleAnswer="toggleAnswer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Faq from "@/components/widgets/Faq.vue";

export default {
  name: "FaqManager",
  components: {
    Faq
  },
  props: {
    faqData: {
      type: Array,
      required: true
    },
    autoCloseFAQ: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    faqs: null,
    openFaq: null,
    isFaqOpen: false
  }),
  computed: {
    faqEnabled() {
      return process.env.VUE_APP_ENABLE_FAQ;
    }
  },
  watch: {
    faqData() {
      this.faqs = this.generateFaqs(this.faqData);
    }
  },
  methods: {
    handleClick() {
      this.isFaqOpen = !this.isFaqOpen;
    },
    toggleAnswer(index) {
      for (let faq in this.faqs) {
        if (Number(this.faqs[faq].id) == index) {
          this.faqs[faq].isOpen = !this.faqs[faq].isOpen;
          if (this.autoCloseFAQ) {
            if (this.openFaq != null) {
              this.faqs[this.openFaq].isOpen = false;
              this.openFaq = null;
            }
            if (this.faqs[faq].isOpen) {
              this.openFaq = faq;
            }
          }
        }
      }
    },
    generateFaqs(faqs) {
      let tmpFaqs = [];
      for (let faq in faqs) {
        let currentFaq = {
          id: faq,
          question: faqs[faq].question,
          answer: faqs[faq].answer,
          isOpen: false
        };
        tmpFaqs.push(currentFaq);
      }
      return tmpFaqs;
    }
  },
  mounted() {
    this.faqs = this.generateFaqs(this.faqData);
  }
};
</script>

<style lang="scss" scoped>
.faqs_container {
  flex-grow: 1;
  border: solid 1px $gris-fonce;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    cursor: pointer;
  }
  .faqs {
    display: flex;
    flex-direction: column;
  }
}
</style>
