<template>
  <fieldset>
    <h4>{{ $t("editTrip.subformTitle") }}</h4>
    <Select
      v-model="filter.dfoRegion"
      :label="$t('dfoRegion.label')"
      :help="$t('dfoRegion.desc')"
      :options="filteredRegions"
      :required="true"
      :refeshError="refresh"
      @error="checkError"
    />
    <Select
      v-if="filteredGears.length > 2"
      v-model="filter.gears"
      :label="$t('fields.gears')"
      :options="filteredGears"
      :required="true"
      :refeshError="refresh"
      @error="checkError"
    />
    <Select
      v-if="filteredTargets.length > 2"
      v-model="filter.target"
      :label="$t('fields.targetSpecie')"
      :options="filteredTargets"
      :required="true"
      :refeshError="refresh"
      @error="checkError"
    />
    <br />
    <Select
      v-if="possibleSubforms.length > 2"
      v-model="form.subform"
      :label="$t('editTrip.subform')"
      :options="possibleSubforms"
      :required="true"
      :refeshError="refresh"
      @error="checkError"
    />
    <b-row>
      <b-col lg="4" sm="6" cols="12">
        <LabelView
          v-if="possibleSubforms.length === 2"
          :label="$t('editTrip.subform')"
          :value="form.subform"
          :options="systemsLists.subforms"
          :margin="true"
          :floatLeft="true"
          type="select"
        />
      </b-col>
    </b-row>
  </fieldset>
</template>

<script>
import Select from "@/components/Select.vue";
import LabelView from "@/components/LabelView.vue";
import { mapState, mapActions } from "vuex";
import G from "glob";
export default {
  name: "SubformSelector",
  components: {
    Select,
    LabelView
  },
  props: {
    checkError: { type: Function },
    refresh: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      filter: {
        dfoRegion: null,
        gears: null,
        target: null
      },
      form: {
        subform: null
      },
      filteredRegions: [],
      filteredGears: [],
      filteredTargets: [],
      possibleSubforms: [],
      regionMap: {},
      gearMap: {},
      targetMap: {},
      subformMap: {},
      hasOneRegionPerSubform: true,
      hasMultiGear: false,
      hasMultiTarget: false,
      error: {},
      message: []
    };
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists
    })
  },
  watch: {
    "filter.dfoRegion": function() {
      if (process.env.VUE_APP_DEBUG_SUBFORM) {
        console.debug("watch dfoRegion trigerred", this.filter); // eslint-disable-line no-console
      }
      // reset other filters
      const oldGearId = this.filter.gears;
      const oldTargetId = this.filter.target;
      this.filter.gears = null;
      this.filter.target = null;
      this.updateSubforms();
      this.updateFilters();
      if (this.filteredGears.find(x => x.value == oldGearId)) {
        this.filter.gears = oldGearId;
      }
      if (this.filteredTargets.find(x => x.value == oldTargetId)) {
        this.filter.target = oldTargetId;
      }
      this.$emit("regionUpdated", this.filter.dfoRegion);
    },
    "filter.target": function() {
      if (process.env.VUE_APP_DEBUG_SUBFORM) {
        console.debug("watch target trigerred", this.filter); // eslint-disable-line no-console
      }
      this.updateSubforms();
      this.$emit("targetUpdated", this.filter.target);
    },
    "filter.gears": function() {
      if (process.env.VUE_APP_DEBUG_SUBFORM) {
        console.debug("watch gears trigerred", this.filter); // eslint-disable-line no-console
      }
      this.updateSubforms();
      this.$emit("gearUpdated", this.filter.gears);
    },
    form: {
      deep: true,
      handler: function() {
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.info("subform selected", this.form); // eslint-disable-line no-console
        }
        this.updateFilters();
        this.$emit("subformSelected", this.form.subform);
      }
    }
  },
  mounted() {
    const vm = this;

    // LF: assumes no subforms filter ([0].v)
    for (const subformId of vm.subscription.module["subforms"][0].v) {
      const subform = vm.systemsLists.subforms.find(x => x.value == subformId);
      subform.regionIds = [];
      subform.targetIds = [];
      subform.gearIds = [];
      vm.subformMap[subformId] = subform;
    }
    const subformIds = Object.values(vm.subformMap).map(s => s.value);
    if (process.env.VUE_APP_DEBUG_SUBFORM) {
      console.debug("built subformMap", vm.subformMap); // eslint-disable-line no-console
    }

    // LF: assumes always subform filters (length > 1 && .subform)
    // otherwise handle like targets and gears below
    for (const regionOptions of vm.subscription.module["regions"]) {
      for (const regionId of regionOptions.v) {
        if (!vm.regionMap.hasOwnProperty(regionId)) {
          vm.regionMap[regionId] = { subformIds: [] };
        }
        for (const subformId of regionOptions.subforms) {
          vm.regionMap[regionId].subformIds.push(subformId);
          vm.subformMap[subformId].regionIds.push(regionId);
        }

        if (
          this.hasOneRegionPerSubform &&
          vm.regionMap[regionId].subformIds.length > 1
        ) {
          this.hasOneRegionPerSubform = false;
        }
      }
    }

    for (const regionId of Object.keys(vm.regionMap)) {
      let region = vm.systemsLists.dfoRegions.find(x => x.value == regionId);
      vm.regionMap[regionId].text = region.text;
      vm.regionMap[regionId].value = region.value;
    }
    if (process.env.VUE_APP_DEBUG_SUBFORM) {
      console.debug("built regionMap", vm.regionMap); // eslint-disable-line no-console
    }

    if (vm.subscription.module["gears"].length > 1) {
      this.hasMultiGear = true;
      let configuredSubformIds = new Set();
      for (const gearOptions of vm.subscription.module["gears"]) {
        for (const gearId of gearOptions.v) {
          if (!vm.gearMap.hasOwnProperty(gearId)) {
            vm.gearMap[gearId] = { subformIds: [] };
          }
          if (gearOptions.hasOwnProperty("subforms")) {
            for (const subformId of gearOptions.subforms) {
              vm.gearMap[gearId].subformIds.push(subformId);
              vm.subformMap[subformId].gearIds.push(gearId);
              configuredSubformIds.add(subformId);
            }
          } else {
            // sometimes after having specific subform configs we get the general one
            for (const subformId of subformIds) {
              if (configuredSubformIds.has(subformId)) continue;
              vm.gearMap[gearId].subformIds.push(subformId);
              vm.subformMap[subformId].gearIds.push(gearId);
            }
          }
        }
      }
    } else {
      // no filters, all in one
      for (const gearId of vm.subscription.module["gears"][0].v) {
        vm.gearMap[gearId] = { subformIds: [] };
        for (const subformId of subformIds) {
          vm.gearMap[gearId].subformIds.push(subformId);
          vm.subformMap[subformId].gearIds.push(gearId);
        }
      }
    }

    for (const gearId of Object.keys(vm.gearMap)) {
      let gear = vm.systemsLists.gearType.find(x => x.value == gearId);
      vm.gearMap[gearId].text = gear.text;
      vm.gearMap[gearId].value = gear.value;
    }
    if (process.env.VUE_APP_DEBUG_SUBFORM) {
      console.debug("built gearMap", vm.gearMap); // eslint-disable-line no-console
    }

    if (vm.subscription.module["targetSpecies"].length > 1) {
      this.hasMultiTarget = true;
      for (const targetOptions of vm.subscription.module["targetSpecies"]) {
        for (const targetId of targetOptions.v) {
          if (!vm.targetMap.hasOwnProperty(targetId)) {
            vm.targetMap[targetId] = { subformIds: [] };
          }
          for (const subformId of targetOptions.subforms) {
            vm.targetMap[targetId].subformIds.push(subformId);
            vm.subformMap[subformId].targetIds.push(targetId);
          }
        }
      }
    } else {
      // no filters, all in one
      for (const targetId of vm.subscription.module["targetSpecies"][0].v) {
        vm.targetMap[targetId] = { subformIds: [] };
        for (const subformId of subformIds) {
          vm.targetMap[targetId].subformIds.push(subformId);
          vm.subformMap[subformId].targetIds.push(targetId);
        }
      }
    }

    for (const targetId of Object.keys(vm.targetMap)) {
      let target = vm.systemsLists.species.find(x => x.value == targetId);
      vm.targetMap[targetId].text = target.text;
      vm.targetMap[targetId].value = target.value;
    }
    if (process.env.VUE_APP_DEBUG_SUBFORM) {
      console.debug("built targetMap", vm.targetMap); // eslint-disable-line no-console
    }

    this.updateSubforms();
    this.updateFilters();
    this.initFilters();
  },
  beforeUpdate() {},
  methods: {
    updateParentComponent() {
      if (this.filter.dfoRegion) {
        this.$emit("regionUpdated", this.filter.dfoRegion);
      }
      if (this.filter.target) {
        this.$emit("targetUpdated", this.filter.target);
      }
      if (this.filter.gears) {
        this.$emit("gearUpdated", this.filter.gears);
      }
    },
    updateFilters() {
      const vm = this;
      const defaultValue = [{ value: null, text: "---------", disabled: true }];

      // LF: always keep option to change dfo region
      if (vm.filteredRegions.length == 0)
        vm.filteredRegions = [...defaultValue, ...Object.values(vm.regionMap)];

      if (!vm.form.subform && vm.possibleSubforms.length < 2) {
        vm.filteredGears = [...defaultValue, ...Object.values(vm.gearMap)];
        vm.filteredTargets = [...defaultValue, ...Object.values(vm.targetMap)];
      } else {
        let gears = new Set();
        let targets = new Set();
        const subforms = vm.possibleSubforms.slice(
          1,
          vm.possibleSubforms.length
        );
        for (const subform of subforms) {
          for (const regionId of subform.gearIds) {
            gears.add(vm.gearMap[regionId]);
          }
          for (const targetId of subform.targetIds) {
            targets.add(vm.targetMap[targetId]);
          }
        }

        vm.filteredGears = [...defaultValue, ...Array.from(gears)];
        vm.filteredTargets = [...defaultValue, ...Array.from(targets)];
      }
    },
    updateSubforms() {
      const vm = this;
      vm.possibleSubforms = [
        { value: null, text: "---------", disabled: true }
      ];

      let subformIds = Object.values(vm.subformMap).map(s => s.value);
      if (vm.filter.dfoRegion) {
        subformIds = subformIds.filter(item =>
          vm.regionMap[vm.filter.dfoRegion].subformIds.includes(item)
        );
      }

      if (vm.hasMultiGear && vm.filter.gears) {
        subformIds = subformIds.filter(item =>
          vm.gearMap[vm.filter.gears].subformIds.includes(item)
        );
      }

      if (vm.hasMultiTarget && vm.filter.target) {
        subformIds = subformIds.filter(item =>
          vm.targetMap[vm.filter.target].subformIds.includes(item)
        );
      }

      for (const subformId of subformIds) {
        vm.possibleSubforms.push(vm.subformMap[subformId]);
      }
      if (process.env.VUE_APP_DEBUG_SUBFORM) {
        console.debug("updated subforms", vm.possibleSubforms); // eslint-disable-line no-console
      }

      // clear invalid data
      if (!subformIds.includes(vm.form.subform)) {
        vm.form.subform = null;
      }

      // auto select if only one possible subform
      if (vm.possibleSubforms.length === 2 && !vm.form.subform) {
        vm.form.subform = vm.possibleSubforms[1].value;
      }
    },
    initFilters() {
      const preferences = this.subscription.preferences;
      if (preferences.subform) {
        this.form.subform = preferences.subform;
      }
      if (preferences.dfoRegion) {
        this.filter.dfoRegion = preferences.dfoRegion;
      }
      if (preferences.gears) {
        this.filter.gears = preferences.gears;
      }
      if (preferences.licences?.length > 0) {
        const targetId = preferences.licences[0].target;
        if (targetId) {
          this.filter.target = targetId;
        }
      }
    }
  }
};
</script>
