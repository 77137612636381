<template>
  <div name="BaitStep">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.baitStep.title") }}</span>
        </span>
      </span>
    </h3>
    <fieldset>
      <BaitUsed
        :baitUsages="form.baitUsages"
        :addError="
          (error, index) => {
            this.addError(error, index);
          }
        "
        :showErrorData="showErrorData"
      />
    </fieldset>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaitUsed from "./EffortStepComponents/BaitUsed.vue";

export default {
  components: {
    BaitUsed
  },
  name: "BaitStep",
  data: () => ({
    form: {
      baitUsages: []
    },
    showErrorData: 0,
    name: "baitStep"
  }),
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      showError: state => state.editTripSubform.showError,
      currentPage: state => state.editTripSubform.currentPage
    })
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    showError() {
      if (this.isFinalReview || this.currentPage === this.name) {
        ++this.showErrorData;
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addError(error, index) {
      this.addErrorsToState({ page: `${this.name}-${index}`, errors: error });
      this.addErrorsToState({ page: this.name, errors: error });
    }
  },
  beforeMount() {
    if ("creation" in this.openTrip) {
      this.form.baitUsages = this.openTrip.baitUsages ?? [];
    }
  }
};
</script>
