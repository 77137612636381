<template>
  <div class="field-wrapper field-wrapper-captcha tooltipped">
    <span class="label">
      <span>
        <label>{{ $t("captcha.label") }}</label>
      </span>
      <a
        class="dot"
        v-b-tooltip.hover.topleft="{
          title: this.$t('captcha.help'),
          variant: 'primary'
        }"
      >
        ?
      </a>
    </span>
    <p class="captcha-wrapper">
      <img id="captchaImg" :src="remoteUrl" />
      <a class="refreshCaptcha" @click="refreshCaptcha">{{
        $t("captcha.refresh")
      }}</a>
    </p>
    <b-input
      :id="errorId"
      type="text"
      v-model="captcha.text"
      :class="errorClass"
      @blur="focusChanged"
    />
    <label v-if="isError" class="error">{{ $t("captcha.error") }}</label>
  </div>
</template>

<script>
import Validator from "@/utils/validator.js";
import axios from "axios";

export default {
  props: ["errorId", "refeshError", "captcha", "refresh"],
  data() {
    return {
      id: Math.round(Math.random() * 100000),
      remoteId: null,
      remoteUrl: null,
      help: this.$t("captcha.help"),
      filter: {
        type: "text",
        required: true,
        maxlength: 6,
        minlength: 6
      },
      errorClass: { error: false },
      isError: false
    };
  },
  watch: {
    refeshError() {
      this.setError(this.captcha.text);
    },
    isError() {
      this.errorClass.error = this.isError;
      let object = {};
      object["captcha_" + this.id] = this.isError;
      this.$emit("error", object);
    },
    refresh() {
      if (this.refresh) {
        this.refreshCaptcha();
        this.$emit("refreshed");
      }
    }
  },
  methods: {
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);

      this.isError = rt.error;
      this.errorClass.error = this.isError;
      let object = {};
      object["captcha_" + this.id] = this.isError;
      this.$emit("error", object);
    },
    focusChanged(e) {
      this.setError(e.target.value);
    },
    refreshCaptcha() {
      const vuejs = this;
      const now = new Date().getTime();
      const baseUrl = process.env.VUE_APP_DJANGO_BASE_URL;
      this.captcha.text = null;
      axios.get(`${baseUrl}/jc/newCaptcha/?${now}`).then(function(response) {
        vuejs.captcha.id = response.data.captcha;
        vuejs.remoteId = response.data.captcha;
        vuejs.remoteUrl = `${baseUrl}${response.data.url}`;
        return Promise.resolve(true);
      });
    }
  },
  mounted() {
    this.refreshCaptcha();
  },
  beforeDestroy() {
    let object = {};
    object["captcha_" + this.id] = false;
    this.$emit("error", object);
  }
};
</script>
