<template>
  <div name="Init" id="pageLobby" class="page insideApp">
    <h1>{{ $t("loading") }}</h1>
    <div class="content">
      <div id="showme">
        <p>{{ $t("tryReloading") }}</p>
        <button @click="reload">{{ $t("reload") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/service/db";

export default {
  name: "Init",
  methods: {
    reload() {
      window.location.reload();
    }
  },
  mounted() {
    const router = this.$router;
    const store = this.$store;
    store
      .dispatch("checkAuthentification")
      .then(function() {
        let defaultUrl = "/modules";
        return db
          .getLastVisitedUrl()
          .then(function(url) {
            if (url) {
              const resolved = router.resolve(url);
              const uuid = resolved.route.params.uuid;
              const luid = resolved.route.params.luid;
              const reportId = resolved.route.params.reportId;
              if (process.env.VUE_APP_DEBUG_ONLINE) {
                console.debug("resolved =", resolved); // eslint-disable-line no-console
                console.debug("uuid =", uuid); // eslint-disable-line no-console
                console.debug("luid =", luid); // eslint-disable-line no-console
                console.debug("reportId =", reportId); // eslint-disable-line no-console
              }
              if (uuid) {
                return store
                  .dispatch("selectSubscription", uuid)
                  .then(function() {
                    if (luid) {
                      return store.dispatch("selectTrip", luid);
                    }
                    return Promise.resolve(true);
                  })
                  .then(function() {
                    return router.replace(url).catch(error => {
                      // ignore because of exception on navigation guard...
                    });
                  });
              } else if (reportId) {
                return store
                  .dispatch("selectReportContext", reportId)
                  .then(function() {
                    if (luid) {
                      return store.dispatch("selectTrip", luid);
                    }
                    return Promise.resolve(true);
                  })
                  .then(function() {
                    return router.replace(url).catch(error => {
                      // ignore because of exception on navigation guard...
                    });
                  });
              }
              return router.replace(url).catch(error => {
                // ignore because of exception on navigation guard...
              });
            }
            return router.replace(defaultUrl).catch(error => {
              // ignore because of exception on navigation guard...
            });
          })
          .catch(error => {
            console.warn("thrown last visited url", error.message); // eslint-disable-line no-console
            router.replace(defaultUrl);
          });
      })
      .catch(error => {
        console.warn("while check auth", error.message); // eslint-disable-line no-console
        router.replace("/connexion");
      });
  }
};
</script>

<style lang="scss" scoped>
#showme {
  visibility: hidden;
  animation: showme-animation 0s ease-in 5s forwards;
  -webkit-animation: showme-animation 0s ease-in 5s forwards;
  -moz-animation: showme-animation 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
@keyframes showme-animation {
  to {
    visibility: visible;
  }
}
@-webkit-keyframes showme-animation {
  to {
    visibility: visible;
  }
}
@-moz-keyframes showme-animation {
  to {
    visibility: visible;
  }
}

button {
  border: none;
  margin-top: 11px;
  // width: 438px;
  padding: 10px;
  min-height: 53px;
  background-color: $lightColor;
  font-size: 1em;
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: $darkColor;
  }

  &.active {
    background-color: $darkColor;
  }
}
</style>
